<template>
  <template v-if="isLoading">
    <at-table-item>
      <at-skeleton :style="{ height: 24 / 16 + 'rem' }" />
    </at-table-item>
    <at-table-item>
      <at-skeleton :style="{ height: 24 / 16 + 'rem' }" />
    </at-table-item>
  </template>

  <template v-else>
    <at-table-item :to="clientShowLink">
      <div class="flex flex-col gap-y-1">
        <p class="text-sm whitespace-normal font-medium">
          {{ getClientFullName(client) }}
        </p>
        <p class="text-sm whitespace-nowrap text-gray-500 dark:text-gray-400">
          {{ clientGetFormattedPhone(client) }}
        </p>
      </div>
    </at-table-item>

    <at-table-item :to="clientShowLink">
      <client-status-badge :client="client" />
    </at-table-item>
  </template>
</template>

<script setup>
  import { computed } from 'vue';

  import { ROUTE_NAMES } from '@/modules/nasiya/common/constants/routeNames';

  import ClientStatusBadge from './ClientStatusBadge.vue';

  import { AtTableItem, AtSkeleton } from '@packages/aliftech-ui';
  import { clientGetFormattedPhone, getClientFullName } from '../lib';

  const props = defineProps({
    client: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  });

  const clientShowLink = computed(() => {
    return { name: ROUTE_NAMES.clientsShow, params: { clientId: props.client.id } };
  });
</script>
