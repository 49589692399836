import { reactive, toRefs } from 'vue';
import { checkType } from '@/plugins/aliftech-ui/utils';
import { useI18n } from 'vue-i18n';

/**
 * @param {Function} callback - axios callback
 * @returns {Result}
 */
export const useService = callback => {
  const { t, locale } = useI18n();

  /**
   * Method to get all states with initial value
   * @returns {{request: *[], alert: null, result: null, hasError: boolean, error: null, loading: boolean, errors: null}}
   */
  const getState = () => ({
    result: null,
    error: null,
    errors: null,
    loading: false,
    alert: null,
    request: [],
    hasError: false,
  });
  const data = reactive({ ...getState() });

  /**
   * Method to reset all states
   * @returns {VoidFunction}
   */
  const resetState = () => {
    Object.assign(data, { ...getState() });
  };

  const setError = (e, title = null) => {
    if (checkType(e, 'object') && 'errors' in e) {
      data.errors = e.errors;
      return;
    } else if (checkType(e, 'object') && 'message' in e) {
      setAlert({ message: e.message, type: 'danger', title: title });
      data.error = { ...(e || {}) };
      return;
    } else if (checkType(e, 'string')) {
      setAlert({ message: e, type: 'danger', title: title });
      return;
    } else {
      setError(t('system.errors.somethingWentWrong'));
      return;
    }
  };

  /**
   * @type setSuccess
   */
  const setSuccess = (message, title = null) => {
    if (message) {
      if (checkType(message, 'object') && 'lang' in message) {
        setAlert({ message: message.lang[locale.value], type: 'success', title });
      } else if (checkType(message, 'string')) {
        setAlert({ message: message, type: 'success', title });
      }
    }
  };

  /**
   * @type setAlert
   */
  const setAlert = ({ message = null, type = null, title = null, dismissible = true }) => {
    data.alert = {
      type,
      message,
      title,
      dismissible,
      id: message ? new Date().getTime() : null,
    };
  };

  /**
   * @type setResult
   */
  const setResult = payload => {
    data.result = payload;
  };

  /**
   * @type setLoading
   */
  const setLoading = payload => {
    data.loading = payload;
  };

  /**
   * @type execute
   */
  const execute = (...args) => {
    resetState();
    setLoading(true);
    data.request = [...args];

    return new Promise((resolve, reject) => {
      callback(...args)
        .then(res => {
          setResult(res?.data);
          return resolve(res);
        })
        .catch(err => {
          data.hasError = true;
          setError(err);
          return reject(err);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return {
    ...toRefs(data),
    execute,
    setError,
    setAlert,
    setSuccess,
    setResult,
    setLoading,
  };
};
