import mainConnect, { generateError } from '@/services';

export const getRejectCases = params => {
  return mainConnect.get('/credits/reject-cases', { params }).catch(err => generateError(err));
};

export const requestCreateRejectCase = params => {
  return mainConnect.post(`/reject_cases/reject_cases/request-store`, params).catch(generateError);
};

export const createRejectCase = formData => {
  return mainConnect.post(`/reject_cases/reject_cases_full`, formData).catch(generateError);
};

export const getRejectCase = (rejectCaseId, params) => {
  return mainConnect.get(`/credits/reject-cases/${rejectCaseId}`, { params }).catch(err => generateError(err));
};

export const rejectRefund = (rejectCaseId, body) => {
  return mainConnect.post(`/credits/reject-cases/partner-denied/${rejectCaseId}`, body).catch(generateError);
};

export const approveRefund = rejectCaseId => {
  return mainConnect.post(`/credits/reject-cases/partner-approved/${rejectCaseId}`).catch(generateError);
};

export const getRejectCaseTemplate = (applicationId, body) => {
  return mainConnect
    .post(`/credits/reject-cases/get-reject-case-template/${applicationId}`, body, { responseType: 'blob' })
    .catch(generateError);
};
