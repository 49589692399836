export const ROUTE_NAMES = {
  paymentsList: 'acquiring.payments',
  balancesList: 'acquiring.balances',
  balancesListToFsd: 'acquiring.balances.toFsd',
  scoringIndex: 'acquiring.scoring',
  cardScoring: 'acquiring.card-scoring',
  createInvoice: 'acquiring.createInvoice',
  createInvoiceToFsd: 'acquiring.createInvoice.to-fsd',
  paymentsListV2: 'acquiring.payments2',
  searchClients: 'acquiring.searchClients',
};
