export default {
  widgets: {
    clientApplicationsList: {
      card: {
        sum: 'Miqdori',
        createdDate: 'Yaratilgan sana',
        applicationType: 'Ariza turi',
        more: 'Batafsil',
        type: {
          inStore: "Do'kondan",
          outOfStore: 'Masofadan',
        },
      },
    },
  },
};
