import { useMutation } from '@tanstack/vue-query';
import { checkClient } from '../api';

const useCheckClientMutation = () => {
  return useMutation({
    async mutationFn({ passportId, phone }) {
      const response = await checkClient({
        passport_id: passportId,
        phone,
      });

      return response.data;
    },
  });
};

export default useCheckClientMutation;
