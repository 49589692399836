import { parseISO } from 'date-fns';
import { formatDate, truncateStr } from '@/to-fsd/shared/lib';

export const problemCaseGetId = problemCase => {
  return problemCase.id;
};

export const problemCaseGetStatusId = problemCase => {
  return problemCase.status_id;
};

export const problemCaseGetCreatedAt = problemCase => {
  return problemCase.created_at;
};

export const problemCaseGetFormattedCreatedAt = problemCase => {
  return formatDate(parseISO(problemCaseGetCreatedAt(problemCase)));
};

export const problemCaseGetCommentFromMerchant = problemCase => {
  return problemCase.comment_from_merchant;
};

export const problemCaseGetTruncatedCommentFromMerchant = problemCase => {
  return truncateStr(problemCaseGetCommentFromMerchant(problemCase));
};

export const problemCaseHasCommentFromMerchant = problemCase => {
  return Boolean(problemCaseGetCommentFromMerchant(problemCase));
};

export const problemCaseGetClient = problemCase => {
  return problemCase.client;
};

export const problemCaseGetDeadline = problemCase => {
  return problemCase.deadline;
};

export const problemCaseGetFormattedDeadline = problemCase => {
  return formatDate(parseISO(problemCaseGetDeadline(problemCase)));
};

export const problemCaseHasDeadline = problemCase => {
  return Boolean(problemCaseGetDeadline(problemCase));
};

export const problemCaseGetDescription = problemCase => {
  return problemCase.description;
};

export const problemCaseGetApplicationItems = problemCase => {
  return problemCase.application_items;
};
