export default {
  widgets: {
    clientApplicationsList: {
      card: {
        sum: 'Сумма',
        createdDate: 'Дата создания',
        applicationType: 'Тип заявки',
        more: 'Подробнее',
        type: {
          inStore: 'Из магазина',
          outOfStore: 'Дистанционно',
        },
      },
    },
  },
};
