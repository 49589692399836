export default {
  features: {
    cancelAcquiringTransaction: {
      button: 'Отменить транзакцию',
      cancel: 'Отменить',
      submit: 'Подтвердить отмену',
      status: 'Транзакция отменена!',
      timeout: 'Нельзя вернуть через',
      reset: 'Вернуть',
      placeholder: 'Причина',
      success: 'Успешно',
      pending: 'На рассмотрении',
      errors: {
        notFound: 'Не найдено',
        unprocessable: 'Невозможно отменить данный платеж',
        temporalError: 'Временая ошибка сервера',
        clientError: 'Ошибка сервиса',
        unknown: 'Неизвестная ошибка',
      },
    },
  },
};
