import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { problemCaseSetComment } from '../api';
import { useRefetchOnSuccess } from '@/to-fsd/shared/lib/vue-query';

const useProblemCaseSetCommentMutation = () => {
  const queryClient = useQueryClient();

  const { onSuccess, isRefetchingAfterMutation } = useRefetchOnSuccess(async (_, { problemCaseId }) => {
    await Promise.all([queryClient.refetchQueries({ queryKey: ['problem-cases', 'detail', problemCaseId] })]);
  });

  const mutation = useMutation({
    async mutationFn({ problemCaseId, body }) {
      const response = await problemCaseSetComment(problemCaseId, { body });

      return response.data;
    },
    onSuccess,
  });

  return {
    ...mutation,
    isRefetchingAfterMutation,
  };
};

export default useProblemCaseSetCommentMutation;
