import { useQuery } from '@tanstack/vue-query';
import { getRejectCases } from '../api';

const useGetRejectCasesQuery = (params = {}) => {
  return useQuery({
    queryKey: ['reject-cases', 'list', params],
    async queryFn({ queryKey }) {
      const { applicationId, page, q, search_param, status } = queryKey[2];

      const requestParams = {
        ...(applicationId ? { application_id: applicationId, paginate: 0, relationships: 'items' } : {}),
        page,
        search_param,
        status,
        q: q,
      };

      const response = await getRejectCases(requestParams);

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetRejectCasesQuery;
