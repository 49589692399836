export default {
  pages: {
    offerDemandsEdit: {
      quitModal: {
        doYouWantToQuit: 'Вы точно хотите выйти?',
        cancel: 'Отменить',
        quit: 'Выйти',
      },
    },
  },
};
