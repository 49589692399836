export default {
  widgets: {
    reportsList: {
      headers: ['Fayl', "Oralig'i", "So'nggi generatsiya", 'Harakatlar'],
      successAlert: {
        title: 'Hisobotni ko\'chirib olish uchun "yuklash" tugmasini bosing',
        message: '"{name}" nomli hisobot generatsiya bo\'ldi',
      },
      failAlert: {
        message: '"{name}" nomli hisobot generatsiya bo\'lmadi',
      },
      reportNameText: {
        merchants_service: 'Tovarlar',
        merchants_rejects: 'Qaytarilgan tovarlar',
        merchants_problem: 'Muammoli mijozlar',
        merchants_invoice: 'Hisob faktura',
        merchants_minus_invoice: 'Minus hisob faktura',
        merchants_reconciliation: 'Solishtirma Dalolatnomasi',
        merchants_delivery: 'Yetkazib berishlar',
        delivery_tariffs: 'Yetkazib berish tariflari',
      },
      periodTypes: {
        month: 'Oy',
        quarter: 'Choraklik',
        half_year: 'Yarim yillik',
        year: 'Yillik',
      },
      generate: 'Yaratish',
      lastGenerated: "So'nggi generatsiya",
      download: 'Yuklash',
      periodOptions: ['Kun', 'Davr'],
    },
  },
};
