<template>
  <at-alert v-if="hasActiveFixReasons" type="warning" :title="$t('entities.client.fixReasons.title')">
    <ul class="pl-5 list-disc">
      <li v-for="reason in activeFixReasons" :key="reason.id">{{ reason.body }}</li>
    </ul>
  </at-alert>
</template>

<script setup>
  import { computed } from 'vue';

  import { clientGetFixReasons } from '@/to-fsd/entities/client';

  import { AtAlert } from '@packages/aliftech-ui';

  const props = defineProps({
    client: {
      type: Object,
      required: true,
    },
  });

  const activeFixReasons = computed(() => {
    return clientGetFixReasons(props.client).filter(fixReason => fixReason.active);
  });

  const hasActiveFixReasons = computed(() => activeFixReasons.value.length > 0);
</script>
