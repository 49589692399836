import { generateError, mainConnect } from '@/services';

export const getClientActivityAreas = () => {
  return mainConnect.get('clients/clients/questionnaire/activity-areas').catch(generateError);
};

export const getPassportIssuers = () => {
  return mainConnect.get('/info/passport-issuers').catch(generateError);
};

export const getCreditsApp = () => {
  return mainConnect.get('credits/app').catch(generateError);
};

export const getWarehouseApp = () => {
  return mainConnect.get('/warehouse/app').catch(generateError);
};

export const getDeliveriesApp = () => {
  return mainConnect.get('/deliveries/app').catch(generateError);
};

export const getThirdCategory = params => {
  return mainConnect.get(`/api/v1/admin/third-categories`, { params }).catch(generateError);
};

export const getOrderStatuses = params => {
  return mainConnect.get(`orders/statuses`, { params }).catch(generateError);
};

export const getDeliveryApp = () => {
  return mainConnect.get('deliveries/app').catch(generateError);
};
