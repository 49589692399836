import { computed, unref } from 'vue';
import { useI18n } from 'vue-i18n/index';

const useElasticSearchParams = ({ paramsToInclude, defaultParam }) => {
  const { t } = useI18n();

  const searchParams = {
    PASSPORT: 'passport',
    PHONE: 'phone',
    NAME: 'fio',
    PINFL: 'pinfl',
    CLIENT_ID: 'client_id',
    APPLICATION_ID: 'application_id',
  };

  const getParamsOptions = computed(() => {
    const allOptions = [
      {
        title: t('shared.hooks.useSearchParams.phone'),
        value: searchParams.PHONE,
      },
      {
        title: t('shared.hooks.useSearchParams.name'),
        value: searchParams.NAME,
      },
      {
        title: t('shared.hooks.useSearchParams.passport'),
        value: searchParams.PASSPORT,
      },
      {
        title: t('shared.hooks.useSearchParams.pinfl'),
        value: searchParams.PINFL,
      },
      {
        title: t('shared.hooks.useSearchParams.clientId'),
        value: searchParams.CLIENT_ID,
      },
      {
        title: t('shared.hooks.useSearchParams.applicationId'),
        value: searchParams.APPLICATION_ID,
      },
    ];

    return allOptions.filter(option => unref(paramsToInclude).includes(option.value));
  });

  return { searchParams, defaultParam, getParamsOptions };
};

export default useElasticSearchParams;
