import { computed, unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { nasiyaSearchClients, getLimit } from '../api';

const useNasiyaSearchClientsQuery = ({ phone }) => {
  const isPhoneValid = computed(() => unref(phone).length === 12);

  return useQuery({
    queryKey: ['clients', 'list', 'search', { nasiya: true, phone }],
    async queryFn({ queryKey }) {
      const { phone } = queryKey[3];

      const response = await nasiyaSearchClients({ phone });

      const clientLimit = await getLimit(response.data.id);

      return {
        ...response.data,
        client_limit: clientLimit.data,
      };
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
    enabled: computed(() => isPhoneValid.value),
  });
};

export default useNasiyaSearchClientsQuery;
