import mainConnect, { generateError } from '@/services';

export const getProblemCase = problemCaseId => {
  return mainConnect.get(`/merchants/problem-cases/${problemCaseId}`).catch(err => generateError(err));
};

export const getProblemCaseStatuses = params => {
  return mainConnect.get(`/merchants/problem-cases/statuses`, { params }).catch(err => generateError(err));
};

export const problemCaseSetComment = (problemCaseId, payload) => {
  return mainConnect
    .put(`/merchants/problem-cases/${problemCaseId}/set-comment`, payload)
    .catch(err => generateError(err));
};

export const updateProblemCaseStatus = (problemCaseId, body) => {
  return mainConnect.put(`/merchants/problem-cases/${problemCaseId}/set-status`, body).catch(err => generateError(err));
};

export const getProblemCases = params => {
  return mainConnect.get('/merchants/problem-cases', { params }).catch(err => generateError(err));
};

export const getProblemCaseClients = params => {
  return mainConnect.get('clients/clients-pc', { params }).catch(generateError);
};
