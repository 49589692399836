export default {
  validations: {
    required: 'Это поле является обязательным',
    minLength: 'Минимально допустимая длина символов - {min}',
    maxLength: 'Максимально допустимая длина символов - {max}',
    length: 'Допустимая длина символов - {len}',
    minValue: 'Минимально допустимое значение - {min}',
    maxValue: 'Максимально допустимое значение - {max}',
    between: 'Значение должно быть между - {min} и {max}',
    alpha: 'Допустимы только буквы',
    alphaNum: 'Допустимы только буквы и цифры',
    numeric: 'Допустимы только цифры',
    integer: 'Допустимы только целые числа',
    decimal: 'Допустимы только дробные числа',
    email: 'Введите корректный адрес электронной почты',
    ipAddress: 'Введите корректный ip-адрес',
    macAddress: 'Введите корректный mac-адрес',
    url: 'Введите корректный адрес сайта',
    sameAs: 'Введенное значение должно быть такой-же как в поле "{otherName}"',
    isUnique: 'Значение должно быть уникальным',
    isDMYDateFormat: 'Не соответвтует формату дд-мм-гггг',
    uppercase: 'Минимум одна заглавная буква из латинского алфавита [A-Z]',
    lowercase: 'Минимум одна строчная буква из латинского алфавита [a-z]',
    number: 'Минимум одна цифра',
    specSymbol: 'Минимум один дополнительный символ',
    itemMarking: 'Невалидная маркировка',
  },
};
