export default {
  features: {
    cancelApplicationDownPayment: {
      button: "Boshlang'ich to'lovni bekor qilish",
      modal: {
        title: "Boshlang'ich to'lovni bekor qilish",
        subTitle: "Boshlang'ich to'lovni bekor qilishni aniq istaysizmi ?",
        cancel: "Yo'q",
        submit: 'Xa',
        save: 'Xa',
        successToast: "To'lov muvaffaqiyatli bekor qilindi",
      },
    },
  },
};
