export default {
  features: {
    registration: {
      title: "Ro'yxatdan O'tish",
      askAccount: ['Akkauntingiz bormi?', 'Kiring'],
      username: {
        label: 'Sizning F.I.SH.',
        placeholder: 'Sizning F.I.SH.',
      },
      userPhone: {
        label: 'Sizning telefon raqamingiz',
        placeholder: '(99) 999-99-99',
      },
      name: {
        label: "Do'konning nomi",
        placeholder: "Do'konning nomini kiriting",
      },
      tin: {
        label: 'Soliq to‘lovchining identifikatsiya raqami (STIR)',
        placeholder: 'Soliq to‘lovchining identifikatsiya raqami (STIR)',
        select: 'STIR',
      },
      pinfl: {
        label: 'PINFL',
        placeholder: 'PINFL',
        select: 'PINFL',
      },
      prev: 'Orqaga',
      complete: 'Yakunlash',
    },
  },
};
