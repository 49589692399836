import { useQuery } from '@tanstack/vue-query';
import { getDeliveryApp } from '@/to-fsd/shared/api';

const useGetDeliveryAppQuery = () => {
  return useQuery({
    queryKey: ['deliveries-app'],
    async queryFn() {
      const response = await getDeliveryApp();

      return response.data;
    },
    gcTime: Infinity,
  });
};

export default useGetDeliveryAppQuery;
