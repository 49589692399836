import { defineStore } from 'pinia';
import { actions } from './actions';
import { getters } from './getters';

export const useMainStore = defineStore('MainStore', {
  state: () => {
    return {
      app: null,
      module: null,
      lang: null,
      reload: false,
      error: null,
    };
  },
  actions,
  getters,
});
