export default {
  features: {
    alifshopOrderComplete: {
      title: 'Buyurtmani rasmiylashtirish',
      btn: 'Rasmiylashtirish',
      moneyFrozen: 'Miqdor bir-necha kunga muzlatildi.',
      smsIsNeeded: "Mablag' yechib olinishi uchun tasdiqlovchi SMS kerak",
      itemMarkingNeed: "Pul mablag'larini olish uchun mahsulot belgisini tasdiqlashingiz kerak",
      otp: {
        label: 'SMS kod',
        placeholder: 'Kodni kiriting',
      },
      sent: 'Mijozning ({phone}) raqamiga SMS kod yuborildi, xaridni rasmiylashtirish uchun ushbu kodni kiriting.',
      cancel: 'Bekor qilish',
    },
  },
};
