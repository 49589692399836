import { useNasiyaGlobalStore } from '@/to-fsd/shared/store/nasiya-global';

const fetchNasiyaResources = async ({ next }) => {
  const nasiyaGlobalStore = useNasiyaGlobalStore();

  if (!nasiyaGlobalStore.app) {
    await nasiyaGlobalStore.fetchApp();
  }
  if (!nasiyaGlobalStore.merchantRelations) {
    await nasiyaGlobalStore.fetchMerchantRelations();
  }

  return next();
};

export default fetchNasiyaResources;
