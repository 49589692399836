import { onMounted, onUnmounted, ref, watchEffect } from 'vue';
import { useUserStore } from '@/to-fsd/shared/store/user';

const useControlJivoWidgetVisibility = () => {
  const userStore = useUserStore();

  const isLoaded = ref(false);

  onMounted(() => {
    window.jivo_onLoadCallback = () => {
      isLoaded.value = true;
    };
  });

  onUnmounted(() => {
    window.jivo_onChangeState = undefined;
    isLoaded.value = false;
  });

  watchEffect(() => {
    if (!isLoaded.value) {
      return false;
    }

    if (userStore.me) {
      window.jivo_init();
    } else {
      window.jivo_destroy();
    }
  });
};

export { useControlJivoWidgetVisibility };
