export default {
  otpInfoBlock: {
    trigger: 'Nega SMS kelmayapti?',
    title: 'Tekshirish uchun nima qilishim kerak?',
    steps: [
      'Telefoningiz balansini tekshiring.',
      "Telefondagi xotirani tekshiring (agar xotirasi to'lgan bo'lsa, uni tozalang).",
      "Telefonni o'chirib, yana yoqib ko'ring.",
      "Alif Nasiya spamga qo'shilgan yoki yo'qligini tekshiring.",
      'Agar yuqorida aytilganlarning hech biri yordam bermasa, {technicalSupport} xizmatiga  murojaat qiling.',
    ],
    technicalSupport: 'texnik yordam',
  },
};
