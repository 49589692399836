export default {
  features: {
    acquiringDownloadExcel: {
      downloadCsv: 'Экспорт в excel',
      errors: {
        dateTooEarly: 'Ошибка: Дата отчета не может быть раньше 1 января 2025 года',
      },
      excel: {
        id: 'ID',
        paymentType: 'Тип оплаты',
        paymentTypeNew: 'Вид оплаты',
        type: 'Тип операции',
        amount: 'Стоимость',
        amountNew: 'Сумма',
        date: 'Дата и время',
        status: 'Статус выплаты',
        spic: 'ИКПУ товара',
        label: 'Название товара',
        store: 'Магазин',
        phone: 'Номер покупателя',
        created_at: 'Дата и время',
        updated_at: 'Дата обновления',
        fee: 'Комиссия Эквайера',
        feeNew: 'Комиссия',
        created_from: 'С',
        created_to: 'По',
        created: 'Отчет создан',
        excelLength: 'Кол-во транзакций',
        period: 'Период',
        filename: 'Отчеты',
      },
      statuses: {
        REFUND: 'Возврат',
        PARTIAL_REFUND: 'Частичный возврат',
        PAYMENT: 'Платеж',
      },
      paymentType: {
        INSTALLMENT: 'Рассрочка',
        CARD: 'Карта',
      },
    },
  },
};
