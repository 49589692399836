export default {
  features: {
    cancelAcquiringBmlTransaction: {
      button: 'Tranzaksiyani bekor qilish',
      bmlTitle: 'Tranzaksiyani bekor qilish',
      bmlRefundLabel: 'Bekor qilish summasi',
      close: 'Yopish',
      placeholder: 'Sabab',
      fullRefund: "To'liq bekor qilish",
      submit: 'Bekor qilishni tasdiqlang',
      cancel: 'Qaytarish',
      status: 'Tranzaksiya bekor qilindi!',
      timeout: `Keyin qaytarib bo'lmaydi`,
      reset: 'Qaytish',
      success: 'Muvaffaqiyatli',
      pending: 'Kutilmoqda',
      error: 'Xatolik yuz berdi',
    },
  },
};
