export default {
  entities: {
    alifshopOrderItem: {
      table: {
        title: 'Список товаров',
        headers: ['НАИМЕНОВАНИЕ', 'Тип', 'Кол-во', 'Цена'],
      },
    },
  },
};
