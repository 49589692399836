export default {
  features: {
    toggleTheme: {
      toggle: {
        label: 'Rejim',
        options: ['Kunduzgi rejim', 'Tungi rejim'],
      },
    },
  },
};
