export default {
  qrApplication: {
    create: {
      title: 'QR kod orqali ariza yaratish',
      posTitle: 'Kassani tanlang',
      invalidFields: "{itemName} tovarni tanlash mumkin emas, chunki uning quyidagi maydonlari noto'g'ri: {itemFields}",
      buttons: {
        create: 'Ariza yaratish',
        show: "Ko'rsatish",
      },
      modal: {
        title: "Mijozdan QR kodni skaner qilishni so'rang",
        successTitle: 'Ariza muvaffaqiyatli yaratildi',
        failTitle: 'Ariza yaratilinmadi',
        checkTitle: "To'lov hali qilinmadi",
        buttons: {
          check: 'QR skaner qilindi',
          cancel: 'Arizani bekor qilish',
          finish: 'Tayyor',
          ok: 'Tushunarli',
        },
      },
      markingNeededAlert: "Quyidagi mahsulotlar uchun belgi ro'yxatdan o'tkazish majburiydir:",
      markingPanel: {
        title: 'Belgi',
        desc: "Ba'zi mahsulotlarda belgisi yo'q, ularni qo'lda to'ldiring",
        btns: {
          mark: "Belgini to'ldirish",
        },
      },
      markingModal: {
        title: "Quyidagi mahsulotlar uchun belgini to'ldiring",
        placeholder: 'Belgi',
        markingFormatAlert:
          "Kod kamida 38 belgidan iborat bo'lishi kerak (birinchi 18 ta belgi raqamlar bo'lishi kerak)",
        successAlert: 'Belgi muvaffaqiyati saqlandi',
      },
    },
  },
};
