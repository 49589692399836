import { useMutation } from '@tanstack/vue-query';
import { createRejectCase } from '../api';

const useCreateRejectCaseMutation = () => {
  return useMutation({
    async mutationFn({ applicationId, code, note, applicationItems, file }) {
      const formData = new FormData();

      formData.append('application_id', applicationId);
      formData.append('otp', code);
      formData.append('note', note);

      applicationItems.forEach(applicationItem => {
        formData.append('application_items[]', applicationItem.id);
      });

      if (file) {
        formData.append('file', file);
      }

      const response = await createRejectCase(formData);

      return response.data;
    },
  });
};

export default useCreateRejectCaseMutation;
