export default {
  features: {
    acquiringInvoiceCreate: {
      product: {
        total: 'Jami summa',
        sum: `so'm`,
      },
      smsInvoice: {
        label: 'Mijozning telefon raqami',
      },
      createLink: `To'lov uchun SMS jo'natish`,
      successSentMessage: `Mijozga SMS orqali toʻlovga havola yuborildi`,
      errors: {
        maxAmount: 'Maksimal miqdor - 200 000 000',
        minAmount: 'Minimal miqdor - 1',
      },
      required: 'Bu maydon majburiy',
      alertSentMessage: `So'rovlar soni oshib ketdi`,
      wrongPhoneFormat: `Telefon formati noto'g'ri`,
    },
  },
};
