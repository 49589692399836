import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { storeClientPassport } from '../api';

const useStoreClientPassportMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn({ clientId, fields }) {
      const response = await storeClientPassport(clientId, fields);

      return response.data;
    },
    onSuccess(_, { clientId }) {
      queryClient.refetchQueries({ queryKey: ['clients', 'detail', clientId, 'requirements'], exact: true });
      queryClient.refetchQueries({ queryKey: ['clients', 'detail', clientId, 'passport'], exact: true });
    },
  });
};

export default useStoreClientPassportMutation;
