import { useQuery } from '@tanstack/vue-query';
import { getLimit } from '../api';
import { computed } from 'vue';

const useGetClientLimitQuery = clientId => {
  return useQuery({
    queryKey: ['clients', 'detail', clientId, 'limit'],
    async queryFn({ queryKey }) {
      const clientId = queryKey[2];

      const response = await getLimit(clientId);

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
    enabled: computed(() => Boolean(clientId.value)),
  });
};

export default useGetClientLimitQuery;
