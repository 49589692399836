export default {
  entities: {
    alifshopOffer: {
      modelSeries: {
        title: 'Введите бренд товара, модель, серия и цвет',
        brandLabel: 'Бренд',
        modelLabel: 'Модель',
        seriesLabel: 'Серия',
        colorLabel: 'Цвет',
        anotherBrand: 'Другое',
        empty: 'Пусто',
      },
      videos: {
        offerVideo: 'Видео о товаре',
        uzLink: 'URL-ссылка от видео на узбекском',
        ruLink: 'URL-ссылка от видео на русском ',
        empty: 'Пусто',
      },
      descriptions: {
        title: 'Описание товара',
        enterDescription: 'Введите описание',
        ru: 'На русском',
        uz: 'На узбекском',
      },
      images: {
        offerImage: 'Фото товара',
        mainImage: 'Основное',
        additionalImages: 'Дополнительные',
      },
      empty: 'Пусто',
    },
  },
};
