export default {
  pages: {
    errorPage: {
      messages: {
        pageNotFound: 'Sahifa topilmadi',
        objectNotFound: 'Obyekt topilmadi',
        notAllowed: 'Huquqlar yetarli emas',
        moduleAccessDenied: "Modulga kirishga ruxsat yo'q",
        serverError: 'Serverda xatolik',
      },
      goToMainPage: 'Bosh sahifaga qaytish',
    },
  },
};
