import { useQuery } from '@tanstack/vue-query';
import { getClient } from '../api';

const useGetClientQuery = clientId => {
  return useQuery({
    queryKey: ['clients', 'detail', clientId],
    async queryFn({ queryKey }) {
      const clientId = queryKey[2];

      const response = await getClient(clientId);

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetClientQuery;
