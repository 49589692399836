<template>
  <div id="main-app" class="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white">
    <router-view v-slot="{ Component }">
      <component :is="Component"></component>
    </router-view>
  </div>

  <vue-query-devtools button-position="top-left" position="top" />
</template>

<script setup>
  import { useTheme } from '@/hooks';
  import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
  import { useControlJivoWidgetVisibility } from '@/to-fsd/features/jivo';
  import { stringToUri, tokenEncoding } from '@/utils/filters';
  import router from '@/router';
  import { onMounted, onUnmounted } from 'vue';
  import { ROUTE_NAMES } from '@/to-fsd/shared/constants';

  useControlJivoWidgetVisibility();

  const { theme, setTheme } = useTheme();
  setTheme(theme.value);

  const onOffline = () => {
    const from =
      router.currentRoute.value?.fullPath !== '/'
        ? tokenEncoding(stringToUri(router.currentRoute.value?.fullPath))
        : null;
    router.push({
      name: ROUTE_NAMES.offline,
      query: { from },
    });
  };

  onMounted(() => {
    window.addEventListener('offline', onOffline);
  });

  onUnmounted(() => {
    window.removeEventListener('offline', onOffline);
  });
</script>
