export default {
  features: {
    warehouseItemUpdate: {
      updateWithExcelButton: "Tovarlarni o'zgartirish",
      updateQuantityAndPriceForm: {
        title: 'Narh va miqdor',
        submit: "O'zgartirish",
        fields: {
          quantity: {
            label: 'Ombordagi miqdor',
            times: 'ta.',
          },
          originalPrice: {
            label: '1 dona uchun narh',
          },
          price: {
            label: 'QQS bilan narx',
            placeholder: 'QQS bilan narx',
          },
        },
      },
    },
  },
};
