export default {
  features: {
    addEmployee: {
      button: 'Добавить сотрудника',
      modal: {
        title: 'Добавление нового сотрудника',
        form: {
          search: {
            label: 'Номер телефона',
            placeholder: '(00) 000-00-00',
            userIsEmployeeAlert: 'Пользователь с данным номером уже являяется сотрудником одного из мерчантов',
            addEmployee: 'Добавить',
            storeSelect: {
              label: 'Магазин',
              placeholder: 'Выберите магазин',
            },
          },
        },
        createUserForm: {
          sendSms: 'Отправить смс',
          code: {
            label: 'Код',
            placeholder: 'Введите полученный код',
          },
          name: {
            label: 'Имя',
            placeholder: 'Введите имя',
          },
          store: {
            label: 'Магазин',
            placeholder: 'Введите магазин',
          },
          brokerUserId: {
            label: 'Брокер юзер id',
            placeholder: 'Брокер юзер id',
          },
          submit: 'Добавить сотрудника',
        },
      },
    },
  },
};
