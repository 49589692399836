import mainConnect, { generateError, setToken } from '@/services';
import { app as Vue } from '@/main';

export const logout = () => {
  return mainConnect
    .post(`auth/logout`)
    .then(() => {
      if ('$cookies' in Vue.config.globalProperties) Vue.config.globalProperties.$cookies.delete('auth');
      setToken('');
    })
    .catch(generateError);
};
