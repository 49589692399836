import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { approveRefund } from '../api';

const useApproveRefundMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn({ rejectCaseId }) {
      const response = await approveRefund(rejectCaseId);

      return response.data;
    },
    onSuccess(_, { rejectCaseId }) {
      queryClient.refetchQueries({ queryKey: ['reject-cases', 'detail', rejectCaseId], exact: true });
    },
  });
};

export default useApproveRefundMutation;
