export default {
  features: {
    createDelivery: {
      success: 'Yetkazma muvaffaqiyati yaratildi',
      panelLink: {
        title: 'Yetkazmalar',
        link: 'Alif yetkazmasi',
      },
      senderForm: {
        title: 'Yuboruvchi',
        noWarehouseAddresses: 'Omborxonalar manzillari mavjud emas',
        fio: {
          label: 'FISh',
        },
        phone: {
          label: 'Telefon raqami',
        },
      },
      receiverForm: {
        title: 'Qabul qiluvchi',
        region: {
          label: 'Viloyat',
        },
        district: {
          label: 'Tuman',
        },
        city: {
          label: 'Aholi yashash punkti',
        },
        street: {
          label: "Ko'cha",
        },
        fio: {
          label: 'FISh',
        },
        phone: {
          label: 'Telefon raqami',
        },
        schedule: 'Ish kunlari',
        weekend: 'Dam olish kuni',
        daysOfTheWeek: ['Dush', 'Sesh', 'Chor', 'Pay', 'Jum', 'Shan', 'Yak'],
      },
      parcelForm: {
        title: 'Yetkazma',
        pickup: {
          label: 'Yetkazmani olib ketish sanasi',
        },
        weight: {
          label: 'Bitta yetkazma vazni (kg)',
        },
        volume: {
          label: 'Bitta yetkazma hajmi (metr kub)',
        },
      },
      cancel: 'Bekor qilish',
      submit: 'Saqlash',
    },
  },
};
