export default {
  features: {
    cancelApplicationDownPayment: {
      button: 'Отменить предоплату',
      modal: {
        title: 'Отменить предоплату',
        subTitle: 'Вы точно уверены что хотите отменить предоплату ?',
        cancel: 'Нет',
        submit: 'Да',
        save: 'Да',
        successToast: 'Предоплата успешно отменена',
      },
    },
  },
};
