import { prmService } from './prm.service';

export const actions = {
  async fetchPrmApp() {
    if (!this.app) {
      const response = await prmService.getPrmApp();

      this.app = response.data;
    }
    return Promise.resolve(this.app);
  },
};
