import { useMutation } from '@tanstack/vue-query';

import { updateClientPassportV2 } from '../api';

const useUpdateClientPassportV2Mutation = () => {
  return useMutation({
    async mutationFn({ uuid, city_name, address, region, nationality_name, passport_issuer_name, patronymic }) {
      const response = await updateClientPassportV2({
        uuid,
        city_name,
        address,
        region,
        nationality_name,
        passport_issuer_name,
        patronymic,
      });

      return response.data;
    },
  });
};

export default useUpdateClientPassportV2Mutation;
