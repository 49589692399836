import { NasiyaLayout } from '@/to-fsd/layouts/nasiya';

import invoice from '../invoice/routers';
import { ROUTE_NAMES } from '@/modules/nasiya/common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import i18n from '@/plugins/i18n';

const moduleName = 'module_azo';

const mainRoutes = {
  path: '/nasiya/',
  component: NasiyaLayout,
  children: [
    ...invoice,
    // ------------------------------
    {
      path: '/nasiya/deliveries',
      name: ROUTE_NAMES.deliveriesList,
      component: async () => {
        const { DeliveriesListPage } = await import('@/to-fsd/pages/deliveries-list');

        return DeliveriesListPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Доставки'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_deliveries']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/deliveries/:deliveryId-:applicationId',
      name: ROUTE_NAMES.deliveriesShow,
      component: async () => {
        const { DeliveriesShowPage } = await import('@/to-fsd/pages/deliveries-show');

        return DeliveriesShowPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Доставки'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_deliveries']),
          fetchNasiyaResources,
        ],
      },
      props: true,
    },
    {
      path: '/nasiya/applications/:applicationId/deliveries/new',
      name: ROUTE_NAMES.deliveriesCreate,
      component: async () => {
        const { CreateDeliveryPage } = await import('@/to-fsd/pages/create-delivery');

        return CreateDeliveryPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Создание доставки'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_deliveries']),
          fetchNasiyaResources,
        ],
      },
      props: true,
    },
    // -------------------------------
    {
      path: '/nasiya/clients/:clientId',
      name: ROUTE_NAMES.clientsShow,
      component: async () => {
        const { ClientsShowPage } = await import('@/to-fsd/pages/clients-show');

        return ClientsShowPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Клиент'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/clients/search',
      name: ROUTE_NAMES.clientsSearch,
      component: async () => {
        const { NasiyaSearchClientsPage } = await import('@/to-fsd/pages/nasiya-search-clients');

        return NasiyaSearchClientsPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Клиент'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/clients/:clientId/confirm-agreement',
      name: ROUTE_NAMES.clientConfirmAgreement,
      component: async () => {
        const { ClientConfirmAgreementPage } = await import('@/to-fsd/pages/client-confirm-agreement');

        return ClientConfirmAgreementPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Клиент'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/clients/:clientId/applications/new',
      name: ROUTE_NAMES.createApplication,
      component: async () => {
        const { CreateApplicationPage } = await import('@/to-fsd/pages/create-application');

        return CreateApplicationPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Клиент'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/clients/new',
      name: ROUTE_NAMES.clientsNew,
      component: async () => {
        const { AddNewClientPage } = await import('@/to-fsd/pages/add-new-client');

        return AddNewClientPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Новый клиент'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
      props: true,
    },
    {
      path: '/nasiya/clients/by-phone/:clientPhone/update-passport/new-onboarding',
      name: ROUTE_NAMES.updateClientPassportNewOnboarding,
      component: async () => {
        const { UpdateClientPassportPage } = await import('@/to-fsd/pages/update-client-passport');

        return UpdateClientPassportPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle(i18n.global.t('pages.updateClientPassport.documentTitle')),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
      props: true,
    },
    // -------------------------------
    {
      path: '/nasiya/clients',
      name: ROUTE_NAMES.clientsList,
      component: async () => {
        const { ClientsListPage } = await import('@/to-fsd/pages/clients-list');

        return ClientsListPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Клиенты'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/warehouse',
      name: ROUTE_NAMES.warehouseList,
      component: async () => {
        const { WarehousePage } = await import('@/to-fsd/pages/warehouse');

        return WarehousePage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Склад'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/warehouse/:warehouseItemId',
      name: ROUTE_NAMES.warehouseShow,
      component: async () => {
        const { WarehouseItemsShowPage } = await import('@/to-fsd/pages/warehouse-items-show');

        return WarehouseItemsShowPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Склад'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
          fetchNasiyaResources,
        ],
      },
      props: true,
    },
    {
      path: '/nasiya/warehouse/offer-demands/new',
      name: ROUTE_NAMES.offerDemandsCreate,
      component: async () => {
        const { OfferDemandsCreatePage } = await import('@/to-fsd/pages/offer-demands-create');

        return OfferDemandsCreatePage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Создание заявки'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
          fetchNasiyaResources,
        ],
      },
      props: true,
    },
    {
      path: '/nasiya/warehouse/offer-demands/:offerDemandId',
      name: ROUTE_NAMES.offerDemandsShowPage,
      component: async () => {
        const { OfferDemandsShowPage } = await import('@/to-fsd/pages/offer-demands-show');

        return OfferDemandsShowPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Склад'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
          fetchNasiyaResources,
        ],
      },
      props: true,
    },
    {
      path: '/nasiya/warehouse/offer-demands/:offerDemandId/edit',
      name: ROUTE_NAMES.offerDemandsEditPage,
      component: async () => {
        const { OfferDemandsEditPage } = await import('@/to-fsd/pages/offer-demands-edit');

        return OfferDemandsEditPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Склад'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/warehouse/alifshop-offers/:alifshopOfferId',
      name: ROUTE_NAMES.offerShowPage,
      component: async () => {
        const { AlifshopOffersShowPage } = await import('@/to-fsd/pages/alifshop-offers-show');

        return AlifshopOffersShowPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Склад'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
          fetchNasiyaResources,
        ],
      },
      props: true,
    },
    {
      path: '/nasiya/warehouse-items/new',
      name: ROUTE_NAMES.warehouseItemsNew,
      component: async () => {
        const { CreateWarehouseItem } = await import('@/to-fsd/pages/create-warehouse-item');

        return CreateWarehouseItem;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Запросы'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_moderation_goods']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/rejects',
      name: ROUTE_NAMES.rejectsList,
      component: async () => {
        const { RejectCasesListPage } = await import('@/to-fsd/pages/reject-cases-list');

        return RejectCasesListPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Возвраты'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/rejects/:rejectCaseId',
      name: ROUTE_NAMES.rejectsShow,
      component: async () => {
        const { RejectCaseShowPage } = await import('@/to-fsd/pages/reject-case-show');

        return RejectCaseShowPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Возвраты'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/applications/:applicationId/rejects/new',
      name: ROUTE_NAMES.rejectsCreate,
      component: async () => {
        const { CreateRejectPage } = await import('@/to-fsd/pages/create-reject-page');

        return CreateRejectPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Новый возврат'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
      props: true,
    },
    {
      path: '/nasiya/manager-applications',
      name: ROUTE_NAMES.managerApplicationsList,
      component: async () => {
        const { ManagerApplicationsListPage } = await import('@/to-fsd/pages/manager-applications-list');

        return ManagerApplicationsListPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Все заявки'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_manager']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/qr-applications/create',
      name: ROUTE_NAMES.qrApplicationsCreate,
      component: () => import('@/to-fsd/temp/qr-applications/views/NasiyaQrApplicationsCreatePage.vue'),
      meta: {
        middlewares: [
          setDocumentTitle('Создание заявки по QR'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/applications',
      name: ROUTE_NAMES.applicationsList,
      component: async () => {
        const { ApplicationsListPage } = await import('@/to-fsd/pages/applications-list');

        return ApplicationsListPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Alifshop Merchant - Заявки'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/applications/:applicationId',
      name: ROUTE_NAMES.applicationsShow,
      component: async () => {
        const { ApplicationShowPage } = await import('@/to-fsd/pages/application-show');

        return ApplicationShowPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Заявка'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/problems',
      name: ROUTE_NAMES.problemsList,
      component: async () => {
        const { ProblemCasesListPage } = await import('@/to-fsd/pages/problem-cases-list');

        return ProblemCasesListPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Проблемные кейсы'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/problems/:problemCaseId',
      name: ROUTE_NAMES.problemsShow,
      component: async () => {
        const { ProblemCaseShowPage } = await import('@/to-fsd/pages/problem-case-show');

        return ProblemCaseShowPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Проблемные кейсы'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
      props: true,
    },
    {
      path: '/nasiya/analytics',
      name: ROUTE_NAMES.analyticsList,
      component: async () => {
        const { AnalyticsPage } = await import('@/to-fsd/pages/analytics');

        return AnalyticsPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Аналитика'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_manager']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/reports',
      name: ROUTE_NAMES.excelsList,
      component: async () => {
        const { ReportsPage } = await import('@/to-fsd/pages/reports');

        return ReportsPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Отчёты'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_manager']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/sales',
      name: ROUTE_NAMES.salesList,
      component: async () => {
        const { SalesListPage } = await import('@/to-fsd/pages/sales-list');

        return SalesListPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Продажи'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_manager']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/faq',
      name: ROUTE_NAMES.faqList,
      component: async () => {
        const { FaqPage } = await import('@/to-fsd/pages/faq');

        return FaqPage;
      },

      meta: {
        middlewares: [
          setDocumentTitle('Часто задаваемые вопросы'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/bonuses',
      name: ROUTE_NAMES.bonusesIndex,
      component: async () => {
        const { BonusesPage } = await import('@/to-fsd/pages/bonuses');

        return BonusesPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Bonuses'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/notifications',
      name: ROUTE_NAMES.notificationsList,
      component: async () => {
        const { NotificationsListPage } = await import('@/to-fsd/pages/notifications-list');

        return NotificationsListPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Уведомления'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/calculator',
      name: ROUTE_NAMES.calculator,
      component: async () => {
        const { CalculatorPage } = await import('@/to-fsd/pages/calculator');

        return CalculatorPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Калькулятор'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule(moduleName),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/nasiya/calculator/preview-payment',
      name: ROUTE_NAMES.calculatorPreview,
      component: async () => {
        const { CalculatorPreviewPaymentPage } = await import('@/to-fsd/pages/calculator-preview-payment');

        return CalculatorPreviewPaymentPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('График платежей'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_azo'),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '',
      name: ROUTE_NAMES.home,
      component: async () => {
        const { HomePage } = await import('@/to-fsd/pages/home');

        return HomePage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Главная'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_azo'),
          requirePermissions(['merchant_alifnasiya']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: 'knowledge-base',
      name: ROUTE_NAMES.knowledgeBase,
      component: async () => {
        const { KnowledgeBasePage } = await import('@/to-fsd/pages/knowledge-base');

        return KnowledgeBasePage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('База знаний'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: 'knowledge-base/videos/:videoId',
      name: ROUTE_NAMES.knowledgeBaseShow,
      component: async () => {
        const { KnowledgeBaseShowPage } = await import('@/to-fsd/pages/knowledge-base-show');

        return KnowledgeBaseShowPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('База знаний'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: 'installment-payment',
      name: ROUTE_NAMES.installmentPayment,
      component: async () => {
        const { InstallmentPaymentPage } = await import('@/to-fsd/pages/installment-payment');

        return InstallmentPaymentPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Оплата рассрочки'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          fetchNasiyaResources,
        ],
      },
    },
  ],
};

export default router => {
  router.addRoute(mainRoutes);
};
