import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { rejectRefund } from '../api';

const useRejectRefundMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn({ rejectCaseId, partnerCancelNote }) {
      const response = await rejectRefund(rejectCaseId, {
        partner_cancel_note: partnerCancelNote,
      });

      return response.data;
    },
    onSuccess(_, { rejectCaseId }) {
      queryClient.refetchQueries({ queryKey: ['reject-cases', 'detail', rejectCaseId], exact: true });
    },
  });
};

export default useRejectRefundMutation;
