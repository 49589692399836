export default {
  widgets: {
    acquiringBalancesList: {
      errors: {
        noData: 'Нет данных',
      },
      buttons: {
        reset: 'Сбросить фильтр',
        filter: 'Фильтр',
        search: 'Поиск',
        next: 'Далее',
        prev: 'Назад',
        copy: 'Скопировать',
      },
      pagination: {
        select: 'Выбрать по:',
      },
      slideOver: {
        header: 'Детали транзакции',
        spic: 'ИКПУ товара',
        updated_at: 'Дата обновления',
        cancelled_at: 'Дата отмены',
        subtitle: 'Название товара',
        phoneTitle: 'Номер телефона покупателя',
        holderTitle: 'ФИО покупателя',
        label: 'Код маркировки',
        monthlyPayment: 'Ежемесячная сумма реккурентных платяжей',
        month: 'Ежемесячные платежи',
        fiscalReceipt: 'Чек',
        fiscalReceiptTitle: 'Фискальные чеки',
        splitMonthes: 'Месяц реккурентных платяжей',
        close: 'Закрыть',
        revertedBy: 'Инициатор отмены',
        revertedByAlif: 'Сотрудник Алифа',
        ext_id: 'Внешний ID',
      },
      headers: {
        ref: 'Id',
        paymentVariant: 'Тип оплаты',
        store: 'Название магазина',
        sum: 'Сумма товара',
        date: 'Дата создания',
        status: 'Статус',
      },
      status: {
        all: 'Все',
      },
      onlinePurchase: 'Онлайн касса',
      placeholders: {
        phone: 'Номер покупателя',
        name: 'Ф.И Покупателя',
        spic: 'ИКПУ товара',
        label: 'Код маркировки товара',
        total: 'Сумма',
        paymentType: 'Тип оплаты',
        store: 'Все магазины',
        created_from: 'Дата от',
        created_to: 'Дата до',
        id: 'Поиск по ID',
        ext_id: 'Поиск по внешнему ID',
      },
    },
  },
};
