export default {
  features: {
    toggleTheme: {
      toggle: {
        label: 'Режим',
        options: ['Дневной режим', 'Ночной режим'],
      },
    },
  },
};
