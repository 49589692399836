export default {
  features: {
    applicationCreate: {
      title: 'Создать заявку',
      body: 'Выберите “Дистанционное оформление”, если клиент находится вне магазина',
      createInStore: 'Клиент в магазине',
      createOutStore: 'Дистанционное оформление',
      confirmCreateApplicationModal: {
        title: 'Новая заявка?',
        body:
          'Хотим предупредить вас, вы отправили {applicationsCreated} заявки на данного клиента. Осталось {attemptsLeft} попытки!',
        btns: {
          cancel: 'Отмена',
          confirm: 'Продолжить',
        },
      },
    },
  },
};
