import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { clientRequestReview } from '../api';
import { useRefetchOnSuccess } from '@/to-fsd/shared/lib/vue-query';

const useClientRequestReviewMutation = () => {
  const queryClient = useQueryClient();

  const { onSuccess, isRefetchingAfterMutation } = useRefetchOnSuccess(async (_, { clientId }) => {
    await Promise.all([queryClient.refetchQueries({ queryKey: ['clients', 'detail', clientId], exact: true })]);
  });

  const mutation = useMutation({
    async mutationFn({ clientId }) {
      const response = await clientRequestReview(clientId);

      return response.data;
    },
    onSuccess,
  });

  return {
    ...mutation,
    isRefetchingAfterMutation,
  };
};

export default useClientRequestReviewMutation;
