import employees from '../employees/routers';
import pos from '../pos/routers';
import { AdminLayout } from '@/to-fsd/layouts/admin';

const routes = {
  path: '/admin/',
  component: AdminLayout,
  children: [...employees, ...pos],
};

export default router => {
  router.addRoute(routes);
};
