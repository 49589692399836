export default {
  features: {
    applicationCreate: {
      title: 'Ariza yaratish',
      body: 'Mijoz doʻkondan tashqarida boʻlsa, “Masofadan rasmiylashtirish” ni tanlang',
      createInStore: 'Mijoz doʻkonda',
      createOutStore: 'Masofadan rasmiylashtirish',
      confirmCreateApplicationModal: {
        title: 'Yangi ariza?',
        body:
          "Sizga aytib o'tmoqchimizki, siz ushbu mijozga {applicationsCreated} ta ariza yubordingiz. {attemptsLeft} ta urinish qoldi!",
        btns: {
          cancel: 'Bekor qilish',
          confirm: 'Davom ettirish',
        },
      },
    },
  },
};
