import { useQuery } from '@tanstack/vue-query';
import { getProblemCases, getProblemCaseClients } from '../api';

const useGetProblemCasesQuery = ({ statusId, page, q, perPage }) => {
  return useQuery({
    queryKey: ['problem-cases', 'list', { statusId, page, q, perPage }],
    async queryFn({ queryKey }) {
      const { statusId, page, q, perPage } = queryKey[2];

      const response = await getProblemCases({
        status_id: statusId,
        page,
        q,
        per_page: perPage || 15,
      });

      const clientIds = [];

      response.data.data.forEach(problemCase => {
        if (problemCase.client_id && !clientIds.includes(problemCase.client_id)) {
          clientIds.push(problemCase.client_id);
        }
      });

      let problemCaseClientsResponse;

      if (clientIds.length) {
        problemCaseClientsResponse = await getProblemCaseClients({
          ids: clientIds.join(';'),
          per_page: clientIds.length,
        });
      }

      return {
        ...response.data,
        data: response.data.data.map(problemCase => {
          const client = problemCaseClientsResponse?.data.data.find(client => client.id === problemCase.client_id);

          return {
            ...problemCase,
            client,
          };
        }),
      };
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetProblemCasesQuery;
