export default {
  warehouse: {
    index: {
      title: 'Ombor',
      headers: {
        items: 'Tovarlar',
        status: 'Holati',
        ikpu: 'MXIK',
        sku: 'SKU',
        price: "Narxi (so'm)",
        price_brutto: 'QQS bilan narx',
        quantity: 'Omborda',
      },
      add: {
        manually: "Qo'lda qo'shish",
        excel: 'Excel yuklash',
      },
      download: {
        button: 'Yuklab olish',
        excel: 'Tovarlarni yuklab olish (excel)',
        excelForMerchant: "Barcha do'konlar tovarlarini yuklab olish (excel)",
        excelForStore: "Joriy do'kon tovarlarini yuklab olish (excel)",
      },
      update: "Tovarlarni o'zgartirish",
      noOffer: "Tovar bog'lanmagan",
      makeVisible: "Sotuvga qo'yish",
    },
    filters: {
      isVisible: "Ko'rinishi",
      exhibited: 'Saytda',
      notExhibited: 'Saytda emas',
      hasOffer: "Shablonga bog'lash",
      noTemplate: 'Saytga tayyor emas',
      template: 'Saytga tayyor',
      hasProduct: 'Mavjud',
      noProduct: 'Mavjud emas',
      showBy: "Ko'rsatish",
      upTo_5: '5 donagacha',
      upTo_10: '10 donagacha',
      upTo_15: '15 donagacha',
      upTo_20: '20 donagacha',
      upTo_50: '50 donagacha',
      moreThan_50: '50 donagacha ortiq',
      productQuantity: 'Tovarlar mavjudligi',
      expensiveFirst: 'Birinchi qimmat',
      cheapFirst: 'Birinchi arzon',
    },
    add: {
      title: "Omborxonaga mahsulot qo'shish",
      cancel: 'Bekor qilish',
      save: 'Saqlash',
      maxLengthError: 'IKPU maydoni 17 ta raqamdan iborat bo’lishi lozim',
    },
    edit: {
      item: {
        title: "Tovar haqida ma'lumotlar",
        cancel: 'Bekor qilish',
        save: 'Saqlash',
      },
    },
    show: {
      title: "Tovar haqida ma'lumotlar",
      generateBarcode: 'Shtrix kodini yaratish',
      price_info:
        "Siz QQS to'lovchisi emassiz\n" + "Shuning uchun tovarni saytga joylashtirganingizda QQS summasi qo'shiladi",
      more: 'Batafsil',
      generateBarcodeModal: {
        title: 'Shtrix kod',
        print: 'Chop etish',
        download: 'Yuklab olish',
      },
      item: {
        title: "Ma'lumotlar",
        labels: {
          ikpu: 'MXIK',
          name: 'Nomi',
          price: 'Narxi',
          price_brutto: 'QQS bilan narx',
          sku: 'SKU',
          third_category: 'Toifa',
          quantity: 'Miqdor',
        },
        placeholders: {
          name: 'Tovar nomini kiriting',
          price: 'Narxi',
          sku: 'SKU',
          third_category: 'Toifasi',
          search: 'Izlash',
        },
        totalQuantity: 'BILLZdagi mahsulotlarning umumiy soni: {quantity}',
      },
      offer: {
        title: 'Boshqa tovarni tanlang',
        subtitle: 'Tovarni alifshop.uz saytiga yuborish uchun katalogdan tanlang.',
        instructions:
          "Buning uchun mahsulot nomini yozing va uni ro'yxatdan tanlang. Agar siz hohlagan tovar ro'yxatda mavjud bo'lmasa telegramimizga yozing {telegram}.",
        labels: {
          name: 'Mahsulot nomi',
        },
        placeholders: {
          search: 'Izlash',
        },
        selectItem: 'Tovarni ulash',
        detachItem: 'Tovarni uzish',
        pasteToAlifshop: 'Tovarni alifshop.uz saytiga joylashtirish',
        createOrderToAdToAlifshop: "Ushbu tovarni saytga joylashtirish uchun so'rov yarating",
        toAlifshop: "Alif shopga qo'shish",
      },
      noData: 'Hech qanday natija topilmadi',
      delete: "O'chirish",
      makeVisible: "Sotuvga qo'yish",
      success: {
        hidden: 'Tovar muvaffaqiyatli sotuvdan olindi',
        visible: "Tovar muvaffaqiyatli sotuvga qo'yildi",
      },
      confirmDeleteItemModal: {
        title: "O'chirish",
        message: 'Siz haqiqatdan ham ushbu tovarni o’chirmoqchimisiz?',
        cancel: 'Bekor qilish',
        confirm: "O'chirish",
      },
    },
    hideItems: 'Tovarlarni yashirish',
    selectedItems: 'Tanlangan tovarlar',
    offerDemand: {
      title: "Tovar qo'shish uchun ariza yaratish",
      subTitle: "Tovar qo'shish uchun barcha ma'lumotlarni to'ldiring",
      back: 'Ortga',
      quantityAndPrice: 'Narh va miqdor',
      quantityInWarehouse: 'Ombordagi miqdor',
      priceForAnItem: '1 dona uchun narh',
      goToOfflineItems: "Oflayn tovarga o'tish",
      goToOnlineItems: "Onlayn tovarga o'tish",
      goToStock: "Omborga o'tish",
      editItem: 'Tovarni tahrirlash',
      inEditTimeItemWillBeHidden: "Tahrirlash jarayonida tovar saytda ko'rinmaydi",
      cancel: 'Bekor qilish',
      add: "Qo'shish",
      empty: "Bo'sh",
      change: "O'zgartirish",
      quit: 'Chiqish',
      doYouWantToQuit: 'Chiqishni hohlaysizmi?',
      addMore: 'Yana qoʻshish',
      required: "Ushbu maydon to'ldirilishi shart",
      times: 'ta.',
      createdDate: 'Yaratilgan sana',
      name: 'Nomi',
      inSite: 'Sayta',
      steps: {
        category: 'Toifa',
        brandAndModel: 'Brend va model',
        imagesAndVideo: 'Rasm va Video',
        characteristics: 'Xususiyatlar',
        description: 'Tavsif',
        sendToReview: "Ko'rib chiqishga yuborish",
        moderatorComments: 'Moderatordan izohlar',
      },
      category: {
        title: 'Toifa',
        select: 'Tanlov',
        search: 'Qidiruv',
        selectCategory: 'Toifani tanlang',
        secondCategory: '2-kategoriya',
        thirdCategory: '3-kategoriya',
        searchCategory: 'Toifani qidirish...',
        continue: 'Davom etirish',
      },
      brands: {
        title: 'Tovarning brendi, modeli va rangini tanlang',
        brandLabel: 'Brend',
        modelLabel: 'Model',
        seriesLabel: 'Seriya',
        colorLabel: 'Rang',
        anotherBrand: 'Boshqa',
      },
      descriptions: {
        infoText: 'Mahsulotning batafsil tavsifi sotuvni sezilarli darajada oshiradi',
        title: 'Mahsulot tavsifi',
        validation: 'Tavsif qo‘shganda, uni ham rus, ham o‘zbek tilida ko‘rsatish zarur bo‘ladi',
        enterDescription: 'Tavsifni kiriting',
      },
      attributes: {
        translation: 'Tarjima',
        addMoreValue: "Yana qo'shish",
        addMoreAttribute: "Qo's.xususiyatlari qo'shish",
        ruLang: 'Ruscha',
        uzLang: "O'zbekcha",
        valueInRu: "Ruscha ma'nosi",
        valueInUz: "O'zbekcha ma'nosi",
        attributePlaceholderRu: 'Емкость аккумулятора ...',
        attributePlaceholderUz: 'Batareya quvvati...',
        attributeNameRu: 'Atribut nomi (ru)',
        attributeNameUz: 'Atribut nomi (uz)',
        attributeAdding: "Atribut qo'shish",
      },
      imagesAndVideo: {
        title: 'Tovarning fotosuratlari',
        offerImage: 'Tovar fotosurati',
        mainImage: 'Asosiy',
        additionalImages: "Qo'shimcha",
        descriptionTitle: 'Asosiy qilish uchun yuklangan rasmni bosing',
        selectFile: 'Faylni tanlang',
        rules: {
          first: 'Kamida 2 ta rasm',
          second: 'Minimal ruxsat 512x512 piksel',
          third: 'Maksimal 1  mb',
        },
        video: {
          add: 'Video joylash',
          adding: 'Video joylashtirish',
          productConverse: "Tovarning konversiyasini ko'taring",
          addYoutubeVideo: 'Tovar haqida YouTubega video-rolik joylang',
          modal: {
            cancel: 'Bekor qilish',
            add: 'Joylash',
            ruLabel: 'Rus tilidagi videoning URL havolasini kiriting',
            uzLabel: "O'zbek tilidagi videoning URL havolasini kiriting",
          },
          offerVideo: 'Tovar haqida video',
          uzLink: "O'zbek tilidagi videoning URL havolasi",
          ruLink: 'Rus tilidagi videoning URL havolasi',
        },
      },
    },
    tabs: {
      inModeration: 'Moderatsiyada',
      online: 'Onlayn',
      offline: 'Oflayn',
    },
  },
};
