export default {
  shared: {
    hooks: {
      useSearchParams: {
        name: 'По имени',
        passport: 'По паспорту',
        phone: 'По телефону',
        pinfl: 'По ПИНФЛ',
        clientId: 'По ID клиента',
        applicationId: 'По ID заявки',
      },
    },
  },
};
