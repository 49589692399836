<template>
  <transition-root as="template" :show="modelValue">
    <Dialog class="fixed inset-0 overflow-hidden z-[11]" @close="close">
      <div class="absolute inset-0 overflow-hidden">
        <transition-child
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <dialog-overlay class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </transition-child>

        <div class="fixed inset-y-0 pl-16 md:max-w-full right-0 flex">
          <transition-child
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="h-full divide-y divide-gray-200 flex flex-col  shadow-xl bg-white text-slate-700 dark:bg-slate-700 dark:text-white"
              >
                <div class="flex flex-col flex-1 h-0 overflow-y-auto">
                  <div class="p-6 flex align-center justify-between bg-indigo-600 text-white">
                    <p class="text-lg">
                      {{ title }}
                    </p>
                    <button
                      type="button"
                      class="rounded-md text-black-200 hover:text-black-800 focus:outline-none focus:ring-2 focus:ring-white"
                      @click="close"
                    >
                      <span class="sr-only">{{ t('shared.ui.slideOver.close') }}</span>
                      <x-icon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="flex-1 flex full-h flex-col px-6 py-4">
                    <slot />
                  </div>
                </div>
              </div>
            </div>
          </transition-child>
        </div>
      </div>
    </Dialog>
  </transition-root>
</template>

<script setup>
  import { defineEmits, defineProps } from 'vue';
  import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';
  import { XIcon } from '@heroicons/vue/outline';
  import { useI18n } from 'vue-i18n';

  defineProps({
    title: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: true,
    },
  });

  const emit = defineEmits(['update:modelValue']);
  const { t } = useI18n();

  const close = () => {
    emit('update:modelValue', false);
  };
</script>
