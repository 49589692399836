import { getToken } from '@/services';

import { ROUTE_NAMES } from '@/to-fsd/shared/constants';

const requireAuthToken = ({ next, to }) => {
  const checkAuth = getToken() && getToken() !== '';

  if (!checkAuth) {
    return { name: ROUTE_NAMES.login, query: { from: to.fullPath } };
  }

  return next();
};

export default requireAuthToken;
