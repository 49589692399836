import { createPinia } from 'pinia';
import { useUserStore } from '@/to-fsd/shared/store/user';
import { useNasiyaGlobalStore } from '@/to-fsd/shared/store/nasiya-global';
import { useMainStore } from '@/to-fsd/shared/store/main';

const stores = createPinia();

export default stores;

export const resetStores = () => {
  const user = useUserStore();
  const globalStore = useNasiyaGlobalStore();
  const mainStore = useMainStore();

  user.$reset();
  globalStore.$reset();
  mainStore.$reset();
};
