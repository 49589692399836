<template>
  <at-dropdown :title="title" position="right">
    <at-dropdown-item @click="changeLang('ru')">Русский</at-dropdown-item>
    <at-dropdown-item @click="changeLang('uz')">O'zbek</at-dropdown-item>
  </at-dropdown>
</template>

<script setup>
  import { computed } from 'vue';

  import { useLang } from '@/to-fsd/shared/hooks';

  import { AtDropdown, AtDropdownItem } from '@packages/aliftech-ui';

  const { lang, changeLang } = useLang();

  const title = computed(() => {
    const map = {
      'ru': 'Русский',
      'uz': "O'zbek",
    };

    return map[lang.value];
  });
</script>
