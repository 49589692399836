import { useMutation } from '@tanstack/vue-query';

import { CLIENT_PASSPORT_TYPE } from '../constants';

import { getPassportDataFromOcr } from '../api';
import { CLIENT_FILE_TYPE } from '@/to-fsd/entities/client-file';

const useGetPassportDataFromOcr = () => {
  return useMutation({
    async mutationFn({ uuid, passportType, files }) {
      const formData = new FormData();

      formData.append('uuid', uuid);
      formData.append('passport_type', passportType);

      if (passportType === CLIENT_PASSPORT_TYPE.PASSPORT) {
        formData.append('files[0][type]', CLIENT_FILE_TYPE.PASSPORT);
        formData.append('files[0][file]', files[CLIENT_FILE_TYPE.PASSPORT]);

        if (files[CLIENT_FILE_TYPE.PASSPORT_FACE]) {
          formData.append('files[2][type]', CLIENT_FILE_TYPE.PASSPORT_FACE);
          formData.append('files[2][file]', files[CLIENT_FILE_TYPE.PASSPORT_FACE]);
        }
      }

      if (passportType === CLIENT_PASSPORT_TYPE.ID) {
        formData.append('files[0][type]', CLIENT_FILE_TYPE.ID_PASSPORT_FRONT);
        formData.append('files[0][file]', files[CLIENT_FILE_TYPE.ID_PASSPORT_FRONT]);

        formData.append('files[1][type]', CLIENT_FILE_TYPE.ID_PASSPORT_BACK);
        formData.append('files[1][file]', files[CLIENT_FILE_TYPE.ID_PASSPORT_BACK]);

        if (files[CLIENT_FILE_TYPE.ID_PASSPORT_FACE]) {
          formData.append('files[2][type]', CLIENT_FILE_TYPE.ID_PASSPORT_FACE);
          formData.append('files[2][file]', files[CLIENT_FILE_TYPE.ID_PASSPORT_FACE]);
        }
      }

      const response = await getPassportDataFromOcr(formData);

      return response.data;
    },
  });
};

export default useGetPassportDataFromOcr;
