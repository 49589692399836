import { useQuery } from '@tanstack/vue-query';
import { getProblemCaseStatuses } from '../api';

const useGetProblemCaseStatusesQuery = () => {
  return useQuery({
    queryKey: ['global', 'problem-case-statuses'],
    async queryFn() {
      const response = await getProblemCaseStatuses();

      return response.data;
    },
    retry: 0,
    gcTime: Infinity,
    staleTime: Infinity,
  });
};

export default useGetProblemCaseStatusesQuery;
