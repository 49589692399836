import { useQuery } from '@tanstack/vue-query';
import { getProblemCase } from '../api';
import { api as clientApi } from '@/to-fsd/entities/client';

const useGetProblemCaseQuery = problemCaseId => {
  return useQuery({
    queryKey: ['problem-cases', 'detail', problemCaseId],
    async queryFn({ queryKey }) {
      const problemCaseId = queryKey[2];

      const response = await getProblemCase(problemCaseId);

      const clientId = response.data.client_id;

      const clientResponse = await clientApi.getClient(clientId);

      return {
        ...response.data,
        client: clientResponse.data,
      };
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetProblemCaseQuery;
