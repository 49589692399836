<template>
  <div class="flex flex-col gap-y-2">
    <template v-if="error">
      <api-error :error="error" />
    </template>

    <template v-else>
      <at-table size="md" :head="tableHeaders" :elements="isLoading ? new Array(10).fill(null) : clients">
        <template #header>
          <div class="flex items-center gap-2">
            <at-panel-title>{{ $t('entities.client.table.title') }}</at-panel-title>
            <span v-if="secondaryTitle" class="text-gray-400 font-weight text-lg">
              {{ secondaryTitle }}
            </span>
            <!-- this hack is needed to ensure the table header is the same height with applications table header in home page  -->
            <at-button class="invisible">
              hidden
            </at-button>
          </div>
        </template>

        <template #element="{ element: client }">
          <template v-if="isLoading">
            <clients-table-row is-loading />
          </template>
          <template v-else>
            <clients-table-row :client="client" />
          </template>
        </template>

        <template v-if="showPagination" #footer>
          <div class="flex flex-wrap justify-end">
            <at-pagination
              :model-value="props.page"
              :total="totalPages"
              @update:model-value="$emit('update:page', $event)"
            />
          </div>
        </template>
      </at-table>

      <at-alert v-if="showNoDataAlert" type="info">
        {{ $t('entities.client.table.noClientsAlert') }}
      </at-alert>
    </template>
  </div>
</template>
<script setup>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';

  import { ApiError } from '@/to-fsd/shared/lib';

  import { AtTable, AtPanelTitle, AtPagination, AtAlert, AtButton } from '@packages/aliftech-ui';

  import ClientsTableRow from './ClientsTableRow.vue';

  const props = defineProps({
    secondaryTitle: {
      type: String,
      default: null,
    },
    clients: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    totalPages: {
      type: Number,
      default: null,
    },
    hasNextPage: {
      type: Boolean,
      default: null,
    },
    page: {
      type: Number,
      required: true,
    },
  });

  defineEmits(['update:page']);

  const { t } = useI18n();

  const tableHeaders = computed(() => [
    { title: t('entities.client.table.headers[0]') },
    { title: t('entities.client.table.headers[1]') },
  ]);

  const showPagination = computed(() => {
    if (props.isLoading) {
      return false;
    }

    if (!props.clients.length) {
      return false;
    }

    const isFirstPage = props.page === 1;

    if (isFirstPage && !props.hasNextPage) {
      return false;
    }

    return true;
  });

  const showNoDataAlert = computed(() => {
    return !props.isLoading && props.clients.length === 0;
  });
</script>
