import { defineComponent, h, inject, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n/index';
import { useRoute, useRouter } from 'vue-router';
import { useNasiyaGlobalStore } from '@/to-fsd/shared/store/nasiya-global';
import { storeToRefs } from 'pinia';

import { modulesList } from '@/to-fsd/shared/constants';

export default defineComponent({
  name: 'NasiyaClientScoringCentrifuge',
  setup() {
    const store = useNasiyaGlobalStore();
    const { storeId, rejectReasons } = storeToRefs(store);

    const channel = `merchant-pre-scoring-${storeId.value}`;
    const subscription = ref(null);
    const { t } = useI18n();
    const $toast = inject('$toast');
    const $centrifuge = inject('$centrifuge');
    const router = useRouter();
    const route = useRoute();

    subscription.value = $centrifuge.getSubscription(channel) || $centrifuge.newSubscription(channel);

    subscription.value.subscribe();

    const showNotification = notification => {
      const message = notification.data;
      if (!message) return;
      const title = h('p', { class: ['text-red-600'] }, [t('clients.scoring.reject.title')]);

      const id = message?.client_id?.toString?.();

      const nameElement = h('strong', message?.client_name);

      const rejectReason = rejectReasons.value?.find(r => Number(r.id) === Number(message?.reject_reason_id));
      const rejectElement = h('p', { class: [''] }, rejectReason?.body);

      const link = h(
        'div',
        {
          class: ['text-blue-600', 'cursor-pointer'],
          onClick: () => redirect(id),
        },
        [t('clients.scoring.reject.goToClient')]
      );

      const subTitle = h('div', [nameElement, rejectElement, link]);

      $toast.show({ id, title: title, subTitle: subTitle, type: 'error', duration: null });

      const redirect = id => {
        const moduleName = modulesList.module_azo.name.toLowerCase();
        const path = `/${moduleName}/clients/${id}?tab=requirements`;
        if (route.fullPath !== path) router.push({ path });
        $toast.remove(id);
      };
    };

    onMounted(() => {
      subscription.value.on('publication', showNotification);
    });

    onUnmounted(() => {
      subscription.value.off('publication', showNotification);
    });
  },
  render: () => null,
});
