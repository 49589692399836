<template>
  <div>
    <div>
      <at-input
        ref="referenceRef"
        v-click-outside="handleClickOutside"
        :model-value="modelValue"
        :label="label"
        type="text"
        :placeholder="placeholder"
        :disabled="disabled"
        :error="error"
        :icon-before="iconBefore"
        :icon-after="iconAfter"
        :success="success"
        @update:model-value="handleUpdateModelValue"
        @update:on-focus="showOptions = true"
      />
    </div>

    <Teleport to="body">
      <div
        v-if="showOptions && (loading || hasAtLeastOneOption)"
        ref="floatingRef"
        class="absolute top-0 left-0 origin-top bg-white dark:bg-gray-700 w-80 rounded-md shadow-lg ring-1 ring-black dark:ring-gray-700 ring-opacity-5 focus:outline-none z-50"
        :style="{
          width: `${referenceRef?.$el?.clientWidth}px`,
          transform: `translate(${x}px, ${y}px)`,
          zIndex: Z_INDEX_VALUES.high,
        }"
      >
        <div v-if="loading" class="text-center pb-1">
          <at-loading />
        </div>

        <ul v-else class="divide-y divide-gray-200 dark:divide-gray-600 max-h-80 overflow-y-auto">
          <li
            v-for="option in options"
            :key="option"
            class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:text-white dark:hover:bg-gray-800 text-left"
            @click="handleOptionSelected(option)"
          >
            {{ option }}
          </li>
        </ul>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
  import { defineProps, defineEmits, ref, computed, onMounted, watch, onBeforeUnmount } from 'vue';
  import { Z_INDEX_VALUES } from './zIndexValues';
  import { clickOutside } from './clickOutside';
  import InputElements from './InputElements';
  import { useFloating } from './useFloating';

  import { AtInput, AtLoading } from '@packages/aliftech-ui';

  const props = defineProps({
    ...InputElements.props,
    modelValue: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: { type: String, default: '' },
    iconBefore: { type: [String, Object], default: null },
    iconAfter: { type: [String, Object], default: null },
    loading: { type: Boolean, default: false },
  });

  const emit = defineEmits(['update:model-value', 'select']);

  const hasAtLeastOneOption = computed(() => props.options.length >= 1);

  const showOptions = ref(false);

  const referenceRef = ref(null);
  const floatingRef = ref(null);

  const { x, y, updatePosition, destroyAutoFloating } = useFloating(referenceRef, floatingRef, {
    autoFloating: true,
    placement: 'bottom',
    alignment: 'center',
    offset: [0, 5],
    getReferenceDomElement: element => element.value?.$el,
  });

  onMounted(() => {
    updatePosition();
  });

  watch([() => floatingRef.value, () => referenceRef.value?.$el], () => {
    updatePosition();
  });

  onBeforeUnmount(() => {
    destroyAutoFloating();
  });

  const vClickOutside = clickOutside['click-outside'];

  const handleOptionSelected = option => {
    emit('select', option);
    emit('update:model-value', option);
  };

  const handleUpdateModelValue = $event => {
    if ($event) {
      showOptions.value = true;
    }
    emit('update:model-value', $event);
  };

  const handleClickOutside = () => {
    if (showOptions.value) {
      showOptions.value = false;
    }
  };
</script>
