import { useMutation } from '@tanstack/vue-query';
import { clientConfirmAgreement } from '../api';

const useClientConfirmAgreementMutation = () => {
  return useMutation({
    async mutationFn({ clientId, otp }) {
      const response = await clientConfirmAgreement({
        client_id: clientId,
        otp,
      });

      return response.data;
    },
  });
};

export default useClientConfirmAgreementMutation;
