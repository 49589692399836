export default {
  features: {
    alifshopOrderCreateDelivery: {
      link: {
        title: 'Yetkazmalar',
        button: 'Alif yetkazmasi',
      },
      form: {
        ENTER_ENTER: 'Eshikdan eshikgacha',
        ENTER_POINT: 'Eshikdan tortib olish punktigacha',
        deliveryType: 'Yetkazib berish turi',
        save: 'Saqlash',
        cancel: 'Bekor qilish',
        sender: {
          title: 'Yuboruvchi',
          info: 'Omborxonalar manzillari mavjud emas',
          name: {
            label: 'FISh',
          },
          phone: {
            label: 'Telefon raqami',
          },
        },
        receiver: {
          title: 'Qabul qiluvchi',
          region: {
            label: 'Viloyat',
            placeholder: 'Qiymatni tanlang',
          },
          district: {
            label: 'Tuman',
            placeholder: 'Qiymatni tanlang',
          },
          city: {
            label: 'Aholi yashash punkti',
            placeholder: 'Qiymatni tanlang',
          },
          street: {
            label: "Ko'cha",
          },
          name: {
            label: 'FISh',
          },
          phone: {
            label: 'Telefon raqami',
          },
        },
        parcel: {
          title: 'Yetkazma',
          datePickup: {
            label: 'Yetkazmani olib ketish sanasi',
          },
          singleWeight: {
            label: 'Bitta yetkazma vazni (kg)',
          },
          singleVolume: {
            label: 'Bitta yetkazma hajmi (metr kub)',
          },
        },
      },
    },
  },
};
