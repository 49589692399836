import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { uploadFile } from '../api';
import { useRefetchOnSuccess } from '@/to-fsd/shared/lib/vue-query';

const useUploadClientFileMutation = () => {
  const queryClient = useQueryClient();

  const { onSuccess, isRefetchingAfterMutation } = useRefetchOnSuccess(async (newClientFile, { clientId }) => {
    await Promise.all([
      queryClient.refetchQueries({ queryKey: ['clients', 'detail', clientId, 'requirements'], exact: true }),
    ]);

    queryClient.setQueryData(['client-files', 'list', { clientId }], oldClientFiles => {
      if (!oldClientFiles) {
        return oldClientFiles;
      }

      return [...oldClientFiles, newClientFile];
    });
  });

  const mutation = useMutation({
    async mutationFn({ clientId, type, file }) {
      const formData = new FormData();

      formData.append('type', type);
      formData.append('file', file);

      const response = await uploadFile(clientId, formData);

      return response.data;
    },
    onSuccess,
  });

  return {
    ...mutation,
    isRefetchingAfterMutation,
  };
};

export default useUploadClientFileMutation;
