export default {
  features: {
    employeeTogglePermissions: {
      panel: {
        title: 'Настройка доступов',
      },
      merchant_acquiring: 'Управление эквайринг',
      merchant_admin: 'Админ панель',
      merchant_alifnasiya: 'Управление алифнасия',
      merchant_alifshop: 'Управление алифшоп',
      merchant_applications: 'Управление заявками',
      merchant_deliveries: 'Управление доставкой',
      merchant_manager: 'Управление сотрудниками',
      merchant_moderation_goods: 'Управление модерацией товаров',
      merchant_scoring_card: 'Управление скоринг карт',
      merchant_upload_goods: 'Управление товарами',
      merchant_moderation_alifshop: 'Управление офферами алифшоп',
    },
  },
};
