export default {
  features: {
    cancelAcquiringBmlTransaction: {
      button: 'Отменить транзакцию',
      bmlTitle: 'Отмена транзакции',
      bmlRefundLabel: 'Сумма отмены',
      close: 'Закрыть',
      isFullRefund: 'Полная отмена',
      placeholder: 'Причина',
      submit: 'Подтвердить отмену',
      cancel: 'Отменить',
      status: 'Транзакция отменена!',
      timeout: 'Нельзя вернуть через',
      reset: 'Вернуть',
      success: 'Успешно',
      pending: 'На рассмотрении',
      error: 'Ошибка',
    },
  },
};
