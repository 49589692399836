<template>
  <template v-if="error">
    <at-panel :title="$t('entities.rejectCase.rejectCasesTable.title')">
      <api-error :error="error" />
    </at-panel>
  </template>

  <template v-else>
    <at-table :head="tableHeaders" :elements="isLoading ? new Array(5).fill(true) : rejectCases">
      <template #header>
        <div class="flex flex-wrap justify-between items-center">
          <h2 class="text-lg leading-6 font-medium text-gray-900 dark:text-white">
            {{ $t('entities.rejectCase.rejectCasesTable.title') }}
          </h2>

          <slot name="actions" />
        </div>
      </template>

      <template #element="{ element: rejectCase }">
        <template v-if="isLoading">
          <at-table-item><at-skeleton /></at-table-item>
          <at-table-item><at-skeleton /></at-table-item>
          <at-table-item><at-skeleton /></at-table-item>
          <at-table-item><at-skeleton /></at-table-item>
        </template>

        <template v-else>
          <at-table-item :to="{ name: ROUTE_NAMES.rejectsShow, params: { rejectCaseId: rejectCase.id } }">
            <div v-if="!rejectCase.is_full">
              <div v-for="item in rejectCase.items" :key="item.id">
                <p>{{ item.name }} - {{ formatPrice(item.price) }}</p>
              </div>
            </div>
            <div v-else>
              <p>{{ $t('entities.rejectCase.rejectCasesTable.allItems') }}</p>
            </div>
          </at-table-item>
          <at-table-item :to="{ name: ROUTE_NAMES.rejectsShow, params: { rejectCaseId: rejectCase.id } }">
            <base-status :id="rejectCase.status" :statuses="rejectStatuses" />
          </at-table-item>
          <at-table-item :to="{ name: ROUTE_NAMES.rejectsShow, params: { rejectCaseId: rejectCase.id } }">
            <template v-if="rejectCase.is_full">
              <at-badge color="green" rounded>{{ $t('entities.rejectCase.rejectCasesTable.fullReject') }}</at-badge>
            </template>
            <template v-else>
              <at-badge color="red" rounded>{{ $t('entities.rejectCase.rejectCasesTable.partialReject') }}</at-badge>
            </template>
          </at-table-item>
          <at-table-item :to="{ name: ROUTE_NAMES.rejectsShow, params: { rejectCaseId: rejectCase.id } }">
            <p>{{ formatDate(parseISO(rejectCase.date)) }}</p>
          </at-table-item>
        </template>
      </template>

      <template #footer>
        <at-alert v-if="!hasRejectCases" type="info">{{
          $t('entities.rejectCase.rejectCasesTable.noRejects')
        }}</at-alert>
      </template>
    </at-table>
  </template>
</template>

<script setup>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n/index';

  import { parseISO } from 'date-fns';

  import { formatPrice, formatDate } from '@/to-fsd/shared/lib';

  import { useNasiyaGlobalStore } from '@/to-fsd/shared/store/nasiya-global';

  import { AtAlert, AtBadge, AtTable, AtTableItem, AtSkeleton, AtPanel } from '@packages/aliftech-ui';

  import { ApiError } from '@/to-fsd/shared/lib';
  import { useService } from '@/to-fsd/shared/hooks';

  import { ROUTE_NAMES } from '@/modules/nasiya/common/constants/routeNames';

  const props = defineProps({
    rejectCases: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: null,
    },
  });

  const { t } = useI18n();

  const hasRejectCases = computed(() => {
    return props.rejectCases.length >= 1;
  });

  const tableHeaders = computed(() => [
    { title: t('entities.rejectCase.rejectCasesTable.headers[0]') },
    { title: t('entities.rejectCase.rejectCasesTable.headers[1]') },
    { title: t('entities.rejectCase.rejectCasesTable.headers[2]') },
    { title: t('entities.rejectCase.rejectCasesTable.headers[3]') },
  ]);

  const store = useNasiyaGlobalStore();

  const { execute: getCreditsApp } = useService((...args) => store.fetchCreditsApp(...args));

  getCreditsApp();

  const rejectStatuses = computed(() => store.creditsApp?.reject_case_statuses);
</script>
