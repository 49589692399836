export default {
  clients: {
    index: {
      title: 'Клиенты',
      headers: {
        client: 'Клиент',
        clientNumber: 'Номер клиента',
        status: 'Статус',
        date: 'Дата',
      },
      addClient: 'Добавить клиента',
      searchParams: {
        name: 'По имени',
        passport: 'По паспорту',
        phone: 'По телефону',
        pinfl: 'По ПИНФЛ',
        clientId: 'По ID клиента',
        applicationId: 'По ID заявки',
      },
      useSearchToFindAzoClientAlert: 'Воспользуйтесь поиском, чтобы найти аъзо клиентов',
    },
    applications: {
      headers: {
        sum: 'Сумма',
        createdDate: 'Дата создания',
        applicationType: 'Тип заявки',
      },
      inStore: {
        title: 'Новая заявка (магазин)',
        body: 'Если клиент находится в магазине',
        type: 'Из магазина',
      },
      outOfStore: {
        title: 'Новая заявка (дистанционно)',
        body: 'Если клиент не в магазине',
        type: 'Дистанционно',
      },
      more: 'Подробнее',
      create: 'Создать',
      full: 'Полная заявка',
      partial: 'Частичная заявка',
      confirmCreateApplicationModal: {
        title: 'Новая заявка?',
        body:
          'Хотим предупредить вас, вы отправили {applicationsCreated} заявки на данного клиента. Осталось {attemptsLeft} попытки!',
        btns: {
          cancel: 'Отмена',
          confirm: 'Продолжить',
        },
      },
    },
    cbAgreement: {
      title: 'Согласие Субъекта - физического лица на предоставление кредитного отчета о себе',
      header:
        'В соответствии со ст. 19 Закона РУз «Об обмене кредитной информацией» и ст. ' +
        '18 Закона РУз «О персональных данных» Заёмщик (потенциальный заёмщик):',
      body:
        'Я, __________ проживающий (ая) по адресу __________ ' +
        'владелец паспорта № __________ выданного __________' +
        ' от __________, настоящим, выражаю свое согласие на получение кредитного отчета о себе' +
        ' кредитной организацией OOO "ALIF MOLIYA", а также обработки моих персональных данных для ' +
        'принятия мер по заключению и выполнению договора, стороной которого я являюсь.',
      from: 'От имени и по поручению Субъекта',
      note: '(Ф.И.О. по паспорту доверенного лица в случае действия по доверенности)',
      footer: 'действующий на основании доверенности № __________ от __________',
      clientAgreement: 'Согласие клиента __________',
      receivedEmployee: 'Согласие получил:',
      confirmation: 'Подтверждение согласия',
      confirm: 'Подтвердить',
    },
    show: {
      limit: {
        request: 'Запрос на получение лимита',
      },
      tabs: {
        requirements: 'Требования',
        applications: 'Заявки',
      },
      notifications: {
        statusChanged: 'Статус клиента изменен',
      },
    },
    requirements: {
      requestReview: {
        desc: 'Отправить клиента на идентификацию',
        btn: 'Отправить',
      },
      updatePassportSuggestion: {
        desc: 'Клиент поменял паспорт? Вы можете обновить данные тут',
        btns: {
          update: 'Обновить',
        },
      },
      reject: {
        title: 'Причина отказа',
      },
      fix: {
        title: 'Причины исправления',
      },
      passport: {
        title: 'Паспорт',
        description: 'Загрузите фото паспорта',
        info: 'Загрузите фото паспорта/id карты, чтобы заполнить все паспортные данные автоматически',
        titles: {
          passport: 'Паспорт',
          id: 'ID карта',
        },
        label: {
          passport: 'Фото паспорта',
          idBack: 'Фото задней стороны ID карты',
          idFront: 'Фото передней стороны ID карты',
        },
        toggle: 'Заполнить данные вручную',
        uploadPassport: 'Загрузите паспорт',
        ocr: 'Считать с фото',
        save: 'Сохранить',
        type: {
          label: 'Тип документа, удостоверяющего личность',
          placeholder: 'Выберите тип документа',
          options: {
            passport: 'Паспорт',
            id: 'ID',
          },
        },
        pinfl: {
          label: 'ПИНФЛ',
          helper: 'Последние 14 символов в номере паспорта',
          placeholder: 'Введите пинфл',
        },
        passport_id: {
          label: 'Серия и номер',
          placeholder: 'Введите серию и номер паспорта',
        },
        surname: {
          label: 'Фамилия',
          placeholder: 'Введите фамилию',
        },
        patronymic: {
          label: 'Отчество',
          placeholder: 'Введите отчество',
        },
        name: {
          label: 'Имя',
          placeholder: 'Введите имя',
        },
        gender: {
          label: 'Пол',
          placeholder: 'Выберите пол',
          options: {
            male: 'Мужской',
            female: 'Женский',
          },
        },
        birth_date: {
          label: 'Дата рождения',
          placeholder: 'год-месяц-день',
        },
        address: {
          label: 'Адрес прописки',
          placeholder: 'Введите адрес',
        },
        citizenship: {
          label: 'Гражданство',
          placeholder: 'Введите гражданство',
        },
        birthplace: {
          label: 'Место рождения',
          placeholder: 'Введите место рождения',
        },
        issuedBy: {
          label: 'Кем выдан',
          placeholder: 'Кем выдан паспорт',
        },
        issuedAt: {
          label: 'Дата выдачи',
          placeholder: 'год-месяц-день',
        },
        expiresAt: {
          label: 'Годен до',
          placeholder: 'год-месяц-день',
        },
        region: {
          label: 'Регион',
          placeholder: 'Выберите регион',
        },
      },
      card: {
        title: 'Карта',
        description: 'Добавьте карту',
        active: 'Активная',
        notActive: 'Не активная',
        checked: 'Проверено {date}',
        notChecked: 'Не проверено',
        number: {
          label: 'Номер',
        },
        cardValidityPeriod: {
          label: 'Срок карты',
        },
        successSendCode: 'Код успешно отправлен на номер',
        smsNotificationsAreTiedToAnotherPhone: 'SMS информирование привязано к другому номеру {phone}',
        informClientCardIsTiedToAnotherPhone:
          'Проинформируйте покупателя, что карта привязана к другому номеру телефона',
        confirmationCode: {
          label: 'Код подтверждение',
        },
        wrongCardName: 'Имя на основной карте должно совпадать с именем клиента',
        smsIsSentToNumber: 'На номер {phone} отправлено SMS сообщение.',
        clientFIOShouldMatchCardOwnerFIO: 'Ф.И.О на основной карте должно совпадать с Ф.И.О клиента',
        whySmsNotComing: 'Почему смс не приходит?',
        popup: {
          title: 'Что нужно сделать, чтобы проверить?',
          steps: [
            'Сверить номер телефона, к которому привязана карта, и убедиться, что номер совпадает с тем номером, на который ожидают SMS',
            'Проверить баланс телефона.',
            'Проверить память на телефоне (если памяти нет, то очистить).',
            'Попробовать выключить и снова включить телефон.',
            'Проверить, не добавлен ли Alif Mobi в спам.',
            'Если ничего из вышеперечисленного не помогло, то обратиться в {technicalSupport}',
          ],
          technicalSupport: 'тех. поддержку.',
        },
        orderCall: 'Заказать звонок',
        confirm: 'Подтвердить',
        add: 'Добавить еще карту',
        cancel: 'Отменить',
        save: 'Сохранить',
        noAttachedCardsAlert: 'У клиента пока нет привязанных карт',
      },
      additionalDocs: {
        title: 'Дополнительные фото',
        description: 'Загрузите дополнительные фото',
        fillPassport: 'Пожалуйста прежде чем загрузить дополнительные фото, сперва заполните паспортные данные',
        maxFileSize: 'Максимальный размер файла не более 5 мб',
        commonFiles: 'Прочие файлы',
        delete: 'Удалить',
        addCommonFile: 'Добавить',
        passportWithFace: 'Паспорт на фоне лица',
        idWithFace: 'ID карта на фоне лица',
        common: 'Файл',
        noCommonFiles: 'Нет прочих файлов',
        taxiDailyIncome: 'Доход водителя за день',
        taxiWeeklyIncome: 'Доход водителя за неделю',
        taxiMonthlyIncome: 'Доход водителя за месяц',
        militaryCertificate: 'Удостоверение',
        moneyTransferRecipient: 'Получатель денежных переводов',
      },
      questionnaire: {
        title: 'Личная информация',
        description: 'Анкета',
        activityArea: {
          label: 'Сфера деятельности',
          placeholder: 'Выберите значение',
        },
        profession: {
          label: 'Должность',
          placeholder: 'Введите должность',
        },
        preferredLanguage: {
          title: 'Предпочитаемый язык',
          uzbek: 'Узбекский',
          russian: 'Русский',
        },
        car: {
          title: 'Автомобиль',
          do: 'Есть',
          dont: 'Нет',
        },
        save: 'Сохранить',
      },
      phones: {
        title: 'Контакты',
        description: 'Доп. контакты',
        main: 'Основной номер',
        number: {
          label: 'Номер телефона',
        },
        relationship: {
          label: 'Кем является? Брат, сестра ...',
        },
        owner: {
          label: 'Имя владельца номера',
        },
        add: 'Добавить контакт',
        save: 'Сохранить',
        added: 'Контактные данные успешно сохранены',
        singleAdded: 'Контакт успешно сохранен',
      },
      addresses: {
        title: 'Адреса',
        residence: 'Прописка',
        address: {
          label: 'Адрес',
        },
        landmark: {
          label: 'Ориентир',
        },
        cancel: 'Отменить',
        save: 'Сохранить',
        add: 'Добавить адрес',
        added: 'Адрес успешно сохранен',
      },
    },
    create: {
      title: 'Добавление нового клиента',
      passport_id: {
        label: 'Серия и номер',
        placeholder: 'AA1234567',
      },
      phone: {
        label: 'Номер телефона',
        placeholder: '999999999',
      },
      birth_date: {
        label: 'Дата рождения',
        placeholder: 'дд-мм-гггг',
      },
      code: {
        label: 'Код',
        placeholder: 'Введите полученный код',
      },
      sendSms: 'Отправить смс',
      status: {
        title: 'Новый клиент',
        body: ['- Введите номер телефона для проверки существования', '- Номер должен состоять из 9 цифр'],
      },
      clientExists: {
        title: 'Клиент с данным номером существует',
        instructions: 'Пожалуйста обратитесь к нашим сотрудникам за помощью',
        btn: 'Перейти',
      },
      passportExists: {
        title: 'Теперь смена номера клиента стала безопаснее',
        instructions: 'Чтобы сменить номер телефона клиента в приложении "alif merchant", следуйте этапам ниже:',
        request: 'Пожалуйста, не создавайте повторного клиента с поддельными паспортными данными',
        step1: 'Скачайте приложение через QR-код или в App Store или Play Market',
        step2: 'Добавьте клиента, нажав «+» на главной странице',
        step3: 'Смените номер с помощью MyID',
        close: 'Понятно',
      },
      confirm: 'Подтвердить',
      cancel: 'Отменить',
      clientAlreadyExistsModal: {
        title: 'Клиент с данным номером телефона уже существует',
        body: 'Чтобы продолжить, обновите паспортные данные клиента',
        btns: {
          cancel: 'Отмена',
          updatePassport: 'Изменить паспортные данные',
        },
      },
    },
    scoring: {
      reject: {
        title: 'Отказано',
        header: 'Клиенту {name} отказано',
        goToClient: 'Перейти к клиенту',
        reasons: {
          has_mib_delays: 'Есть долги в мибе',
          zero_incomes: 'Нет приходов на карту последние 3 месяца',
          current_delays: 'Есть активные долги',
          low_incomes: 'Поступления на карту менее 400 тысяч сум',
          credit_status: 'Есть проблемная рассрочка в alifshop',
          more_than_monthly_payment: 'Сумма ежемесячного погашения долга большая',
        },
      },
    },
    updatePassport: {
      btns: {
        save: 'Сохранить',
        cancel: 'Отмена',
      },
      description: 'Загрузите фото паспорта',
      titles: {
        passport: 'Паспорт',
        id: 'ID карта',
      },
      label: {
        passport: 'Фото паспорта',
        idBack: 'Фото задней стороны ID карты',
        idFront: 'Фото передней стороны ID карты',
        passportPhotoFace: 'Фото паспорта на фоне лица',
        idPassportPhotoFace: 'Фото айди на фоне лица',
      },
      uploadPassport: 'Загрузите паспорт',
      save: 'Сохранить',
      type: {
        label: 'Тип документа, удостоверяющего личность',
        options: {
          passport: 'Паспорт',
          id: 'ID',
        },
      },
    },
    reminder: {
      title: 'Теперь регистрация клиента стала быстрее и безопаснее!',
      subTitle: 'Чтобы регистрировать клиента в приложении Аlif Merchant, следуйте этапам ниже:',
      steps: {
        first: 'Скачать приложение отсканировав QR-код',
        second: "Добавить клиента нажав '+' на главной странице",
        third: 'Регистрируйте клиента через MyID',
      },
      footer: {
        description: 'Пожалуйста, не создавайте повторных клиентов с поддельными паспортными данными',
        button: 'Понятно',
      },
    },
  },
};
