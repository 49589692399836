<template>
  <div class="flex items-center gap-1" :class="color">
    <star-icon class="h-6 w-6 shrink-0" />
    <span class="leading-4 font-medium text-sm">
      {{ text }}
    </span>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { StarIcon } from '@heroicons/vue/solid';

  import { capitalizeStr } from '@/to-fsd/shared/lib';

  import { clientGetStatusBody, clientGetStatusKey } from '../lib';

  import { CLIENT_STATUS } from '../constants';

  const props = defineProps({
    client: {
      type: Object,
      required: true,
    },
  });

  const text = computed(() => {
    return capitalizeStr(clientGetStatusBody(props.client));
  });

  const color = computed(() => {
    const map = {
      [CLIENT_STATUS.CREATED]: 'text-blue-500',
      [CLIENT_STATUS.FIXED]: 'text-yellow-500',
      [CLIENT_STATUS.REVIEWED]: 'text-yellow-500',
      [CLIENT_STATUS.IDENTIFIED]: 'text-green-500',
    };

    return map[clientGetStatusKey(props.client)];
  });
</script>
