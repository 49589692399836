import axios from 'axios';
import { checkType } from '@/plugins/aliftech-ui/utils';
import { hasOwnProperty } from '@/plugins/aliftech-ui/utils';
import { deepCopy } from '@/plugins/aliftech-ui/utils';
import { callbackIterableArray } from '@/plugins/aliftech-ui/utils';
import { callbackIterableObject } from '@/plugins/aliftech-ui/utils';
import i18n from '../plugins/i18n';
import router from '../router';
import { useMainStore } from '@/to-fsd/shared/store/main';
import { resetStores } from '@/stores';
import { app as Vue } from '@/main';
import fileReader from '@/utils/fileReader';
import * as api from './api';
import { tokenEncoding } from '@/utils/filters';
import { ROUTE_NAMES } from '@/to-fsd/shared/constants';

/**
 * @typedef {Object} AxiosInstanceObject
 * @property {Object} defaults
 * @property {Object} interceptors
 * @property {Function} getUri
 * @property {Function} request
 * @property {Function} get
 * @property {Function} delete
 * @property {Function} head
 * @property {Function} options
 * @property {Function} post
 * @property {Function} put
 * @property {Function} patch
 * */

/**
 * @typedef {AxiosInstanceObject | Function} AxiosInstance
 * */

let requestPromise = null;

/**
 * Обрабатывает логику обновления токена
 * @param {Error} error - Объект ошибки
 * @returns {null|Promise<AxiosInstance>} - Обновленный экземпляр Axios
 * */
const refreshToken = async error => {
  const request = error.config;
  let response = null;

  try {
    if (requestPromise) {
      response = await requestPromise;
    } else {
      requestPromise = api.refreshToken();
      response = await requestPromise;
    }
  } finally {
    requestPromise = null;
  }

  if (response) {
    const token = response.data?.token_type + ' ' + response.data?.access_token;

    setToken(token);
    Vue.config.globalProperties.$cookies.set('auth', tokenEncoding(token), { expire: '1m' });
    error.config.headers['Authorization'] = token;

    return mainConnect(request);
  }

  return null;
};

/**
 * Actions for handling errors by status code
 */

const authActions = {
  authCleanUp: () => {
    Vue.config.globalProperties.$cookies.delete('auth');
    setToken('');
  },
};

const errorActions = {
  manageUnauthorizedError: () => {
    authActions.authCleanUp();
    return router.push({ name: ROUTE_NAMES.login }).then(resetStores);
  },
  managePasswordExpiredError: async error => {
    authActions.authCleanUp();
    await router.push({ name: ROUTE_NAMES.login });
    Vue.config.globalProperties.$toast.error({
      title: error.response.data.message,
      duration: 7000,
    });
  },
  manageRefreshTokenError: error => refreshToken(error),
  manageNoPermissionError: () => router.replace({ name: ROUTE_NAMES.noPermission }),
};

const errorStatus = {
  401: async error => {
    const isRefresh = error.response.data.code === 'token_expired';
    const isPasswordExpired = error.response.data.code === 'password_expired';
    if (isRefresh) return errorActions.manageRefreshTokenError(error);
    if (isPasswordExpired) return errorActions.managePasswordExpiredError(error);
    return errorActions.manageUnauthorizedError();
  },
  403: errorActions.manageNoPermissionError,
};

/**
 * Парсер ошибок
 * @param {AxiosInstance} connect
 * @return {VoidFunction}
 * */
const setParser = connect => {
  connect.interceptors.response.use(
    res => res,
    async e => {
      if (e.response?.status in errorStatus) {
        const store = useMainStore();
        store.$patch({ error: null });

        const responseWithRefreshedToken = await errorStatus[e.response.status](e);

        if (responseWithRefreshedToken) {
          return Promise.resolve(responseWithRefreshedToken);
        }
        store.$patch({ error: e.response.data });
      }

      if (e?.config?.responseType === 'blob') return Promise.reject(e);
      const $parsed = (error => {
        const e = error?.response?.data ?? 'Неизвестная ошибка';
        const parser = e => {
          let result = [];
          const callback = object => {
            if (checkType(object, 'string')) result.push(object);
            if (checkType(object, 'array')) callbackIterableArray(object, callback);
            if (checkType(object, 'object')) callbackIterableObject(object, callback);
          };
          if (checkType(e, 'array')) callbackIterableArray(e, callback);
          if (checkType(e, 'object')) callbackIterableObject(e, callback);
          result = checkType(e, 'string') ? [e] : result;
          return result.filter(e => e.trim() !== '');
        };
        return checkType(e, 'object') && 'errors' in e ? parser(e.errors) : parser(e);
      })(e);
      return Promise.reject({
        ...e,
        response: { ...e.response, data: { ...(e.response?.data || {}), $parsed } },
      });
    }
  );
};

export const mainConnect = new axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND}`,
});

mainConnect.interceptors.request.use(config => {
  config.headers['locale'] = i18n?.global?.locale || 'uz';
  return config;
});
setParser(mainConnect);

/**
 * Метод преобразования ошибок
 * @return {Object | String}
 * */
export const generateError = function(error) {
  if (hasOwnProperty(error?.response?.data, 'errors') && checkType(error?.response?.data?.errors, 'object')) {
    const result = {};
    const errors = deepCopy(error.response.data.errors);
    for (const key in errors) {
      result[key] = errors[key].join(' ');
    }
    return Promise.reject({ errors: result });
  } else if (hasOwnProperty(error?.response?.data, 'error') && checkType(error.response.data.error, 'object')) {
    const result = { ...error.response.data, ...error.response.data.error };
    return Promise.reject(result);
  } else if (hasOwnProperty(error?.response?.data, 'lang') && checkType(error.response.data.lang, 'object')) {
    const locale = i18n?.global?.locale;
    const message = error.response.data.lang[locale] || error.response.data.lang.ru;
    return Promise.reject({ ...error.response.data, message });
  } else if (
    hasOwnProperty(error?.response?.data, 'message') &&
    checkType(error.response.data.message, 'string') &&
    error.response.data.message !== ''
  ) {
    return Promise.reject({ ...error.response.data });
  }
  return Promise.reject(error?.response?.data?.$parsed?.join(', '));
};

const fileMIMETypes = {
  excel: {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: '.xlsx',
  },
  pdf: {
    type: 'application/pdf',
    extension: '.pdf',
  },
};

/**
 * Method to handle downloaded blob file
 *
 * @param params
 * @param {Blob} params.blob
 * @param {String} params.fileName
 * @param {String} params.fileType
 * @returns {Promise<File>}
 */
export const downloadBlobFile = params => {
  const { blob, fileName, fileType } = params;
  return new Promise(resolve => {
    const type = fileMIMETypes[fileType]?.type;
    const name = `${fileName}${fileMIMETypes[fileType]?.extension}`;
    const fileLink = document.createElement('a');
    fileLink.href = window.URL.createObjectURL(new Blob([blob], { type }));
    fileLink.setAttribute('download', name);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
    resolve();
  });
};

/**
 * Method to handle error blob response
 *
 * @param {Response} error - http response
 * @returns {Promise<Object|String>}
 */
export const handleBlobErrorResponse = async error => {
  const data = error?.response?.data;
  if (data instanceof Blob) {
    const file = (await fileReader(data)) ?? data;
    const fileError = JSON.parse(file);
    const err = { response: { data: fileError } };
    return generateError(err);
  }
  return generateError(error);
};

/**
 * Метод получения пред. установленного токена
 * @return {String}
 * */
export const getToken = function() {
  return mainConnect?.defaults?.headers?.common?.['Authorization'] ?? '';
};

/**
 * Метод установки токена
 * @param {String} token
 * @return {VoidFunction}
 * */
export const setToken = function(token) {
  if (mainConnect?.defaults?.headers?.common) mainConnect.defaults.headers.common['Authorization'] = token;
  else throw new Error('Ошибка во время установки токена');
};

export const alifpayConnect = new axios.create({
  baseURL: `${process.env.VUE_APP_ALIFPAY_API}`,
});
export default mainConnect;
