import { useQuery } from '@tanstack/vue-query';
import { getUpdatedClientUuid } from '../api';
import { NINE_MINUTES_IN_MS } from '../constants';

const useGetUpdatedClientUuid = clientId => {
  return useQuery({
    queryKey: ['client', { clientId }, 'uuid'],
    async queryFn({ queryKey }) {
      const { clientId } = queryKey[1];

      const response = await getUpdatedClientUuid(clientId);

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
    refetchInterval: NINE_MINUTES_IN_MS,
  });
};

export default useGetUpdatedClientUuid;
