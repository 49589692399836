import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { deleteFile } from '../api';
import { useRefetchOnSuccess } from '@/to-fsd/shared/lib/vue-query';

const useDeleteClientFileMutation = () => {
  const queryClient = useQueryClient();

  const { onSuccess, isRefetchingAfterMutation } = useRefetchOnSuccess(async (_, { clientId, fileId }) => {
    await Promise.all([
      queryClient.refetchQueries({ queryKey: ['clients', 'detail', clientId, 'requirements'], exact: true }),
    ]);

    queryClient.setQueryData(['client-files', 'list', { clientId }], oldClientFiles => {
      if (!oldClientFiles) {
        return oldClientFiles;
      }

      return oldClientFiles.filter(clientFile => clientFile.id !== fileId);
    });
  });

  const mutation = useMutation({
    async mutationFn({ clientId, fileId }) {
      const response = await deleteFile(clientId, fileId);

      return response.data;
    },
    onSuccess,
  });

  return {
    ...mutation,
    isRefetchingAfterMutation,
  };
};

export default useDeleteClientFileMutation;
