import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { updateProblemCaseStatus } from '../api';
import { useRefetchOnSuccess } from '@/to-fsd/shared/lib/vue-query';

const useProblemCaseUpdateStatusMutation = () => {
  const queryClient = useQueryClient();

  const { onSuccess, isRefetchingAfterMutation } = useRefetchOnSuccess(async (_, { problemCaseId }) => {
    await Promise.all([
      queryClient.refetchQueries({ queryKey: ['problem-cases', 'detail', problemCaseId], exact: true }),
    ]);
  });

  const mutation = useMutation({
    async mutationFn({ problemCaseId, statusId }) {
      const response = await updateProblemCaseStatus(problemCaseId, { status_id: statusId });

      return response.data;
    },
    onSuccess,
  });

  return {
    ...mutation,
    isRefetchingAfterMutation,
  };
};

export default useProblemCaseUpdateStatusMutation;
