import { WAREHOUSE_ITEM_STATUS } from '@/to-fsd/entities/warehouse-item/constants';

export default {
  entities: {
    warehouseItem: {
      form: {
        name: {
          label: 'Nomi',
        },
        ikpu: {
          label: 'IKPU',
        },
        price: {
          label: 'Narxi',
        },
        sku: {
          label: 'SKU',
        },
        quantity: {
          label: 'Miqdori',
        },
        thirdCategory: {
          label: 'Toifasi',
        },
        gtin: {
          label: 'Shtrixkod',
          search: 'Qidirish',
        },
        searchByGtin: "Shtrixkod bo'yicha qidirish",
      },
      statusText: {
        [WAREHOUSE_ITEM_STATUS.new]: 'Yangi',
        [WAREHOUSE_ITEM_STATUS.not_assigned]: "Bog'lanmagan",
        [WAREHOUSE_ITEM_STATUS.data_filling]: "Ma'lumotlarni to'ldirish",
        [WAREHOUSE_ITEM_STATUS.approved]: 'Tasdiqlandi',
        [WAREHOUSE_ITEM_STATUS.on_moderation]: 'Moderatsiyada',
        [WAREHOUSE_ITEM_STATUS.to_review]: 'Ko`rib chiqilmoqda',
        [WAREHOUSE_ITEM_STATUS.rejected]: 'Rad etildi',
        [WAREHOUSE_ITEM_STATUS.processing]: 'Ko`rib chiqilmoqda',
        [WAREHOUSE_ITEM_STATUS.to_correct]: 'Tuzatishda',
      },
      quantity: 'Omborda',
    },
  },
};
