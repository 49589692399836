export default {
  pages: {
    offerDemandsCreate: {
      header: {
        title: 'Создание заявки на выставление товара',
      },
      quitModal: {
        doYouWantToQuit: 'Вы точно хотите выйти?',
        cancel: 'Отменить',
        quit: 'Выйти',
      },
    },
  },
};
