import { useQuery } from '@tanstack/vue-query';
import { getRejectCase } from '../api';

const useGetRejectCaseQuery = rejectCaseId => {
  return useQuery({
    queryKey: ['reject-cases', 'detail', rejectCaseId],
    async queryFn({ queryKey }) {
      const rejectCaseId = queryKey[2];

      const response = await getRejectCase(rejectCaseId, { relationships: 'items,file' });

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetRejectCaseQuery;
