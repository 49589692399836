<template>
  <div class="block -m-0.5">
    <p v-if="fullName" class="text-sm whitespace-normal font-medium p-0.5">
      {{ fullName }}
    </p>
    <p class="text-xs whitespace-nowrap p-0.5">
      <template v-if="clientHasPhone(client)">
        {{ clientGetFormattedPhone(client) }}
      </template>

      <template v-else>
        -
      </template>
    </p>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  import { clientGetFormattedPhone, getClientFullName, clientHasPhone } from '../lib';

  const props = defineProps({
    client: {
      type: Object,
      required: true,
    },
  });

  const fullName = computed(() => {
    return getClientFullName(props.client);
  });
</script>
