export default {
  widgets: {
    reportsList: {
      headers: ['Файл', 'Период', 'Последняя генерация', 'Действия'],
      successAlert: {
        title: 'Нажмите на кнопку "скачать" чтобы загрузить отчет',
        message: 'Отчет - "{name}" успешно сгенерирован',
      },
      failAlert: {
        message: 'Отчет - "{name}" не удалось сгенерировать',
      },
      reportNameText: {
        merchants_service: 'Товары',
        merchants_rejects: 'Возвраты',
        merchants_problem: 'Проблемные клиенты',
        merchants_invoice: 'Счёт фактура',
        merchants_minus_invoice: 'Минусовая счёт фактура',
        merchants_reconciliation: 'Акт сверки',
        merchants_delivery: 'Доставки',
        delivery_tariffs: 'Тарифы на доставку',
      },
      periodTypes: {
        month: 'Месяц',
        quarter: 'Квартал',
        half_year: 'Полугодие',
        year: 'Год',
      },
      generate: 'Сгенерировать',
      lastGenerated: 'Последняя генерация',
      download: 'Скачать',
      periodOptions: ['День', 'Период'],
    },
  },
};
