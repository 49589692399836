export const ALIFSHOP_ORDER_STATUS = {
  NEW: 'NEW',
  REVIEWING: 'REVIEWING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  DELIVERING: 'DELIVERING',
  DELETED: 'DELETED',
};

export const ALIFSHOP_ORDER_DELIVERY_METHOD = {
  delivery: 'delivery',
  pickup: 'pickup',
};
