import { useQuery } from '@tanstack/vue-query';
import { getUpdatedApplicantUuid } from '../api';
import { NINE_MINUTES_IN_MS } from '../constants';

const useGetUpdatedApplicantUuid = uuid => {
  return useQuery({
    queryKey: ['applicant', uuid],
    async queryFn({ queryKey }) {
      const uuid = queryKey[1];

      const response = await getUpdatedApplicantUuid({ uuid });

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
    refetchInterval: NINE_MINUTES_IN_MS,
  });
};

export default useGetUpdatedApplicantUuid;
