import { parseISO } from 'date-fns';
import { REJECT_CASE_STATUS } from '../constants';
import { formatDate } from '@/to-fsd/shared/lib';

export const isRejectCaseOnPartnerReview = rejectCase => {
  return rejectCase.status === REJECT_CASE_STATUS.ON_PARTNER_REVIEW;
};

export const rejectCaseIsStatusOnPartnerReview = rejectCase => {
  return rejectCase.status === REJECT_CASE_STATUS.ON_PARTNER_REVIEW;
};

export const rejectCaseIsStatusOnWaitingForInvoice = rejectCase => {
  return rejectCase.status === REJECT_CASE_STATUS.WAITING_FOR_INVOICE;
};

export const rejectCaseIsStatusCompleted = rejectCase => {
  return rejectCase.status === REJECT_CASE_STATUS.COMPLETED;
};

export const isRejectCaseHasCancelReason = rejectCase => {
  return Boolean(rejectCase.cancel_reason);
};

export const rejectCaseGetId = rejectCase => {
  return rejectCase.id;
};

export const rejectCaseGetClientName = rejectCase => {
  return rejectCase.client_name;
};

export const rejectCaseGetIsFull = rejectCase => {
  return rejectCase.is_full;
};

export const rejectCaseGetDate = rejectCase => {
  return rejectCase.date;
};

export const rejectCaseGetNote = rejectCase => {
  return rejectCase.note;
};

export const rejectCaseGetFormattedDate = rejectCase => {
  return formatDate(parseISO(rejectCaseGetDate(rejectCase)));
};
