<template>
  <at-alert v-if="hasError" type="danger" dismissible>
    <div class="flex flex-col gap-y-2">
      <span v-if="errorMessage" class="whitespace-normal">{{ errorMessage }}</span>

      <ul v-if="showValidationErrors && isObjectError && error.errors">
        <li v-for="(validationErrorMessage, field) in error.errors" :key="field">
          {{ field }}: {{ validationErrorMessage }}
        </li>
      </ul>
    </div>
  </at-alert>
</template>

<script setup>
  import { computed } from 'vue';
  import { AtAlert } from '@packages/aliftech-ui';

  const props = defineProps({
    error: {
      type: [Object, String],
      default: null,
    },
    showValidationErrors: {
      type: Boolean,
      default: true,
    },
  });

  const isObjectError = computed(() => typeof props.error === 'object' && props.error !== null);

  const errorMessage = computed(() => {
    if (!props.error) return '';
    return typeof props.error === 'string' ? props.error : props.error.message;
  });

  const hasError = computed(() => {
    if (!props.error) return false;
    if (typeof props.error === 'string') return true;
    return props.error.message || (props.showValidationErrors && props.error.errors);
  });
</script>
