export default {
  widgets: {
    alifshopOrders: {
      filters: {
        creationDate: 'Дата создания',
      },
      table: {
        headers: ['ID', 'Клиент', 'Сумма', 'СОЗДАНО', 'Статус'],
      },
    },
  },
};
