export default {
  widgets: {
    alifshopOrders: {
      filters: {
        creationDate: 'Yaratilgan sana',
      },
      table: {
        headers: ['ID', 'Клиент', 'Narxi', 'Sana', 'Holat'],
      },
    },
  },
};
