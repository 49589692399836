export default {
  features: {
    createDelivery: {
      success: 'Доставка успешно создано',
      panelLink: {
        title: 'Доставки',
        link: 'Доставка Алифа',
      },
      senderForm: {
        title: 'Отправитель',
        noWarehouseAddresses: 'Отсутствует адреса складов',
        fio: {
          label: 'ФИО',
        },
        phone: {
          label: 'Номер телефона',
        },
      },
      receiverForm: {
        title: 'Получатель',
        region: {
          label: 'Область',
        },
        district: {
          label: 'Район',
        },
        city: {
          label: 'Населенный пункт',
        },
        street: {
          label: 'Улица',
        },
        fio: {
          label: 'ФИО',
        },
        phone: {
          label: 'Номер телефона',
        },
        schedule: 'Дни работы',
        weekend: 'выходной',
        daysOfTheWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      },
      parcelForm: {
        title: 'Посылка',
        pickup: {
          label: 'Дата забора посылки',
        },
        weight: {
          label: 'Вес одной посылки (кг)',
        },
        volume: {
          label: 'Объем одной посылки (метр куб)',
        },
      },
      cancel: 'Отменить',
      submit: 'Сохранить',
    },
  },
};
