export const DELIVERY_STATUS = {
  NEW: 'NEW',
  PENDING_PICKUP: 'PENDING_PICKUP',
  TRANSFERRED_TO_COURIER: 'TRANSFERRED_TO_COURIER',
  IN_TRANSIT: 'IN_TRANSIT',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  AWAITING_RECEIPT: 'REFUND',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
  RETURN_IN_PROGRESS: 'RETURN_IN_PROGRESS',
  RETURNED: 'RETURNED',
  DELIVERY_PROBLEM: 'DELIVERY_PROBLEM',
};

export const DELIVERY_STATUS_ID = {
  NEW: 1,
  PENDING_PICKUP: 3,
  TRANSFERRED_TO_COURIER: 4,
  IN_TRANSIT: 9,
  OUT_FOR_DELIVERY: 10,
  AWAITING_RECEIPT: 11,
  DELIVERED: 2,
  CANCELLED: 5,
  RETURN_IN_PROGRESS: 6,
  RETURNED: 8,
  DELIVERY_PROBLEM: 7,
};

export const DELIVERY_TYPE_SERVICE = {
  POINT_POINT: 'POINT_POINT',
  POINT_ENTER: 'POINT_ENTER',
  ENTER_ENTER: 'ENTER_ENTER',
  ENTER_POINT: 'ENTER_POINT',
};

export const DELIVERY_TYPE = {
  ALIF_DELIVERY: 'ALIF_DELIVERY',
  DPD_DELIVERY: 'DPD_DELIVERY',
  UZ_POST: 'UZ_POST',
  NESUVEZU: 'NESUVEZU',
  FARGO: 'FARGO',
};
