import { ALIFSHOP_ORDER_DELIVERY_METHOD } from '../constants';

export default {
  entities: {
    alifshopOrder: {
      receiptMethod: {
        title: 'Способ получения',
        pickupDescription: 'Клиент готов приехать за товаром самостоятельно',
        address: 'Адресс',
      },
      deliveryMethod: {
        [ALIFSHOP_ORDER_DELIVERY_METHOD.delivery]: 'Доставка',
        [ALIFSHOP_ORDER_DELIVERY_METHOD.pickup]: 'Самовывоз',
      },
    },
  },
};
