import { useQuery } from '@tanstack/vue-query';
import { getRejectCaseTemplate } from '../api';

const useGetRejectCaseTemplateQuery = applicationId => {
  return useQuery({
    queryKey: ['reject-cases-templates', 'detail', { applicationId }],
    async queryFn({ queryKey }) {
      const { applicationId } = queryKey[2];

      const response = await getRejectCaseTemplate(applicationId);

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
    enabled: false,
  });
};

export default useGetRejectCaseTemplateQuery;
