export default {
  features: {
    cancelAcquiringTransaction: {
      button: 'Tranzaksiyani bekor qilish',
      submit: 'Bekor qilishni tasdiqlang',
      cancel: 'Qaytarish',
      status: 'Tranzaksiya bekor qilindi!',
      timeout: `Keyin qaytarib bo'lmaydi`,
      reset: 'Qaytish',
      success: 'Muvaffaqiyatli',
      pending: 'Kutilmoqda',
      placeholder: 'Sabab',
      errors: {
        notFound: 'Topilmadi',
        unprocessable: "Ushbu to'lovni bekor qilib bo'lmaydi",
        temporalError: 'Vaqtinchalik server xatosi',
        clientError: 'Server xatosi',
        unknown: 'Noma’lum xato',
      },
    },
  },
};
