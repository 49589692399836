export default {
  features: {
    acquiringInvoiceCreate: {
      product: {
        total: 'Сумма',
        sum: 'сум',
      },
      smsInvoice: {
        label: 'Номер телефона клиетна',
      },
      createLink: 'Выставить СМС-счёт',
      successSentMessage: 'Счёт со ссылкой на оплату отправлен клиенту по СМС',
      errors: {
        maxAmount: 'Максимальная сумма - 200 000 000',
        minAmount: 'Минимальная сумма - 1',
      },
      required: 'Это поле обязательно',
      alertSentMessage: 'Количество запросов превышено',
      wrongPhoneFormat: 'Неправильный формат телефона',
    },
  },
};
