export const WAREHOUSE_ITEM_STATUS = {
  not_assigned: 'not_assigned',
  data_filling: 'data_filling',
  processing: 'processing',
  approved: 'approved',
  new: 'new',
  on_moderation: 'on_moderation',
  to_review: 'to_review',
  rejected: 'rejected',
  to_correct: 'to_correct',
};
