import { useQueryClient } from '@tanstack/vue-query';
import { onUnmounted, ref, unref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCentrifuge, useToast } from '@/to-fsd/shared/hooks';

const useSubscribeToClientStatusUpdated = clientId => {
  const toast = useToast();

  const centrifuge = useCentrifuge();

  const { t } = useI18n();

  const subscription = ref(null);
  const subscribed = ref(false);

  const queryClient = useQueryClient();

  const showStatusChangedNotification = () => {
    toast.info({
      title: t('entities.client.clientStatusUpdatedAlert'),
      duration: 5000,
      id: 'client-status-change',
    });
  };

  const clientStatusUpdatedHandler = async () => {
    queryClient.refetchQueries({ queryKey: ['clients', 'detail', unref(clientId)], exact: true });
    queryClient.refetchQueries({ queryKey: ['client-files', 'list', { clientId }], exact: true });
    queryClient.refetchQueries({ queryKey: ['client-cards', 'list', { clientId }], exact: true });
    queryClient.refetchQueries({ queryKey: ['client-phones', 'list', { clientId }], exact: true });
    queryClient.refetchQueries({ queryKey: ['clients', 'detail', clientId, 'addresses'], exact: true });

    showStatusChangedNotification();
  };

  const subscribeToClientStatusUpdated = () => {
    subscription.value =
      centrifuge.getSubscription(`merchant-client-${unref(clientId)}`) ||
      centrifuge.newSubscription(`merchant-client-${unref(clientId)}`);

    subscription.value.subscribe();

    subscription.value.on('publication', async ctx => {
      if (ctx.data.event !== 'client_status_updated') return;
      await clientStatusUpdatedHandler();
    });
  };

  const unsubscribeFromClientStatusUpdated = () => {
    subscription.value?.off('publication', clientStatusUpdatedHandler);
  };

  watch(
    clientId,
    () => {
      if (subscribed.value) {
        unsubscribeFromClientStatusUpdated();
      }
      subscribeToClientStatusUpdated();
    },
    {
      immediate: true,
    }
  );

  onUnmounted(() => {
    unsubscribeFromClientStatusUpdated();
  });
};

export default useSubscribeToClientStatusUpdated;
