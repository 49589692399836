<template>
  <at-badge rounded :color="badgeColor">
    <span class="whitespace-nowrap">
      {{ badgeText }}
    </span>
  </at-badge>
</template>

<script setup>
  import { computed, reactive } from 'vue';

  import { AtBadge } from '@packages/aliftech-ui';

  import { capitalizeStr } from '@/to-fsd/shared/lib';

  import { REJECT_CASE_STATUS } from '@/to-fsd/entities/reject-case';
  import { useGetCreditsAppQuery } from '@/to-fsd/shared/queries';

  const props = defineProps({
    rejectCase: {
      type: Object,
      required: true,
    },
  });

  const queries = reactive({
    creditsApp: useGetCreditsAppQuery(),
  });

  const badgeColor = computed(() => {
    const map = {
      [REJECT_CASE_STATUS.NEW]: 'blue',
      [REJECT_CASE_STATUS.CANCELLED]: 'pink',
      [REJECT_CASE_STATUS.COMPLETED]: 'green',
      [REJECT_CASE_STATUS.ON_PARTNER_REVIEW]: 'yellow',
      [REJECT_CASE_STATUS.PARTNER_APPROVED]: 'default',
      [REJECT_CASE_STATUS.PARTNER_DENIED]: 'red',
      [REJECT_CASE_STATUS.WAITING_FOR_INVOICE]: 'default',
    };

    return map[props.rejectCase.status];
  });

  const badgeText = computed(() => {
    return capitalizeStr(queries.creditsApp.data.reject_case_statuses[props.rejectCase.status].body);
  });
</script>
