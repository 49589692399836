export default {
  warehouse: {
    index: {
      title: 'Склад',
      headers: {
        items: 'Товары',
        status: 'Статус',
        sku: 'SKU',
        ikpu: 'ИКПУ',
        price: 'Цена (сум)',
        price_brutto: 'Цена с НДС',
        quantity: 'На складе',
      },
      add: {
        manually: 'Добавить вручную',
        excel: 'Загрузить excel',
      },
      download: {
        button: 'Скачать',
        excelForMerchant: 'Скачать товары всех магазинов (excel)',
        excelForStore: 'Скачать товары текущего магазина (excel)',
      },
      update: 'Изменить товары',
      noOffer: 'Товар не привязан',
      makeVisible: 'Выставить на продажу',
    },
    filters: {
      isVisible: 'Видимость',
      exhibited: 'Выставленный',
      notExhibited: 'Не выставлен',
      hasOffer: 'Привязка к шаблону',
      noTemplate: 'Нет Шаблона',
      template: 'Есть Шаблон',
      hasProduct: 'Имеется',
      noProduct: 'Не имеется',
      showBy: 'Показывать по',
      upTo_5: 'До 5шт',
      upTo_10: 'До 10шт',
      upTo_15: 'До 15шт',
      upTo_20: 'До 20шт',
      upTo_50: 'До 50шт',
      moreThan_50: 'Больше 50шт',
      productQuantity: 'Наличие товара',
      expensiveFirst: 'Сначала дорогие',
      cheapFirst: 'Сначала дешёвые',
    },
    add: {
      title: 'Добавление товара на склад',
      cancel: 'Отменить',
      maxLengthError: 'Поле ИКПУ должно состоять из 17-ти цифр',
      save: 'Сохранить',
    },
    edit: {
      item: {
        title: 'Данные о товаре',
        cancel: 'Отменить',
        save: 'Сохранить',
      },
    },
    show: {
      title: 'Данные о товаре',
      generateBarcode: 'Сгенерировать штрих-код',
      price_info: 'Вы не являетесь плательщиком НДС. Поэтому при выставлении на сайт добавляется сумма НДС.',
      more: 'Подробнее',
      generateBarcodeModal: {
        title: 'Штрих-код',
        print: 'Распечатать',
        download: 'Скачать',
      },
      item: {
        title: 'Информация',
        labels: {
          ikpu: 'ИКПУ',
          name: 'Название',
          price: 'Цена',
          price_brutto: 'Цена с НДС',
          sku: 'SKU',
          third_category: 'Категория',
          quantity: 'Количество',
        },
        placeholders: {
          name: 'Введите название товара',
          price: 'Цена',
          sku: 'SKU',
          third_category: 'Категория',
          search: 'Поиск',
        },
        totalQuantity: 'Общее кол-во товаров в BILLZ: {quantity}',
      },
      offer: {
        title: 'Выберите другой товар',
        subtitle: 'Выберите товар из каталога, чтобы отправить его в alifshop.uz.',
        instructions:
          'Для этого напишите название товара и выберите его из списка. Если в списке нет нужного товара напишите в наш телеграм {telegram}.',
        labels: {
          name: 'Название продукта',
        },
        placeholders: {
          search: 'Поиск',
        },
        selectItem: 'Привязать товар',
        detachItem: 'Отвязать товар',
        pasteToAlifshop: 'Выставить товар на alifshop.uz',
        createOrderToAdToAlifshop: 'Создайте заявку для выставления этого товара на сайте alifshop.uz',
        toAlifshop: 'Выставить в alif shop',
      },
      noData: 'Ничего не найдено',
      delete: 'Удалить',
      makeVisible: 'Выставить на продажу',
      success: {
        hidden: 'Товар успешно скрыт из продажы',
        visible: 'Товар успешно выстовлен на продажу',
      },
      confirmDeleteItemModal: {
        title: 'Удалить',
        message: 'Вы действительно хотите удалить этот товар?',
        cancel: 'Отмена',
        confirm: 'Удалить',
      },
    },
    hideItems: 'Скрыть товары',
    selectedItems: 'Выбранные товары',
    offerDemand: {
      title: 'Создание заявки на выставление товара',
      subTitle: 'Заполните все данные чтобы добавить товар',
      back: 'Назад',
      quantityAndPrice: 'Количество и цена',
      quantityInWarehouse: 'Количество на складе',
      priceForAnItem: 'Цена за 1 шт.',
      goToOfflineItems: 'Перейти на офлайн товар',
      goToOnlineItems: 'Перейти на онлайн товар',
      goToStock: 'Перейти на склад',
      editItem: 'Редактировать товар',
      inEditTimeItemWillBeHidden: 'При редактировании, товар будет скрыт на сайте',
      cancel: 'Отмена',
      add: 'Добавить',
      empty: 'Пусто',
      change: 'Изменить',
      quit: 'Выйти',
      doYouWantToQuit: 'Вы точно хотите выйти?',
      addMore: 'Добавить еще',
      required: 'Это поле являеется обязательным',
      times: 'шт.',
      createdDate: 'ДАТА СОЗДАНИЯ',
      inSite: 'На сайте',
      name: 'НАЗВАНИЕ',
      steps: {
        category: 'Категория',
        brandAndModel: 'Бренд и модель',
        imagesAndVideo: 'Фото и Видео',
        characteristics: 'Характеристики',
        description: 'Описание',
        sendToReview: 'Отправить на рассмотрение',
        moderatorComments: 'Комментарий от модератора',
      },
      category: {
        title: 'Категория',
        select: 'Выбор',
        search: 'Поиск',
        selectCategory: 'Выберите категорию',
        secondCategory: 'Подкатегория',
        thirdCategory: '3-я категория',
        searchCategory: 'Поиск категории...',
        continue: 'Продолжить',
      },
      brands: {
        title: 'Введите бренд товара, модель, серия и цвет',
        brandLabel: 'Бренд',
        modelLabel: 'Модель',
        seriesLabel: 'Серия',
        colorLabel: 'Цвет',
        anotherBrand: 'Другое',
      },
      descriptions: {
        infoText: 'Подробное описание товара значительно увеличивает конверсию',
        title: 'Описание товара',
        validation: 'При добавлении описания необходимо указать его на обоих языках: русском и узбекском',
        enterDescription: 'Введите описание',
      },
      attributes: {
        translation: 'Перевод',
        addMoreValue: 'Добавить еще',
        addMoreAttribute: 'Добавить доп. характеристику',
        ruLang: 'Русский',
        uzLang: 'Узбекский',
        valueInRu: 'Значение на русском',
        valueInUz: 'Значение на узбекском',
        attributePlaceholderRu: 'Емкость аккумулятора ...',
        attributePlaceholderUz: 'Batareya quvvati...',
        attributeNameRu: 'Название атрибута (ru)',
        attributeNameUz: 'Название атрибута (uz)',
        attributeAdding: 'Добавление атрибута',
      },
      imagesAndVideo: {
        title: 'Фотографии товара',
        offerImage: 'Фото товара',
        mainImage: 'Основное',
        additionalImages: 'Дополнительные',
        descriptionTitle: 'Нажмите на загруженное фото, чтобы сделать его основным',
        selectFile: 'Выберите файл',
        rules: {
          first: 'Минимум 2 картинки',
          second: 'Минимум разрешение 512х512 пикс',
          third: 'Макс 1 мб.',
        },
        video: {
          add: 'Добавить видео',
          adding: 'Добавление видео',
          productConverse: 'Поднимите конверсию товара!',
          addYoutubeVideo: 'Добавьте видео-ролики из YouTube о товаре',
          modal: {
            cancel: 'Отменить',
            add: 'Добавить',
            ruLabel: 'Вставьте URL-ссылку от видео на русском языке',
            uzLabel: 'Вставьте URL-ссылку от видео на узбекском языке',
          },
          offerVideo: 'Видео о товаре',
          uzLink: 'URL-ссылка от видео на узбекском',
          ruLink: 'URL-ссылка от видео на русском ',
        },
      },
    },
    tabs: {
      inModeration: 'На модерации',
      online: 'Онлайн',
      offline: 'Офлайн',
    },
  },
};
