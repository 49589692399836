import { WAREHOUSE_ITEM_STATUS } from '../constants';

export default {
  entities: {
    warehouseItem: {
      form: {
        name: {
          label: 'Название',
        },
        ikpu: {
          label: 'ИКПУ',
        },
        price: {
          label: 'Цена',
        },
        sku: {
          label: 'SKU',
        },
        quantity: {
          label: 'Количество',
        },
        thirdCategory: {
          label: 'Категория',
        },
        gtin: {
          label: 'Штрих код',
          search: 'Найти',
        },
        searchByGtin: 'Поиск по штрих коду',
      },
      statusText: {
        [WAREHOUSE_ITEM_STATUS.new]: 'Новый',
        [WAREHOUSE_ITEM_STATUS.not_assigned]: 'Не привязан',
        [WAREHOUSE_ITEM_STATUS.data_filling]: 'Заполнение данных',
        [WAREHOUSE_ITEM_STATUS.approved]: 'Одобрено',
        [WAREHOUSE_ITEM_STATUS.on_moderation]: 'На модерации',
        [WAREHOUSE_ITEM_STATUS.to_review]: 'Рассматривается',
        [WAREHOUSE_ITEM_STATUS.rejected]: 'Отказано',
        [WAREHOUSE_ITEM_STATUS.processing]: 'В процессе',
        [WAREHOUSE_ITEM_STATUS.to_correct]: 'На исправлении',
      },
      quantity: 'На складе',
    },
  },
};
