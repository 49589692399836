import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import { ROUTE_NAMES as ADMIN_ROUTES } from '../../common/constants/routeNames';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';

// todo refactor module name
const moduleName = 'module_admin';

export default [
  {
    path: '/admin/employees',
    name: ADMIN_ROUTES.employeesList,
    component: async () => {
      const { EmployeesListPage } = await import('@/to-fsd/pages/employees-list');

      return EmployeesListPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Сотрудники'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_admin']),
      ],
    },
  },
  {
    path: '/admin/employees/:employeeId',
    name: ADMIN_ROUTES.employeesShow,
    component: async () => {
      const { AdminEmployeeShowPage } = await import('@/to-fsd/pages/admin-employee-show');

      return AdminEmployeeShowPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Сотрудники'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_admin']),
      ],
    },
  },
];
