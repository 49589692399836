import { ref } from 'vue';

const useRefetchOnSuccess = callback => {
  const isRefetchingAfterMutation = ref(false);

  return {
    async onSuccess(...args) {
      try {
        isRefetchingAfterMutation.value = true;
        await callback(...args);
      } finally {
        isRefetchingAfterMutation.value = false;
      }
    },
    isRefetchingAfterMutation,
  };
};

export default useRefetchOnSuccess;
