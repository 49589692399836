import { useMutation } from '@tanstack/vue-query';
import { logout } from '../api';

const useLogoutMutation = () => {
  return useMutation({
    async mutationFn() {
      await logout();
    },
  });
};

export default useLogoutMutation;
