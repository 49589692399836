import { OFFER_DEMAND_STATUS } from '../constants';

export default {
  entities: {
    offerDemand: {
      statusText: {
        [OFFER_DEMAND_STATUS.new]: 'Yangi',
        [OFFER_DEMAND_STATUS.processing]: 'Ko`rib chiqilmoqda',
        [OFFER_DEMAND_STATUS.to_correct]: 'Tuzatishda',
        [OFFER_DEMAND_STATUS.to_moderation]: 'Moderatsiyada',
        [OFFER_DEMAND_STATUS.approved]: 'Tasdiqlandi',
        [OFFER_DEMAND_STATUS.rejected]: 'Rad etidi',
      },
      modelSeries: {
        title: 'Tovarning brendi, modeli va rangini tanlang',
        brandLabel: 'Brend',
        modelLabel: 'Model',
        seriesLabel: 'Seriya',
        colorLabel: 'Rang',
        anotherBrand: 'Boshqa',
        empty: "Bo'sh",
      },
      characteristics: {
        valueInRu: "Ruscha ma'nosi",
        valueInUz: "O'zbekcha ma'nosi",
        empty: "Bo'sh",
      },
      videos: {
        offerVideo: 'Tovar haqida video',
        uzLink: "O'zbek tilidagi videoning URL havolasi",
        ruLink: 'Rus tilidagi videoning URL havolasi',
        empty: "Bo'sh",
      },
      descriptions: {
        title: 'Mahsulot tavsifi',
        enterDescription: 'Tavsifni kiriting',
        ru: 'Rus tilida',
        uz: "O'zbek tilida",
      },
      images: {
        offerImage: 'Tovar fotosurati',
        mainImage: 'Asosiy',
        additionalImages: "Qo'shimcha",
      },
      empty: "Bo'sh",
    },
  },
};
