export default {
  entities: {
    store: {
      select: {
        labels: {
          store: 'Магазин',
        },
      },
      titles: {
        choose: 'Выберите значение',
        all: 'Все магазины',
      },
    },
  },
};
