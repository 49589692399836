import { computed, unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { searchClients, getLimit } from '../api';

const useSearchClientsQuery = ({ phone, birthDate }) => {
  const isPhoneValid = computed(() => unref(phone).length === 12);
  const isBirthDateValid = computed(() => Boolean(unref(birthDate)));

  return useQuery({
    queryKey: ['clients', 'list', { phone, birthDate }],
    async queryFn({ queryKey }) {
      const { phone, birthDate } = queryKey[2];

      const response = await searchClients({ phone, birth_date: birthDate });

      const clientLimit = await getLimit(response.data.id);

      return {
        ...response.data,
        client_limit: clientLimit.data,
      };
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
    enabled: computed(() => isPhoneValid.value && isBirthDateValid.value),
  });
};

export default useSearchClientsQuery;
