<template>
  <at-badge rounded :color="badgeColor">
    <span class="whitespace-nowrap">{{ badgeText }}</span>
  </at-badge>
</template>

<script setup>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';

  import { AtBadge } from '@packages/aliftech-ui';

  import { CLIENT_STATUS } from '../constants';

  import { clientGetStatusKey } from '../lib';

  const props = defineProps({
    client: {
      type: Object,
      required: true,
    },
  });

  const { t } = useI18n();

  const badgeText = computed(() => {
    return t(`entities.client.statusText.${clientGetStatusKey(props.client)}`);
  });

  const badgeColor = computed(() => {
    const statusColorMap = {
      [CLIENT_STATUS.CREATED]: 'blue',
      [CLIENT_STATUS.IDENTIFIED]: 'green',
      [CLIENT_STATUS.REVIEWED]: 'purple',
      [CLIENT_STATUS.FIXED]: 'yellow',
    };

    return statusColorMap[props.client.client_status_key];
  });
</script>
