export default {
  entities: {
    alifshopOffer: {
      modelSeries: {
        title: 'Tovarning brendi, modeli va rangini tanlang',
        brandLabel: 'Brend',
        modelLabel: 'Model',
        seriesLabel: 'Seriya',
        colorLabel: 'Rang',
        anotherBrand: 'Boshqa',
        empty: "Bo'sh",
      },
      videos: {
        offerVideo: 'Tovar haqida video',
        uzLink: "O'zbek tilidagi videoning URL havolasi",
        ruLink: 'Rus tilidagi videoning URL havolasi',
        empty: "Bo'sh",
      },
      descriptions: {
        title: 'Mahsulot tavsifi',
        enterDescription: 'Tavsifni kiriting',
        ru: 'Rus tilida',
        uz: "O'zbek tilida",
      },
      images: {
        offerImage: 'Tovar fotosurati',
        mainImage: 'Asosiy',
        additionalImages: "Qo'shimcha",
      },
      empty: "Bo'sh",
    },
  },
};
