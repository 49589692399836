import { useQuery } from '@tanstack/vue-query';
import { getDeliveriesApp } from '@/to-fsd/shared/api';

const useGetCreditsAppQuery = () => {
  return useQuery({
    queryKey: ['deliveries-app'],
    async queryFn() {
      const response = await getDeliveriesApp();

      return response.data;
    },
    gcTime: Infinity,
    staleTime: Infinity,
  });
};

export default useGetCreditsAppQuery;
