<template>
  <at-panel v-if="isLoading">
    <template #header>
      <div class="w-1/2">
        <at-skeleton type="row" />
      </div>
    </template>
    <div class="-m-2">
      <div class="p-2">
        <at-skeleton type="row" />
      </div>
      <div class="p-2">
        <at-skeleton type="row" />
      </div>
    </div>
  </at-panel>

  <at-table v-else :head="tableHeaders" :elements="rejectCase.items">
    <template #header>
      <div class="flex flex-wrap items-center justify-between">
        <at-panel-title>{{ $t('entities.rejectCase.table.title') }}</at-panel-title>
      </div>
    </template>
    <template #element="{ element: rejectCaseItem }">
      <at-table-item>
        <p class="whitespace-normal font-medium text-sm">{{ rejectCaseItem.name }}</p>
      </at-table-item>
      <at-table-item>
        <p class="text-sm whitespace-nowrap">{{ formatPrice(rejectCaseItem.price) }}</p>
      </at-table-item>
    </template>
  </at-table>
</template>

<script setup>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n/index';

  import { AtPanelTitle, AtTable, AtTableItem, AtPanel, AtSkeleton } from '@packages/aliftech-ui';

  import { formatPrice } from '@/to-fsd/shared/lib';

  const { t } = useI18n();

  defineProps({
    rejectCase: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  });

  const tableHeaders = computed(() => [
    { title: t('entities.rejectCase.table.headers[0]') },
    { title: t('entities.rejectCase.table.headers[1]') },
  ]);
</script>
