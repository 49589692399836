export default {
  shared: {
    hooks: {
      useSearchParams: {
        name: "Ism bo'yicha",
        passport: "Pasport bo'yicha",
        phone: "Telefon bo'yicha",
        pinfl: "PINFL bo'yicha",
        clientId: "Mijoz ID bo'yicha",
        applicationId: "Ariza ID bo'yicha",
      },
    },
  },
};
