export default {
  widgets: {
    acquiringBalancesList: {
      errors: {
        noData: "Ma'lumot yo'q",
      },
      buttons: {
        reset: 'Filtrlarni tozalash',
        filter: 'Filtr',
        search: 'Qidirmoq',
        next: 'Keyingi',
        prev: 'Orqaga',
        copy: 'Kochirish',
      },
      pagination: {
        select: 'Korsatish:',
      },
      headers: {
        ref: 'Id',
        paymentVariant: 'To`lov yo`li',
        store: "Do'kon nomi",
        sum: 'Jami',
        date: 'Sana va vaqt',
        status: 'Holati',
      },
      status: {
        all: 'Hammasi',
      },
      slideOver: {
        header: 'Tranzaksiya tafsilotlari',
        spic: 'Mahsulotning MXIK',
        updated_at: 'Yangilanish sanasi',
        cancelled_at: 'Bekor qilish sanasi',
        label: 'Marklash Kodi',
        subtitle: 'Mahsulot nomi',
        phoneTitle: 'Xaridorning telefon raqami',
        holderTitle: `Xaridorning to'liq ismi`,
        monthlyPayment: `Takroriy to'lovlarning oylik miqdori`,
        splitMonthes: `Takroriy to'lovlarning oyi`,
        fiscalReceipt: 'Kvitansiya',
        fiscalReceiptTitle: 'Fiskal kvitansiyalar',
        close: 'Yopish',
        month: `Oylik to'lovlar`,
        revertedBy: 'Bekor qilish tashabbuskori',
        revertedByAlif: 'Alif xodimi',
        ext_id: 'Tashqi ID',
      },
      onlinePurchase: 'Onlayn kassa',
      placeholders: {
        phone: 'Telefon raqami',
        spic: 'ИКПУ kodi',
        name: 'Ism va Familiya',
        label: 'Mahsulot kodirovkasi',
        total: 'Jami',
        paymentType: `To'lov turi`,
        store: `Barcha do'konlar`,
        created_from: 'Sanadan',
        created_to: 'Sanagacha',
        id: `ID bo'yicha  qidirish`,
        ext_id: `Tashqi ID bo'yicha qidirish`,
      },
    },
  },
};
