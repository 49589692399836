export default {
  features: {
    alifshopOrderSetMarking: {
      button: 'Заполнить маркировку',
      modal: {
        title: 'Укажите маркировку для следующих товаров',
      },
    },
  },
};
