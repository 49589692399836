export default {
  pages: {
    offerDemandsCreate: {
      header: {
        title: "Tovar qo'shish uchun ariza yaratish",
      },
      quitModal: {
        doYouWantToQuit: 'Chiqishni hohlaysizmi?',
        cancel: 'Bekor qilish',
        quit: 'Chiqish',
      },
    },
  },
};
