export default {
  features: {
    alifshopOrderComplete: {
      title: 'Оформить заказ',
      btn: 'Оформить',
      moneyFrozen: 'Сумма заморожена на несколько дней.',
      smsIsNeeded: 'Для снятия денег требуеться СМС-подтреждение',
      itemMarkingNeed: 'Для снятия средств необходимо подтвердить маркировку товара',
      otp: {
        label: 'Смс код',
        placeholder: 'Введите код',
      },
      sent: 'На номер клиента ({phone}) был отправлен СМС-код. Введите этот код чтобы оформить покупку',
      cancel: 'Отменить',
    },
  },
};
