export default {
  pages: {
    offerDemandsEdit: {
      quitModal: {
        doYouWantToQuit: 'Chiqishni hohlaysizmi?',
        cancel: 'Bekor qilish',
        quit: 'Chiqish',
      },
    },
  },
};
