export default {
  index: {
    title: `To'lovlar ro'yxati`,
    issueInvoice: 'Chekni taqdim qilish',
    dowloadCsv: `Excel ga o'tkazish`,
    onlinePurchase: 'Onlayn kassa',
    headers: {
      ref: 'Id',
      paymentVariant: 'To`lov yo`li',
      holder: 'Ism va Familiya',
      sum: 'Jami',
      date: 'Sana va vaqt',
      updated_at: `O'zgartirilgan sanasi`,
      status: `Holati`,
      holderePhone: 'Telefon raqam',
      spic: 'Mahsulotlning MXIK',
      label: 'Mahsulot nomi',
      store: `Do'kon nomi`,
    },
    buttons: {
      reset: 'Filtrlarni tozalash',
      filter: 'Filtr',
      search: 'Qidirmoq',
      next: 'Keyingi',
      prev: 'Orqaga',
      copy: 'Kochirish',
    },
    placeholders: {
      phone: 'Telefon raqami',
      spic: 'ИКПУ kodi',
      name: 'Ism va Familiya',
      label: 'Mahsulot kodirovkasi',
      total: 'Jami',
      paymentType: `To'lov turi`,
      store: `Barcha do'konlar`,
      created_from: 'Sanadan',
      created_to: 'Sanagacha',
      id: `ID bo'yicha  qidirish`,
      ext_id: `Tashqi ID bo'yicha qidirish`,
    },
    slideOver: {
      header: 'Tranzaksiya tafsilotlari',
      spic: 'Mahsulotning MXIK',
      updated_at: 'Yangilanish sanasi',
      cancelled_at: 'Bekor qilish sanasi',
      label: 'Marklash Kodi',
      subtitle: 'Mahsulot nomi',
      phoneTitle: 'Xaridorning telefon raqami',
      holderTitle: `Xaridorning to'liq ismi`,
      monthlyPayment: `Takroriy to'lovlarning oylik miqdori`,
      splitMonthes: `Takroriy to'lovlarning oyi`,
      fiscalReceipt: 'Kvitansiya',
      fiscalReceiptTitle: 'Fiskal kvitansiyalar',
      close: 'Yopish',
      month: `Oylik to'lovlar`,
      revertedBy: 'Bekor qilish tashabbuskori',
      revertedByAlif: 'Alif xodimi',
      ext_id: 'Tashqi ID',
    },
    pagination: {
      select: 'Korsatish:',
    },
    status: {
      all: 'Hammasi',
    },
  },
  createInvoice: {
    title: 'Hisob-faktura yaratish',
    aboutProducts: `Mahsulotlar haqida ma'lumot`,
    product: {
      name: 'Mahsulot nomi',
      category: 'Mahsulot kategoriya',
      productByIKPU: 'MXIK mahsulotlari',
      ikpu: 'MXIK',
      code: 'Belgi',
      total: 'Jami summa',
      sum: `so'm`,
      split: 'Muddat',
      splitType: `Qo'l bilan kiriting`,
      month: 'oy',
      instantPay: 'Darhol to`lov',
    },
    placeholders: {
      spic: 'IKPU tomonidan nom tanlang',
      category: 'Kategoriyani tanlang',
      name: 'Sarlavhani kiriting',
    },
    errors: {
      maxAmount: 'Maksimal miqdor - 200 000 000',
      minAmount: 'Minimal miqdor - 1',
      notOne: `1 oy davomida o'tkazib bo'lmaydi`,
    },
    searchLoading: 'Yuklanmoqda...',
    firstTimeSearch: `Qidirish uchun mahsulot ismini kiriting`,
    searchEmptyResult: 'Hech narsa topilmadi',
    monthlyPayment: `Oylik to'lov miqdori:`,
    payment: `To'lov miqdori:`,
    link: 'Havola',
    wrongPhoneFormat: `Telefon formati noto'g'ri`,
    successSentMessage: `Mijozga SMS orqali toʻlovga havola yuborildi`,
    alertSentMessage: `So'rovlar soni oshib ketdi`,
    createLink: `To'lov uchun SMS jo'natish`,
    clearForm: 'Tozalash',
    optional: 'Ixtiyoriy',
    required: 'Bu maydon majburiy',
    verified: 'Tasdiqlangan',
    check: 'Tekshiring',
    isNotChecked: 'Markirovka tekshirilmadi',
    smsInvoice: {
      title: 'Havola',
      submit: 'Telefon raqami',
      label: 'Mijozning telefon raqami',
    },
  },
  excel: {
    id: 'ID',
    paymentType: `To'lov turi`,
    type: `Operatsiya turi`,
    amount: 'Narx',
    amountNew: "So'm",
    date: 'Sana va vaqt',
    status: 'Holat',
    spic: 'Mahsulotlning MXIK',
    label: 'Mahsulot nomi',
    store: `Do'kon`,
    phone: 'Xaridorning raqami',
    created_at: 'Sana va vaqt',
    updated_at: 'Yangilanish sanasi',
    fee: 'Qabul qiluvchi komissiyasi',
    feeNew: 'Komissiyasi',
    created_from: 'Dan',
    created_to: 'Gacha',
    created: 'Hisobot yaratilgan sana',
    excelLength: 'Tranzaktsiyalar soni',
    period: 'Davr',
    filename: 'Hisobotlar',
  },
  statuses: {
    REFUND: 'Qaytarilgan',
    PARTIAL_REFUND: 'Qisman qaytarilgan',
    PAYMENT: "To'lov",
  },
  paymentType: {
    INSTALLMENT: 'Nasiya',
    CARD: 'Karta',
  },
  errors: {
    dateTooEarly: "Xato: Hisobot sanasi 2025 yil 1 yanvardan oldin bo'lmasligi kerak",
  },
  cancelTransaction: {
    button: 'Tranzaksiyani bekor qilish',
    bmlTitle: 'Tranzaksiyani bekor qilish',
    bmlRefundLabel: 'Bekor qilish summasi',
    close: 'Yopish',
    fullRefund: "To'liq bekor qilish",
    submit: 'Bekor qilishni tasdiqlang',
    cancel: 'Qaytarish',
    status: 'Tranzaksiya bekor qilindi!',
    timeout: `Keyin qaytarib bo'lmaydi`,
    reset: 'Qaytish',
    success: 'Muvaffaqiyatli',
    pending: 'Kutilmoqda',
    error: 'Xatolik yuz berdi',
  },
};
