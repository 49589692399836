export default {
  features: {
    offerDemandCreate: {
      steps: {
        list: ['Категория', 'Бренд и модель', 'Фото и Видео', 'Характеристики', 'Описание'],
        tabs: ['На русском', 'На узбекском'],
        sendToReview: 'Отправить на рассмотрение',
      },
      stepCategory: {
        tabs: ['Выбор', 'Поиск'],
        fields: {
          firstCategory: {
            label: 'Выберите категорию',
            placeholder: 'Категория',
          },
          secondCategory: {
            placeholder: 'Подкатегория',
          },
          thirdCategory: {
            placeholder: '3-я категория',
          },
          searchCategory: {
            placeholder: 'Поиск категории...',
          },
        },
        continue: 'Продолжить',
      },
      stepSpecifications: {
        title: 'Введите бренд товара, модель, серия и цвет',
        fields: {
          brand: {
            label: 'Бренд',
            placeholder: 'Бренд',
          },
          model: {
            label: 'Модель',
            placeholder: 'Модель',
          },
          series: {
            label: 'Серия',
            placeholder: 'Серия',
          },
          color: {
            label: 'Цвет',
          },
        },
        continue: 'Продолжить',
        required: 'Это поле являеется обязательным',
        anotherBrand: 'Другое',
      },
      stepImagesAndVideo: {
        title: 'Фотографии товара',
        descriptionTitle: 'Нажмите на загруженное фото, чтобы сделать его основным',
        rules: ['Минимум 2 картинки', 'Минимум разрешение 512х512 пикс', 'Макс 1 мб.'],
        selectFile: 'Выберите файл',
        productConverse: 'Поднимите конверсию товара!',
        addYoutubeVideo: 'Добавьте видео-ролики из YouTube о товаре',
        continue: 'Продолжить',
        required: 'Это поле являеется обязательным',
      },
      openVideoButton: 'Добавить видео',
      createVideoModal: {
        title: 'Добавление видео',
        ruLabel: 'Вставьте URL-ссылку от видео на русском языке',
        uzLabel: 'Вставьте URL-ссылку от видео на узбекском языке',
        add: 'Добавить',
        cancel: 'Отменить',
      },
      stepDescriptions: {
        infoText: 'Подробное описание товара значительно увеличивает конверсию',
        validation: 'При добавлении описания необходимо указать его на обоих языках: русском и узбекском',
        title: 'Описание товара',
        save: 'Сохранить',
        enterDescription: 'Введите описание',
      },
      stepCharacteristics: {
        valueInRu: 'Значение на русском',
        valueInUz: 'Значение на узбекском',
        addMoreValue: 'Добавить еще',
        addMoreAttribute: 'Добавить доп. характеристику',
        continue: 'Продолжить',
        attributePlaceholderRu: 'Емкость аккумулятора ...',
        attributeNameRu: 'Название атрибута (ru)',
        attributePlaceholderUz: 'Batareya quvvati...',
        attributeNameUz: 'Название атрибута (uz)',
        cancel: 'Отменить',
        add: 'Добавить видео',
        required: 'Это поле являеется обязательным',
        anotherBrand: 'Другое',
        attributeAdding: 'Добавление атрибута',
      },
    },
  },
};
