import { useQuery } from '@tanstack/vue-query';
import { clientGetQuestionnaire } from '../api';

const useGetClientQuestionnaireQuery = clientId => {
  return useQuery({
    queryKey: ['clients', 'detail', clientId, 'questionnaire'],
    async queryFn({ queryKey }) {
      const clientId = queryKey[2];

      const response = await clientGetQuestionnaire(clientId);

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetClientQuestionnaireQuery;
