import { AlifshopLayout } from '@/to-fsd/layouts/alifshop';
import { ROUTE_NAMES } from '@/modules/alifshop/common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';

const routes = {
  path: '/alifshop/',
  component: AlifshopLayout,
  redirect: '/alifshop/orders',
  children: [
    {
      path: '/alifshop/orders',
      name: ROUTE_NAMES.ordersList,
      component: async () => {
        const { AlifshopOrdersPage } = await import('@/to-fsd/pages/alifshop-orders');

        return AlifshopOrdersPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Заказы'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_alifshop'),
          requirePermissions(['merchant_alifshop']),
        ],
      },
    },
    {
      path: '/alifshop/orders/:alifshopOrderId/to-fsd',
      name: ROUTE_NAMES.ordersShow,
      component: async () => {
        const { AlifshopOrdersShowPage } = await import('@/to-fsd/pages/alifshop-orders-show');

        return AlifshopOrdersShowPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Заказы'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_alifshop'),
          requirePermissions(['merchant_alifshop']),
        ],
      },
      props: true,
    },
    {
      path: '/alifshop/orders/:alifshopOrderId/deliveries/create',
      name: ROUTE_NAMES.ordersDeliveryCreate,
      component: async () => {
        const { AlifshopOrderCreateDeliveryPage } = await import('@/to-fsd/pages/alifshop-order-create-delivery');

        return AlifshopOrderCreateDeliveryPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Создание доставки'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_alifshop'),
          requirePermissions(['merchant_alifshop']),
        ],
      },
      props: true,
    },
  ],
};

export default router => {
  router.addRoute(routes);
};
