import { CASHBACK_TYPE } from '../constants';

export default {
  entities: {
    cashback: {
      typeText: {
        [CASHBACK_TYPE.CHARGE]: 'Одобрено',
        [CASHBACK_TYPE.TO_PAY]: 'Готово к начислению',
        [CASHBACK_TYPE.NOT_APPROVED]: 'Не одобрено',
        [CASHBACK_TYPE.REJECT]: 'Списание за возврат',
        [CASHBACK_TYPE.TOTAL]: 'Начислено за все время',
      },
      table: {
        headers: ['№', 'СУММА ЗАЯВКИ', 'БОНУС', 'ДАТА ОФОРМЛЕНИЯ', 'ДАТА НАЧИСЛЕНИЯ', 'СТАТУС'],
      },
      typeSelect: {
        all: 'Все статусы',
      },
    },
  },
};
