<template>
  <at-badge v-if="queries.statusesQuery.isSuccess" rounded :color="badgeColor">
    <span class="whitespace-nowrap">{{ badgeText }}</span>
  </at-badge>
</template>

<script setup>
  import { computed, reactive } from 'vue';
  import { useI18n } from 'vue-i18n';

  import { AtBadge } from '@packages/aliftech-ui';

  import { useGetProblemCaseStatusesQuery } from '@/to-fsd/entities/problem-case';

  import { problemCaseGetStatusId } from '@/to-fsd/entities/problem-case';

  const props = defineProps({
    problemCase: {
      type: Object,
      required: true,
    },
  });

  const { locale } = useI18n();

  const queries = reactive({
    statusesQuery: useGetProblemCaseStatusesQuery(),
  });

  const badgeText = computed(() => {
    // TODO: refactor
    return queries.statusesQuery.data.find(status => status.id === problemCaseGetStatusId(props.problemCase)).lang[
      locale.value
    ];
  });

  const badgeColor = computed(() => {
    const statusIdColorMap = {
      1: 'blue',
      2: 'yellow',
      3: 'green',
      4: 'default',
    };

    return statusIdColorMap[problemCaseGetStatusId(props.problemCase)];
  });
</script>
