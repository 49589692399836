<template>
  <svg
    v-if="theme === 'dark'"
    class="w-full d-block"
    viewBox="0 0 446 398"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse opacity="0.1" cx="75.2309" cy="298.039" rx="45.4786" ry="12.7069" fill="#252D38" />
    <ellipse opacity="0.1" cx="240.994" cy="337.007" rx="183.19" ry="60.993" fill="#252D38" />
    <path
      d="M123.41 216.461C123.24 213.411 101.734 210.955 75.2116 210.955C48.6895 210.955 27.2677 213.411 27.0127 216.461C27.0127 216.461 27.0127 216.461 27.0127 216.546C27.0977 219.595 48.6895 222.137 75.2116 222.137C101.734 222.137 123.325 219.595 123.41 216.461C123.41 216.546 123.41 216.461 123.41 216.461ZM75.2116 221.205C52.8548 221.205 34.6633 219.087 34.5783 216.546C34.5783 216.546 34.5783 216.546 34.5783 216.461C34.7483 213.835 52.8548 211.802 75.2116 211.802C97.5684 211.802 115.675 213.92 115.845 216.461V216.546C115.76 219.087 97.6534 221.205 75.2116 221.205Z"
      fill="#E8D4C1"
    />
    <path
      d="M75.2116 221.205C97.5684 221.205 115.76 219.087 115.845 216.546V216.461C115.675 213.835 97.5684 211.802 75.2116 211.802C52.8549 211.802 34.7484 213.92 34.5784 216.461C34.5784 216.461 34.5784 216.461 34.5784 216.546C34.6634 219.087 52.8549 221.205 75.2116 221.205Z"
      fill="#BA9C7E"
    />
    <path
      d="M77.5067 221.713C75.8915 197.655 72.6614 173.766 67.901 150.131C63.1406 126.581 56.85 103.37 49.1144 80.5821C44.694 67.7058 39.8487 55.0835 34.5783 42.5461C34.3232 42.1225 33.8983 41.8684 33.4732 41.6989C33.0482 41.6142 32.5381 41.6144 32.113 41.8685C31.688 42.1227 31.433 42.5461 31.263 42.9696C31.178 43.3932 31.178 43.9015 31.4331 44.3251C40.7838 66.3504 48.6044 88.8839 55.0649 111.926C61.4404 134.798 66.2858 158.009 69.6861 181.475C71.5562 194.774 73.0014 208.159 73.9364 221.628C74.0214 224.085 77.6767 224.085 77.5067 221.713Z"
      fill="#059669"
    />
    <path
      d="M89.9177 226.288C88.6426 188.675 90.1728 151.063 94.6782 113.62C95.9533 103.2 97.3983 92.7807 99.1835 82.361C99.5235 80.0738 96.0382 79.0573 95.6982 81.4293C89.4927 118.703 86.2625 156.4 85.9224 194.182C85.8374 204.855 85.9224 215.529 86.3475 226.203C86.3475 228.66 90.0027 228.66 89.9177 226.288Z"
      fill="#059669"
    />
    <path
      d="M68.666 229.676C63.2256 199.519 51.1547 170.886 33.4733 145.811C28.5429 138.78 23.1025 132.087 17.322 125.734C15.7069 124.04 13.1567 126.581 14.7718 128.36C35.0884 150.724 50.2196 177.324 59.1453 206.126C61.6105 214.174 63.6506 222.391 65.1807 230.693C65.6058 232.98 69.176 232.048 68.666 229.676Z"
      fill="#059669"
    />
    <path
      d="M36.1935 197.062L27.3527 192.826L19.7021 189.268C15.1968 187.151 11.8816 185.372 11.7966 183.847C11.7116 182.407 14.9418 180.204 20.8073 179.103C21.5723 179.018 22.3373 178.849 23.1024 178.764C23.8675 178.679 24.7175 178.679 25.5676 178.595C27.4378 178.51 29.3079 178.679 31.0931 178.849C35.3434 179.442 39.4238 180.628 43.2491 182.491C47.0744 184.355 50.4746 186.896 53.5349 189.861C54.81 191.217 56 192.572 57.1051 194.097C57.6151 194.775 58.0401 195.452 58.4652 196.215C58.8902 196.892 59.2303 197.57 59.5703 198.248C62.2055 203.5 62.2906 207.397 61.5255 208.075C60.4204 208.837 57.0201 207.312 52.5147 205.11L44.9492 201.298L36.1935 197.062Z"
      fill="#059669"
    />
    <path
      d="M10.2664 129.715C5.84608 122.261 2.70085 114.128 0.830703 105.657C-0.444399 99.134 -0.189358 94.5597 1.17075 93.9667C2.44585 93.3738 5.84612 96.254 10.0965 101.167C15.3669 107.521 20.1272 114.382 24.2076 121.498C28.3729 128.614 31.9432 136.154 34.7484 143.947C36.9586 150.131 37.8086 154.451 36.9586 155.129C36.1085 155.807 31.8582 153.943 26.6728 149.623C20.1273 143.947 14.6018 137.17 10.2664 129.715Z"
      fill="#34D399"
    />
    <path
      d="M40.4438 151.063C37.0435 130.817 39.8487 113.62 42.739 113.281C45.6292 112.942 53.0249 128.445 56.4251 148.437C59.8254 168.429 57.1051 185.71 54.9799 186.134C52.4297 186.557 43.8441 171.309 40.4438 151.063Z"
      fill="#10B981"
    />
    <path
      d="M24.7175 41.6989C21.5722 32.3806 19.617 22.7234 18.937 12.9815C18.512 5.35736 19.3621 0.443992 20.7222 0.0204297C22.0823 -0.31842 25.1425 3.57836 28.7128 10.1859C33.2181 18.6572 36.9585 27.4674 40.0187 36.5316C43.079 45.5958 45.2891 54.9989 46.5642 64.4019C47.5843 71.8567 47.4993 76.8548 46.4792 77.1936C45.2891 77.7019 41.6339 74.2287 37.3835 67.8752C32.1131 59.9123 27.7777 51.0173 24.7175 41.6989Z"
      fill="#10B981"
    />
    <path
      d="M48.0944 70.6708C47.3293 51.3563 51.4947 35.4303 54.3849 35.2609C57.2751 35.0915 63.4806 50.509 64.2456 70.0776C65.0107 89.5615 59.3152 105.488 57.19 105.488C54.5548 105.572 48.8594 89.9852 48.0944 70.6708Z"
      fill="#059669"
    />
    <path
      d="M32.7931 99.2189C28.6278 95.661 24.9725 91.51 21.9973 86.8508C19.7021 83.0387 18.682 79.9045 19.5321 78.7185C20.3821 77.5325 23.6124 77.4478 28.1177 78.7185C33.7282 80.3281 38.8286 83.2082 43.249 86.9356C47.6693 90.7477 51.2395 95.4069 53.6197 100.744C55.4899 104.979 55.83 108.283 55.1499 109.045C54.2148 109.977 50.8995 109.469 46.7342 107.775C41.7188 105.742 36.9584 102.862 32.7931 99.2189Z"
      fill="#10B981"
    />
    <path
      d="M75.1264 177.578C72.7463 171.225 70.8761 164.786 69.601 158.094C68.6659 152.757 68.5809 148.945 69.856 148.183C71.1311 147.42 74.5314 149.284 78.6117 153.35C83.7121 158.687 87.7074 165.041 90.2576 171.902C92.8928 178.849 93.9129 186.219 93.4029 193.589C92.8929 199.349 91.4477 202.992 90.4277 203.161C89.0675 203.415 86.6023 200.535 83.7971 195.791C80.3968 190.115 77.4216 183.931 75.1264 177.578Z"
      fill="#059669"
    />
    <path
      d="M85.9224 171.055C88.0476 163.516 91.2779 156.4 95.6132 149.877C99.0985 144.794 102.244 142.168 103.604 142.592C104.964 143.015 105.559 147.081 105.304 153.096C104.879 160.635 103.604 168.175 101.479 175.375C99.4385 182.661 96.4632 189.777 92.8079 196.384C89.8327 201.636 87.1125 204.771 86.0924 204.517C84.8173 204.262 83.4573 200.281 83.2022 194.182C82.8622 186.473 83.7972 178.595 85.9224 171.055Z"
      fill="#10B981"
    />
    <path
      d="M95.1881 110.994C97.9933 104.047 102.074 97.694 107.004 92.103C110.914 87.7826 114.4 85.665 115.675 86.3427C116.95 87.0204 117.035 90.917 116.1 96.5928C114.825 103.624 112.784 110.57 110.064 117.178C108.534 120.821 106.834 124.294 104.879 127.682C103.264 130.478 101.564 133.189 99.6084 135.815C98.0783 137.933 96.8032 139.627 95.8681 140.982C94.933 142.338 94.338 143.269 93.828 143.269C93.318 143.269 92.2129 142.592 91.3628 140.728C90.4277 138.271 90.0027 135.645 90.1727 132.935C90.3427 129.461 90.7677 126.073 91.5328 122.684C92.4678 118.703 93.658 114.806 95.1881 110.994Z"
      fill="#10B981"
    />
    <path
      d="M73.8514 95.1526C69.176 78.549 69.9411 63.6396 72.6613 62.7925C75.4665 61.9454 84.6472 73.8898 89.4076 90.7476C94.168 107.605 91.8728 122.854 89.8326 123.362C87.2824 123.955 78.5268 111.672 73.8514 95.1526Z"
      fill="#059669"
    />
    <path
      d="M90.2577 61.8606C91.3628 52.5422 93.743 43.4779 97.2283 34.7526C100.034 27.9756 102.839 24.0787 104.284 24.3329C107.004 24.9259 108.959 42.8003 106.239 63.809C105.049 72.8732 103.009 81.8528 100.033 90.5782C97.6533 97.3552 95.3581 101.591 94.338 101.506C92.9779 101.421 91.2778 96.9317 90.2577 89.7311C89.0676 80.5822 89.0676 71.1789 90.2577 61.8606Z"
      fill="#059669"
    />
    <path
      d="M75.2116 222.137C101.394 222.137 122.645 219.68 123.41 216.715L119.5 262.206C119.5 283.807 99.6085 301.428 75.2116 301.428C50.8146 301.428 30.9231 283.807 30.9231 262.206L27.0127 216.715C27.6077 219.68 48.9445 222.137 75.2116 222.137Z"
      fill="#DEC7B2"
    />
    <path
      d="M121.71 236.199C118.395 236.284 115.25 237.639 112.955 240.011C108.024 244.84 104.964 252.21 100.884 257.716C99.4384 254.666 98.0783 251.617 96.8882 248.482C95.5281 244.67 90.3427 241.197 86.4324 244.247C82.267 247.381 78.8668 251.362 76.2316 255.852C73.8514 259.749 71.8962 264.578 68.2409 267.458C64.5006 270.423 60.5903 271.185 59.1452 266.103C57.8701 261.613 58.2952 256.784 56.8501 252.294C55.66 248.567 53.7048 244.67 49.8795 243.061C45.2891 241.112 40.9538 243.569 36.8735 245.602C34.4933 246.788 32.1131 248.143 29.7329 249.584L30.583 259.156C34.7483 256.53 39.7636 253.396 44.014 252.803C46.6492 252.379 46.4793 254.243 46.6493 256.699C46.8193 258.987 46.8192 261.189 46.9042 263.476C47.1593 268.051 47.8393 272.625 50.5596 276.437C56.34 284.57 67.2209 284.231 74.8715 279.064C78.7818 276.268 82.1821 272.71 84.6473 268.559C85.4973 267.288 86.3474 265.764 87.1975 264.408C90.5127 272.032 95.5281 278.809 103.859 276.014C109.044 274.235 112.359 268.644 115.42 264.408C116.355 263.138 118.055 259.918 119.925 257.123L121.71 236.199Z"
      fill="#EBDBCD"
    />
    <path
      d="M64.8407 120.312C66.7108 110.655 69.7711 101.252 73.9364 92.3571C77.3367 85.4953 80.3969 81.5139 81.842 81.8528C84.5622 82.6152 85.0723 101.506 80.737 123.362C79.6319 128.868 78.3568 134.036 76.9117 138.695C75.4666 143.354 74.1065 147.251 73.0864 150.47C72.0663 153.774 71.4712 156.484 71.0462 158.602C70.6212 160.635 70.2811 161.991 69.7711 162.16C69.261 162.33 67.986 161.567 66.6259 159.534C64.9257 156.654 63.7356 153.52 63.2256 150.216C62.5456 145.557 62.3755 140.897 62.8006 136.238C63.1406 130.986 63.8206 125.649 64.8407 120.312Z"
      fill="#10B981"
    />
    <path
      d="M123.24 216.122H115.675C115.76 216.207 115.845 216.376 115.845 216.461V216.546C115.76 219.172 97.5684 221.205 75.2116 221.205C52.8548 221.205 34.6633 219.087 34.5783 216.546C34.5783 216.546 34.5783 216.546 34.5783 216.461C34.5783 216.376 34.6634 216.207 34.7484 216.122H27.2678C27.1827 216.207 27.0978 216.376 27.0978 216.461C27.0978 216.461 27.0978 216.461 27.0978 216.546C27.1828 219.595 48.7745 222.137 75.2966 222.137C101.819 222.137 123.41 219.68 123.495 216.546V216.461C123.41 216.376 123.325 216.207 123.24 216.122Z"
      fill="#E8D4C1"
    />
    <path
      d="M204.167 308.035C203.912 308.12 203.657 308.035 203.402 307.951C201.616 307.103 199.831 306.51 197.876 306.256C197.621 306.172 197.366 306.087 197.196 305.833C197.026 305.579 197.026 305.325 197.026 305.07C197.111 304.816 197.281 304.562 197.451 304.393C197.706 304.223 197.961 304.223 198.216 304.223C200.341 304.562 202.297 305.24 204.252 306.172C204.507 306.256 204.677 306.511 204.762 306.765C204.847 307.019 204.847 307.273 204.762 307.527C204.677 307.696 204.592 307.781 204.507 307.866C204.507 307.866 204.337 307.951 204.167 308.035Z"
      fill="#110580"
    />
    <path
      d="M382.727 74.2109H104.932C95.0837 74.2109 87.0181 82.1854 87.0181 92.1112V267.467C87.0181 277.308 94.9988 285.368 104.932 285.368H382.727C392.576 285.368 400.641 277.393 400.641 267.467V92.1112C400.641 82.1854 392.576 74.2109 382.727 74.2109Z"
      fill="#B3B6DE"
    />
    <path
      d="M384.562 85.4108H103.377C100.737 85.4108 98.6078 87.5316 98.6078 90.1613V265.334C98.6078 267.963 100.737 270.084 103.377 270.084H384.562C387.202 270.084 389.331 267.963 389.331 265.334V90.1613C389.331 87.5316 387.202 85.4108 384.562 85.4108Z"
      fill="#1F2937"
    />
    <path
      d="M193.73 85.4108H103.287C102.011 85.4108 100.82 85.9203 99.969 86.7694C99.1182 87.6184 98.6078 88.892 98.6078 90.0807V265.414C98.6078 266.688 99.1182 267.877 100.054 268.726C100.905 269.575 102.181 270.084 103.372 270.084H193.815V85.4108H193.73Z"
      fill="#6875F5"
    />
    <path
      d="M435.349 369.959H52.3937C50.521 369.959 48.7335 369.45 47.1162 368.602C45.499 367.668 44.1372 366.396 43.2008 364.784C42.2645 363.172 41.7538 361.39 41.6686 359.523C41.5835 357.657 42.0942 355.875 42.9454 354.178L81.7601 294.616C83.2923 291.816 85.5055 289.44 88.2293 287.828C90.9532 286.216 94.1027 285.368 97.2521 285.368H390.491C393.64 285.368 396.79 286.216 399.514 287.828C402.237 289.44 404.451 291.816 405.983 294.616L444.798 354.263C445.649 355.875 446.074 357.741 445.989 359.523C445.904 361.39 445.393 363.172 444.457 364.699C443.521 366.311 442.159 367.584 440.542 368.517C439.009 369.45 437.222 369.959 435.349 369.959Z"
      fill="#C5C6E3"
    />
    <path
      d="M445.86 358.863C445.86 360.303 445.69 361.743 445.095 363.098C444.585 364.454 443.82 365.64 442.8 366.656C441.78 367.673 440.59 368.52 439.23 369.028C437.87 369.621 436.51 369.875 435.064 369.875H52.6189C51.1738 369.875 49.7288 369.621 48.4537 369.028C47.0936 368.435 45.9035 367.673 44.8834 366.656C43.8634 365.64 43.0983 364.369 42.5883 363.098C42.0783 361.743 41.8232 360.303 41.9082 358.863V368.351C41.9082 371.146 43.0983 373.857 45.1385 375.805C47.1787 377.754 49.8988 378.855 52.704 378.855H435.149C437.955 378.855 440.675 377.754 442.715 375.805C444.755 373.857 445.86 371.146 445.945 368.351V358.863H445.86Z"
      fill="#999BC9"
    />
    <path
      d="M318.35 362.082H169.418C168.823 362.082 168.228 361.912 167.803 361.574C167.293 361.319 166.868 360.896 166.613 360.388C166.273 359.879 166.103 359.286 166.103 358.778C166.103 358.27 166.188 357.592 166.443 357.084L172.309 338.023C172.819 337.092 173.499 336.414 174.349 335.821C175.199 335.313 176.219 335.059 177.239 334.974H310.615C311.635 334.974 312.655 335.228 313.505 335.821C314.355 336.329 315.12 337.092 315.545 338.023L321.411 357.084C321.666 357.592 321.75 358.185 321.75 358.778C321.75 359.371 321.58 359.964 321.24 360.388C320.9 360.896 320.56 361.319 320.05 361.574C319.455 361.912 318.945 362.082 318.35 362.082Z"
      fill="#D8DBF2"
    />
    <path
      d="M372.925 328.451H114.844C114.164 328.451 113.484 328.282 112.889 327.943C112.294 327.604 111.784 327.096 111.444 326.503C111.104 325.91 110.934 325.232 110.849 324.554C110.849 323.877 111.019 323.199 111.359 322.521L122.155 299.988C122.75 298.886 123.6 298.039 124.62 297.446C125.64 296.853 126.83 296.514 128.02 296.514H359.834C361.024 296.514 362.214 296.853 363.234 297.446C364.254 298.039 365.104 298.971 365.699 299.988L376.58 322.521C376.92 323.114 377.09 323.792 377.005 324.554C377.005 325.232 376.75 325.91 376.41 326.503C376.07 327.096 375.56 327.604 374.965 327.943C374.285 328.282 373.605 328.451 372.925 328.451Z"
      fill="#A7A9D6"
    />
    <path
      d="M170.013 192.996H160.405L151.732 170.561L143.069 192.996H133.461L146.559 160.805H156.91L170.013 192.996Z"
      fill="white"
    />
    <path
      d="M176.814 206.813L176.36 207.155C169.508 212.269 161.17 215.03 152.602 215.021C151.426 215.021 150.244 214.971 149.068 214.867H148.968C141.847 214.192 135.084 211.444 129.527 206.966C124.025 202.539 119.99 196.569 117.947 189.831C115.904 183.127 115.967 175.963 118.126 169.295C120.289 162.589 124.434 156.687 130.02 152.358L130.08 152.309C135.775 147.758 142.627 144.867 149.875 143.957L150.753 143.862L154.427 152.67H153.225H152.991C146.844 153.043 140.96 155.279 136.132 159.08L135.967 159.213L135.897 159.268C135.794 159.345 135.695 159.43 135.603 159.521L135.449 159.65C131.354 162.916 128.342 167.332 126.805 172.324C125.271 177.286 125.29 182.595 126.86 187.546C128.435 192.528 131.477 196.926 135.593 200.171C139.749 203.458 144.785 205.458 150.075 205.92C157.815 206.569 165.508 204.225 171.555 199.378C171.834 199.154 172.113 198.922 172.387 198.689L173.155 198.029L176.814 206.813Z"
      fill="white"
    />
    <path d="M369.713 166.688H212.959V168.626H369.713V166.688Z" fill="#CDE6F9" />
    <path d="M283.389 126.468H279.238V164.573H283.389V126.468Z" fill="#9061F9" />
    <path d="M308.593 140.199H304.998V164.75H308.593V140.199Z" fill="#9061F9" />
    <path d="M277.766 134.637H273.615V164.573H277.766V134.637Z" fill="#FDD147" />
    <path d="M303.56 145.368H299.246V164.75H303.56V145.368Z" fill="#FDD147" />
    <path d="M272.091 129.726H267.939V164.573H272.091V129.726Z" fill="#10E1CF" />
    <path d="M369.55 129.726H365.398V164.573H369.55V129.726Z" fill="#10E1CF" />
    <path d="M297.807 142.137H293.493V164.75H297.807V142.137Z" fill="#10E1CF" />
    <path d="M257.166 140.019H253.015V164.573H257.166V140.019Z" fill="#9061F9" />
    <path d="M332.322 147.306H328.727V164.75H332.322V147.306Z" fill="#9061F9" />
    <path d="M251.491 151.257H247.339V164.573H251.491V151.257Z" fill="#FDD147" />
    <path d="M327.289 136.323H322.974V164.75H327.289V136.323Z" fill="#FDD147" />
    <path d="M245.868 155.79H241.716V164.573H245.868V155.79Z" fill="#10E1CF" />
    <path d="M321.536 153.767H317.222V164.75H321.536V153.767Z" fill="#10E1CF" />
    <path d="M231.942 154.468H227.79V164.573H231.942V154.468Z" fill="#FDD147" />
    <path d="M355.682 154.468H351.53V164.573H355.682V154.468Z" fill="#FDD147" />
    <path d="M226.266 150.171H222.115V164.573H226.266V150.171Z" fill="#10E1CF" />
    <path d="M350.006 150.171H345.854V164.573H350.006V150.171Z" fill="#10E1CF" />
    <path d="M220.643 159.19H216.492V164.525H220.643V159.19Z" fill="#9061F9" />
    <path d="M344.383 159.19H340.232V164.525H344.383V159.19Z" fill="#9061F9" />
    <path d="M239.614 101.206H214.863V102.576H239.614V101.206Z" fill="#374151" />
    <path d="M254.907 104.842H214.863V106.211H254.907V104.842Z" fill="#374151" />
    <path d="M254.907 108.431H214.863V109.8H254.907V108.431Z" fill="#374151" />
    <path d="M239.614 113.247H214.863V114.616H239.614V113.247Z" fill="#374151" />
    <path d="M254.907 116.883H214.863V118.252H254.907V116.883Z" fill="#374151" />
    <path d="M254.907 120.471H214.863V121.841H254.907V120.471Z" fill="#374151" />
    <path d="M218.06 241.607V237.73H212.456V241.607H218.06Z" fill="#9061F9" />
    <path d="M369.713 241.633V237.757L220.869 237.757V241.633L369.713 241.633Z" fill="#374151" />
    <path d="M218.06 248.477V244.6H212.456V248.477H218.06Z" fill="#FDD147" />
    <path d="M369.713 248.74V244.864L220.869 244.864V248.74L369.713 248.74Z" fill="#374151" />
    <path d="M218.06 255.347V251.47H212.456V255.347H218.06Z" fill="#10E1CF" />
    <path d="M369.713 255.201V251.324L220.869 251.324V255.201L369.713 255.201Z" fill="#374151" />
    <path
      d="M258.767 209.348C258.325 219.016 250.607 226.818 240.973 227.358V219.114C246.331 218.574 250.46 214.108 250.558 208.612L258.767 209.348Z"
      fill="#FDD147"
    />
    <path
      d="M258.816 208.514C258.816 208.808 258.816 209.152 258.767 209.446L250.509 208.808C250.509 208.71 250.509 208.612 250.509 208.563C250.509 206.207 249.329 203.459 248.051 201.693L255.277 197.62C257.391 200.613 258.816 204.49 258.816 208.514Z"
      fill="#10E1CF"
    />
    <path
      d="M239.842 189.572C229.373 189.572 220.869 198.061 220.869 208.514C220.869 218.966 229.373 227.456 239.842 227.456C240.285 227.456 240.678 227.456 241.121 227.407V219.163C240.727 219.212 240.285 219.212 239.842 219.212C233.944 219.212 229.127 214.403 229.127 208.514C229.127 202.625 233.944 197.816 239.842 197.816C243.136 197.816 246.085 199.288 248.051 201.644L255.326 197.571C251.836 192.712 246.233 189.572 239.842 189.572Z"
      fill="#9061F9"
    />
    <path d="M358.929 227.262H290.114V229.481H358.929V227.262Z" fill="#CDE6F9" />
    <path d="M353.679 187.363H349.943V225.467H353.679V187.363Z" fill="#9061F9" />
    <path d="M348.619 195.531H344.882V225.467H348.619V195.531Z" fill="#FDD147" />
    <path d="M343.511 190.621H339.774V225.467H343.511V190.621Z" fill="#10E1CF" />
    <path d="M330.079 200.914H326.342V225.467H330.079V200.914Z" fill="#9061F9" />
    <path d="M324.971 212.152H321.234V225.467H324.971V212.152Z" fill="#FDD147" />
    <path d="M319.91 216.685H316.174V225.467H319.91V216.685Z" fill="#10E1CF" />
    <path d="M307.377 215.363H303.64V225.467H307.377V215.363Z" fill="#FDD147" />
    <path d="M302.269 211.066H298.532V225.467H302.269V211.066Z" fill="#10E1CF" />
    <path d="M297.208 220.085H293.472V225.42H297.208V220.085Z" fill="#9061F9" />
  </svg>

  <svg v-else class="w-full d-block" viewBox="0 0 446 398" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse opacity="0.1" cx="75.2309" cy="298.039" rx="45.4786" ry="12.7069" fill="#252D38" />
    <ellipse opacity="0.1" cx="240.994" cy="337.007" rx="183.19" ry="60.993" fill="#252D38" />
    <path
      d="M123.41 216.461C123.24 213.411 101.734 210.955 75.2116 210.955C48.6895 210.955 27.2677 213.411 27.0127 216.461C27.0127 216.461 27.0127 216.461 27.0127 216.546C27.0977 219.595 48.6895 222.137 75.2116 222.137C101.734 222.137 123.325 219.595 123.41 216.461C123.41 216.546 123.41 216.461 123.41 216.461ZM75.2116 221.205C52.8548 221.205 34.6633 219.087 34.5783 216.546C34.5783 216.546 34.5783 216.546 34.5783 216.461C34.7483 213.835 52.8548 211.802 75.2116 211.802C97.5684 211.802 115.675 213.92 115.845 216.461V216.546C115.76 219.087 97.6534 221.205 75.2116 221.205Z"
      fill="#E8D4C1"
    />
    <path
      d="M75.2116 221.205C97.5684 221.205 115.76 219.087 115.845 216.546V216.461C115.675 213.835 97.5684 211.802 75.2116 211.802C52.8548 211.802 34.7483 213.92 34.5783 216.461C34.5783 216.461 34.5783 216.461 34.5783 216.546C34.6633 219.087 52.8548 221.205 75.2116 221.205Z"
      fill="#BA9C7E"
    />
    <path
      d="M77.5067 221.713C75.8915 197.655 72.6614 173.766 67.901 150.131C63.1406 126.581 56.85 103.37 49.1144 80.5821C44.694 67.7058 39.8487 55.0835 34.5783 42.5461C34.3232 42.1225 33.8983 41.8684 33.4732 41.6989C33.0482 41.6142 32.5381 41.6144 32.113 41.8685C31.688 42.1227 31.433 42.5461 31.263 42.9696C31.178 43.3932 31.178 43.9015 31.4331 44.3251C40.7838 66.3504 48.6044 88.8839 55.0649 111.926C61.4404 134.798 66.2858 158.009 69.6861 181.475C71.5562 194.774 73.0014 208.159 73.9364 221.628C74.0214 224.085 77.6767 224.085 77.5067 221.713Z"
      fill="#059669"
    />
    <path
      d="M89.9178 226.288C88.6427 188.675 90.1729 151.063 94.6782 113.62C95.9533 103.2 97.3984 92.7806 99.1835 82.361C99.5236 80.0737 96.0383 79.0573 95.6983 81.4293C89.4928 118.703 86.2625 156.4 85.9225 194.182C85.8375 204.855 85.9225 215.529 86.3475 226.203C86.3475 228.66 90.0028 228.66 89.9178 226.288Z"
      fill="#059669"
    />
    <path
      d="M68.6659 229.676C63.2255 199.518 51.1546 170.886 33.4732 145.811C28.5428 138.78 23.1024 132.087 17.3219 125.734C15.7068 124.04 13.1566 126.581 14.7717 128.36C35.0884 150.724 50.2195 177.324 59.1452 206.126C61.6104 214.174 63.6506 222.391 65.1807 230.693C65.6057 232.98 69.176 232.048 68.6659 229.676Z"
      fill="#059669"
    />
    <path
      d="M36.1935 197.062L27.3527 192.826L19.7021 189.268C15.1968 187.151 11.8816 185.371 11.7966 183.847C11.7116 182.407 14.9418 180.204 20.8073 179.103C21.5723 179.018 22.3373 178.849 23.1024 178.764C23.8675 178.679 24.7175 178.679 25.5676 178.594C27.4378 178.51 29.3079 178.679 31.0931 178.849C35.3434 179.442 39.4238 180.628 43.2491 182.491C47.0744 184.355 50.4746 186.896 53.5349 189.861C54.81 191.217 56 192.572 57.1051 194.097C57.6151 194.775 58.0401 195.452 58.4652 196.215C58.8902 196.892 59.2303 197.57 59.5703 198.248C62.2055 203.5 62.2906 207.397 61.5255 208.074C60.4204 208.837 57.0201 207.312 52.5147 205.11L44.9492 201.297L36.1935 197.062Z"
      fill="#059669"
    />
    <path
      d="M10.2664 129.715C5.84608 122.261 2.70085 114.128 0.830703 105.657C-0.444399 99.134 -0.189358 94.5597 1.17075 93.9667C2.44585 93.3737 5.84612 96.2539 10.0965 101.167C15.3669 107.521 20.1272 114.382 24.2076 121.498C28.3729 128.614 31.9432 136.154 34.7484 143.947C36.9586 150.131 37.8086 154.451 36.9586 155.129C36.1085 155.807 31.8582 153.943 26.6728 149.623C20.1273 143.947 14.6018 137.17 10.2664 129.715Z"
      fill="#34D399"
    />
    <path
      d="M40.4438 151.063C37.0435 130.817 39.8487 113.62 42.739 113.281C45.6292 112.942 53.0249 128.445 56.4251 148.437C59.8254 168.429 57.1051 185.71 54.9799 186.134C52.4297 186.557 43.8441 171.309 40.4438 151.063Z"
      fill="#10B981"
    />
    <path
      d="M24.7175 41.6989C21.5722 32.3806 19.617 22.7234 18.937 12.9815C18.512 5.35736 19.3621 0.443992 20.7222 0.0204297C22.0823 -0.31842 25.1425 3.57836 28.7128 10.1859C33.2181 18.6572 36.9585 27.4674 40.0187 36.5316C43.079 45.5958 45.2891 54.9989 46.5642 64.4019C47.5843 71.8567 47.4993 76.8548 46.4792 77.1936C45.2891 77.7019 41.6339 74.2287 37.3835 67.8752C32.1131 59.9123 27.7777 51.0173 24.7175 41.6989Z"
      fill="#10B981"
    />
    <path
      d="M48.0944 70.6708C47.3294 51.3563 51.4947 35.4303 54.3849 35.2609C57.2752 35.0915 63.4806 50.509 64.2457 70.0776C65.0108 89.5615 59.3153 105.488 57.1901 105.488C54.5549 105.572 48.8595 89.9853 48.0944 70.6708Z"
      fill="#059669"
    />
    <path
      d="M32.7931 99.2189C28.6278 95.661 24.9726 91.51 21.9973 86.8508C19.7021 83.0387 18.682 79.9045 19.5321 78.7185C20.3822 77.5325 23.6124 77.4478 28.1178 78.7185C33.7282 80.328 38.8287 83.2082 43.249 86.9356C47.6694 90.7476 51.2396 95.4069 53.6198 100.744C55.4899 104.979 55.83 108.283 55.15 109.045C54.2149 109.977 50.8996 109.469 46.7343 107.775C41.7189 105.742 36.9585 102.862 32.7931 99.2189Z"
      fill="#10B981"
    />
    <path
      d="M75.1265 177.578C72.7463 171.225 70.8762 164.786 69.6011 158.094C68.666 152.757 68.5809 148.945 69.856 148.183C71.1311 147.42 74.5314 149.284 78.6118 153.35C83.7122 158.687 87.7074 165.041 90.2576 171.902C92.8929 178.849 93.913 186.219 93.403 193.589C92.8929 199.349 91.4478 202.992 90.4277 203.161C89.0676 203.415 86.6024 200.535 83.7971 195.791C80.3969 190.115 77.4217 183.931 75.1265 177.578Z"
      fill="#059669"
    />
    <path
      d="M85.9224 171.055C88.0476 163.516 91.2779 156.4 95.6132 149.877C99.0985 144.794 102.244 142.168 103.604 142.592C104.964 143.015 105.559 147.081 105.304 153.096C104.879 160.635 103.604 168.175 101.479 175.375C99.4385 182.661 96.4632 189.777 92.8079 196.384C89.8327 201.636 87.1125 204.771 86.0924 204.517C84.8173 204.263 83.4573 200.281 83.2022 194.182C82.8622 186.473 83.7972 178.595 85.9224 171.055Z"
      fill="#10B981"
    />
    <path
      d="M95.1881 110.994C97.9934 104.047 102.074 97.694 107.004 92.103C110.914 87.7826 114.4 85.665 115.675 86.3427C116.95 87.0204 117.035 90.917 116.1 96.5928C114.825 103.624 112.785 110.57 110.064 117.178C108.534 120.821 106.834 124.294 104.879 127.682C103.264 130.478 101.564 133.189 99.6085 135.815C98.0783 137.933 96.8032 139.627 95.8681 140.982C94.9331 142.338 94.3381 143.269 93.8281 143.269C93.318 143.269 92.2129 142.592 91.3628 140.728C90.4278 138.271 90.0027 135.645 90.1727 132.935C90.3427 129.461 90.7678 126.073 91.5328 122.684C92.4679 118.703 93.658 114.806 95.1881 110.994Z"
      fill="#10B981"
    />
    <path
      d="M73.8515 95.1527C69.1761 78.549 69.9411 63.6396 72.6614 62.7925C75.4666 61.9454 84.6473 73.8899 89.4077 90.7477C94.168 107.605 91.8728 122.854 89.8327 123.362C87.2825 123.955 78.5268 111.672 73.8515 95.1527Z"
      fill="#059669"
    />
    <path
      d="M90.2577 61.8605C91.3628 52.5422 93.743 43.4779 97.2283 34.7525C100.034 27.9755 102.839 24.0787 104.284 24.3328C107.004 24.9258 108.959 42.8003 106.239 63.809C105.049 72.8732 103.009 81.8528 100.033 90.5782C97.6533 97.3552 95.3581 101.591 94.338 101.506C92.9779 101.421 91.2778 96.9316 90.2577 89.7311C89.0676 80.5821 89.0676 71.1789 90.2577 61.8605Z"
      fill="#059669"
    />
    <path
      d="M75.2116 222.137C101.394 222.137 122.645 219.68 123.41 216.715L119.5 262.206C119.5 283.807 99.6085 301.428 75.2116 301.428C50.8146 301.428 30.9231 283.807 30.9231 262.206L27.0127 216.715C27.6077 219.68 48.9445 222.137 75.2116 222.137Z"
      fill="#DEC7B2"
    />
    <path
      d="M121.71 236.199C118.395 236.284 115.25 237.639 112.955 240.011C108.024 244.84 104.964 252.21 100.884 257.716C99.4384 254.666 98.0783 251.617 96.8882 248.482C95.5281 244.67 90.3427 241.197 86.4324 244.247C82.267 247.381 78.8668 251.363 76.2316 255.852C73.8514 259.749 71.8962 264.578 68.2409 267.458C64.5006 270.423 60.5903 271.185 59.1452 266.103C57.8701 261.613 58.2952 256.784 56.8501 252.294C55.66 248.567 53.7048 244.67 49.8795 243.061C45.2891 241.112 40.9538 243.569 36.8735 245.602C34.4933 246.788 32.1131 248.144 29.7329 249.584L30.583 259.156C34.7483 256.53 39.7636 253.396 44.014 252.803C46.6492 252.379 46.4793 254.243 46.6493 256.699C46.8193 258.987 46.8192 261.189 46.9042 263.476C47.1593 268.051 47.8393 272.625 50.5596 276.437C56.34 284.57 67.2209 284.231 74.8715 279.064C78.7818 276.268 82.1821 272.71 84.6473 268.559C85.4973 267.288 86.3474 265.764 87.1975 264.408C90.5127 272.032 95.5281 278.809 103.859 276.014C109.044 274.235 112.359 268.644 115.42 264.408C116.355 263.138 118.055 259.919 119.925 257.123L121.71 236.199Z"
      fill="#EBDBCD"
    />
    <path
      d="M64.8407 120.312C66.7109 110.655 69.7712 101.252 73.9365 92.3571C77.3368 85.4954 80.3969 81.514 81.8421 81.8528C84.5623 82.6153 85.0724 101.506 80.737 123.362C79.632 128.868 78.3568 134.036 76.9117 138.695C75.4666 143.354 74.1065 147.251 73.0864 150.47C72.0663 153.774 71.4713 156.484 71.0462 158.602C70.6212 160.635 70.2812 161.991 69.7711 162.16C69.2611 162.33 67.986 161.567 66.6259 159.534C64.9258 156.654 63.7357 153.52 63.2257 150.216C62.5456 145.557 62.3756 140.897 62.8006 136.238C63.1407 130.986 63.8206 125.649 64.8407 120.312Z"
      fill="#10B981"
    />
    <path
      d="M123.24 216.122H115.675C115.76 216.207 115.845 216.376 115.845 216.461V216.546C115.76 219.172 97.5684 221.205 75.2116 221.205C52.8548 221.205 34.6633 219.087 34.5783 216.546C34.5783 216.546 34.5783 216.546 34.5783 216.461C34.5783 216.376 34.6634 216.207 34.7484 216.122H27.2678C27.1827 216.207 27.0978 216.376 27.0978 216.461C27.0978 216.461 27.0978 216.461 27.0978 216.546C27.1828 219.595 48.7745 222.137 75.2966 222.137C101.819 222.137 123.41 219.68 123.495 216.546V216.461C123.41 216.376 123.325 216.207 123.24 216.122Z"
      fill="#E8D4C1"
    />
    <path
      d="M204.167 308.035C203.912 308.12 203.657 308.035 203.402 307.951C201.617 307.103 199.831 306.51 197.876 306.256C197.621 306.172 197.366 306.087 197.196 305.833C197.026 305.579 197.026 305.325 197.026 305.07C197.111 304.816 197.281 304.562 197.451 304.393C197.706 304.223 197.961 304.223 198.216 304.223C200.341 304.562 202.297 305.24 204.252 306.172C204.507 306.256 204.677 306.511 204.762 306.765C204.847 307.019 204.847 307.273 204.762 307.527C204.677 307.696 204.592 307.781 204.507 307.866C204.507 307.866 204.337 307.951 204.167 308.035Z"
      fill="#110580"
    />
    <path
      d="M382.727 74.2109H104.932C95.0838 74.2109 87.0181 82.1854 87.0181 92.1112V267.467C87.0181 277.308 94.9989 285.368 104.932 285.368H382.727C392.576 285.368 400.641 277.393 400.641 267.467V92.1112C400.641 82.1854 392.576 74.2109 382.727 74.2109Z"
      fill="#B3B6DE"
    />
    <path
      d="M384.562 85.4107H103.377C100.737 85.4107 98.6078 87.5315 98.6078 90.1613V265.333C98.6078 267.963 100.737 270.084 103.377 270.084H384.562C387.202 270.084 389.331 267.963 389.331 265.333V90.1613C389.331 87.5315 387.202 85.4107 384.562 85.4107Z"
      fill="white"
    />
    <path
      d="M193.73 85.4107H103.287C102.011 85.4107 100.82 85.9203 99.969 86.7693C99.1182 87.6184 98.6078 88.8919 98.6078 90.0806V265.414C98.6078 266.688 99.1182 267.877 100.054 268.726C100.905 269.575 102.181 270.084 103.372 270.084H193.815V85.4107H193.73Z"
      fill="#5850EC"
    />
    <path
      d="M435.349 369.959H52.3937C50.521 369.959 48.7335 369.45 47.1162 368.602C45.499 367.668 44.1372 366.396 43.2008 364.784C42.2645 363.172 41.7538 361.39 41.6686 359.523C41.5835 357.657 42.0942 355.875 42.9454 354.178L81.7601 294.616C83.2923 291.816 85.5055 289.44 88.2293 287.828C90.9532 286.216 94.1027 285.368 97.2521 285.368H390.491C393.64 285.368 396.79 286.216 399.514 287.828C402.237 289.44 404.451 291.816 405.983 294.616L444.798 354.263C445.649 355.875 446.074 357.741 445.989 359.523C445.904 361.39 445.393 363.172 444.457 364.699C443.521 366.311 442.159 367.584 440.542 368.517C439.009 369.45 437.222 369.959 435.349 369.959Z"
      fill="#C5C6E3"
    />
    <path
      d="M445.86 358.863C445.86 360.303 445.69 361.743 445.095 363.098C444.585 364.454 443.82 365.64 442.8 366.656C441.78 367.673 440.59 368.52 439.23 369.028C437.87 369.621 436.51 369.875 435.064 369.875H52.619C51.1739 369.875 49.7288 369.621 48.4537 369.028C47.0936 368.435 45.9036 367.673 44.8835 366.656C43.8634 365.64 43.0984 364.369 42.5884 363.098C42.0783 361.743 41.8233 360.303 41.9083 358.863V368.351C41.9083 371.146 43.0984 373.857 45.1386 375.805C47.1787 377.754 49.8989 378.855 52.7041 378.855H435.149C437.955 378.855 440.675 377.754 442.715 375.805C444.755 373.857 445.86 371.146 445.945 368.351V358.863H445.86Z"
      fill="#999BC9"
    />
    <path
      d="M318.35 362.082H169.418C168.823 362.082 168.228 361.912 167.803 361.574C167.293 361.319 166.868 360.896 166.613 360.388C166.273 359.879 166.103 359.286 166.103 358.778C166.103 358.27 166.188 357.592 166.443 357.084L172.309 338.023C172.819 337.092 173.499 336.414 174.349 335.821C175.199 335.313 176.219 335.059 177.239 334.974H310.615C311.635 334.974 312.655 335.228 313.505 335.821C314.355 336.329 315.12 337.092 315.545 338.023L321.411 357.084C321.666 357.592 321.75 358.185 321.75 358.778C321.75 359.371 321.58 359.964 321.24 360.388C320.9 360.896 320.56 361.319 320.05 361.574C319.455 361.912 318.945 362.082 318.35 362.082Z"
      fill="#D8DBF2"
    />
    <path
      d="M372.925 328.451H114.844C114.164 328.451 113.484 328.282 112.889 327.943C112.294 327.604 111.784 327.096 111.444 326.503C111.104 325.91 110.934 325.232 110.849 324.554C110.849 323.877 111.019 323.199 111.359 322.521L122.155 299.988C122.75 298.886 123.6 298.039 124.62 297.446C125.64 296.853 126.83 296.514 128.02 296.514H359.834C361.024 296.514 362.214 296.853 363.234 297.446C364.254 298.039 365.104 298.971 365.699 299.988L376.58 322.521C376.92 323.114 377.09 323.792 377.005 324.554C377.005 325.232 376.75 325.91 376.41 326.503C376.07 327.096 375.56 327.604 374.965 327.943C374.285 328.282 373.605 328.451 372.925 328.451Z"
      fill="#A7A9D6"
    />
    <path
      d="M170.013 192.996H160.405L151.732 170.561L143.069 192.996H133.461L146.559 160.805H156.91L170.013 192.996Z"
      fill="white"
    />
    <path
      d="M176.814 206.813L176.36 207.155C169.508 212.269 161.17 215.029 152.602 215.021C151.426 215.021 150.244 214.971 149.068 214.867H148.968C141.847 214.192 135.084 211.444 129.527 206.966C124.025 202.539 119.99 196.568 117.947 189.831C115.904 183.127 115.967 175.963 118.126 169.295C120.289 162.589 124.434 156.687 130.02 152.358L130.08 152.309C135.775 147.758 142.627 144.867 149.875 143.957L150.753 143.862L154.427 152.67H153.225H152.991C146.844 153.043 140.96 155.279 136.132 159.079L135.967 159.213L135.897 159.268C135.794 159.345 135.695 159.43 135.603 159.521L135.449 159.65C131.354 162.916 128.342 167.332 126.805 172.324C125.271 177.286 125.29 182.595 126.86 187.546C128.435 192.528 131.477 196.926 135.593 200.171C139.749 203.458 144.785 205.458 150.075 205.92C157.815 206.569 165.508 204.225 171.555 199.378C171.834 199.154 172.113 198.922 172.387 198.689L173.155 198.029L176.814 206.813Z"
      fill="white"
    />
    <path d="M369.713 166.688H212.959V168.626H369.713V166.688Z" fill="#CDE6F9" />
    <path d="M283.389 126.468H279.238V164.573H283.389V126.468Z" fill="#7E3AF2" />
    <path d="M308.593 140.199H304.998V164.75H308.593V140.199Z" fill="#7E3AF2" />
    <path d="M277.766 134.636H273.615V164.573H277.766V134.636Z" fill="#FDD147" />
    <path d="M303.56 145.368H299.246V164.75H303.56V145.368Z" fill="#FDD147" />
    <path d="M272.091 129.726H267.939V164.573H272.091V129.726Z" fill="#10E1CF" />
    <path d="M369.55 129.726H365.398V164.573H369.55V129.726Z" fill="#10E1CF" />
    <path d="M297.807 142.137H293.493V164.75H297.807V142.137Z" fill="#10E1CF" />
    <path d="M257.166 140.019H253.015V164.573H257.166V140.019Z" fill="#7E3AF2" />
    <path d="M332.322 147.306H328.727V164.75H332.322V147.306Z" fill="#7E3AF2" />
    <path d="M251.491 151.257H247.339V164.573H251.491V151.257Z" fill="#FDD147" />
    <path d="M327.289 136.323H322.974V164.75H327.289V136.323Z" fill="#FDD147" />
    <path d="M245.868 155.79H241.716V164.573H245.868V155.79Z" fill="#10E1CF" />
    <path d="M321.536 153.767H317.222V164.75H321.536V153.767Z" fill="#10E1CF" />
    <path d="M231.942 154.468H227.79V164.573H231.942V154.468Z" fill="#FDD147" />
    <path d="M355.682 154.468H351.53V164.573H355.682V154.468Z" fill="#FDD147" />
    <path d="M226.266 150.171H222.115V164.573H226.266V150.171Z" fill="#10E1CF" />
    <path d="M350.006 150.171H345.854V164.573H350.006V150.171Z" fill="#10E1CF" />
    <path d="M220.643 159.19H216.492V164.525H220.643V159.19Z" fill="#7E3AF2" />
    <path d="M344.383 159.19H340.232V164.525H344.383V159.19Z" fill="#7E3AF2" />
    <path d="M239.614 101.206H214.863V102.576H239.614V101.206Z" fill="#CDE6F9" />
    <path d="M254.907 104.842H214.863V106.211H254.907V104.842Z" fill="#CDE6F9" />
    <path d="M254.907 108.431H214.863V109.8H254.907V108.431Z" fill="#CDE6F9" />
    <path d="M239.614 113.247H214.863V114.616H239.614V113.247Z" fill="#CDE6F9" />
    <path d="M254.907 116.883H214.863V118.252H254.907V116.883Z" fill="#CDE6F9" />
    <path d="M254.907 120.471H214.863V121.84H254.907V120.471Z" fill="#CDE6F9" />
    <path d="M218.06 241.607V237.73H212.456V241.607H218.06Z" fill="#7E3AF2" />
    <path d="M369.713 241.633V237.757L220.869 237.757V241.633L369.713 241.633Z" fill="#CDE6F9" />
    <path d="M218.06 248.477V244.6H212.456V248.477H218.06Z" fill="#FDD147" />
    <path d="M369.713 248.74V244.864L220.869 244.864V248.74L369.713 248.74Z" fill="#CDE6F9" />
    <path d="M218.06 255.347V251.47H212.456V255.347H218.06Z" fill="#10E1CF" />
    <path d="M369.713 255.201V251.324L220.869 251.324V255.201L369.713 255.201Z" fill="#CDE6F9" />
    <path
      d="M258.767 209.348C258.325 219.016 250.607 226.818 240.973 227.358V219.114C246.331 218.574 250.46 214.108 250.558 208.612L258.767 209.348Z"
      fill="#FDD147"
    />
    <path
      d="M258.816 208.514C258.816 208.808 258.816 209.152 258.767 209.446L250.509 208.808C250.509 208.71 250.509 208.612 250.509 208.563C250.509 206.207 249.329 203.459 248.051 201.693L255.277 197.62C257.391 200.613 258.816 204.49 258.816 208.514Z"
      fill="#10E1CF"
    />
    <path
      d="M239.842 189.572C229.373 189.572 220.869 198.061 220.869 208.514C220.869 218.967 229.373 227.456 239.842 227.456C240.285 227.456 240.678 227.456 241.121 227.407V219.163C240.727 219.212 240.285 219.212 239.842 219.212C233.944 219.212 229.127 214.403 229.127 208.514C229.127 202.625 233.944 197.816 239.842 197.816C243.136 197.816 246.085 199.288 248.051 201.644L255.326 197.571C251.836 192.712 246.233 189.572 239.842 189.572Z"
      fill="#7E3AF2"
    />
    <path d="M358.929 227.262H290.114V229.481H358.929V227.262Z" fill="#CDE6F9" />
    <path d="M353.679 187.363H349.943V225.467H353.679V187.363Z" fill="#7E3AF2" />
    <path d="M348.619 195.531H344.882V225.467H348.619V195.531Z" fill="#FDD147" />
    <path d="M343.511 190.621H339.774V225.467H343.511V190.621Z" fill="#10E1CF" />
    <path d="M330.079 200.914H326.342V225.467H330.079V200.914Z" fill="#7E3AF2" />
    <path d="M324.971 212.152H321.234V225.467H324.971V212.152Z" fill="#FDD147" />
    <path d="M319.91 216.685H316.174V225.468H319.91V216.685Z" fill="#10E1CF" />
    <path d="M307.377 215.363H303.64V225.467H307.377V215.363Z" fill="#FDD147" />
    <path d="M302.269 211.066H298.532V225.468H302.269V211.066Z" fill="#10E1CF" />
    <path d="M297.208 220.085H293.472V225.42H297.208V220.085Z" fill="#7E3AF2" />
  </svg>
</template>

<script>
  export default {
    name: 'SvgAuthIllustration',
  };
</script>

<script setup>
  import { useTheme } from '@/hooks';

  const { theme } = useTheme();
</script>
