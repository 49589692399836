import { useUserStore } from '@/to-fsd/shared/store/user';

import { ROUTE_NAMES } from '@/to-fsd/shared/constants';

const requirePermissions = permissions => {
  return async ({ next }) => {
    const userStore = useUserStore();

    const userPermissions = userStore.me.permissions;
    const userPermissionNames = userPermissions.map(permission => permission.name);

    const hasPermissionToTargetRoute = permissions.every(permissionName =>
      userPermissionNames.includes(permissionName)
    );

    if (!hasPermissionToTargetRoute) {
      return { name: ROUTE_NAMES.noPermission };
    }

    return next();
  };
};

export default requirePermissions;
