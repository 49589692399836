export default {
  features: {
    addEmployee: {
      button: "Xodim qo'shish",
      modal: {
        title: "Yangi xodim qo'shish",
        form: {
          search: {
            label: 'Telefon raqam',
            placeholder: '(00) 000-00-00',
            userIsEmployeeAlert:
              "Ushbu raqamli foydalanuvchi allaqachon merchantlarning biriga xodim bo'lib qo'shilgan",
            addEmployee: "Qo'shish",
            storeSelect: {
              label: "Do'kon",
              placeholder: "Do'konlardan birini tanlang",
            },
          },
        },
        createUserForm: {
          sendSms: 'SMS yuborish',
          code: {
            label: 'Kod',
            placeholder: 'Qabul qilingan kodni kiriting',
          },
          name: {
            label: 'Ism',
            placeholder: 'Ismingizni kiriting',
          },
          store: {
            label: 'Do‘kon',
            placeholder: 'Do‘konni tanlang',
          },
          brokerUserId: {
            label: 'Broker foydalanuvchi IDsi',
            placeholder: 'Broker foydalanuvchi IDsi',
          },
          submit: 'Xodim qo‘shish',
        },
      },
    },
  },
};
