import { generateError, mainConnect } from '@/services';

export const getClient = clientId => {
  return mainConnect.get(`v2/clients/clients/${clientId}`).catch(generateError);
};

export const getLimit = clientId => {
  return mainConnect.get(`/clients/clients/${clientId}/limit`).catch(generateError);
};

export const getClients = params => {
  return mainConnect.get('/clients/clients', { params }).catch(generateError);
};

export const getIdentifiedClients = params => {
  return mainConnect.get('/clients/clients-identified', { params }).catch(generateError);
};

export const uploadFile = (clientId, formData) => {
  return mainConnect.post(`/clients/clients/${clientId}/files`, formData).catch(generateError);
};

export const deleteFile = (clientId, fileId) => {
  return mainConnect.delete(`/clients/clients/${clientId}/files/${fileId}`).catch(generateError);
};

export const getRequirements = clientId => {
  return mainConnect.get(`/v2/clients/clients/create/${clientId}/requirements`).catch(generateError);
};

export const clientRequestReview = clientId => {
  return mainConnect.post(`/clients/clients/${clientId}/request-review`).catch(generateError);
};

export const clientGetQuestionnaire = clientId => {
  return mainConnect.get(`clients/clients/${clientId}/questionnaire`).catch(generateError);
};

export const getClientActivityAreas = () => {
  return mainConnect.get('clients/clients/questionnaire/activity-areas').catch(generateError);
};

export const clientStoreQuestionnaire = (clientId, body) => {
  return mainConnect.post(`clients/clients/${clientId}/questionnaire`, body).catch(generateError);
};

export const clientUpdateQuestionnaire = (clientId, body) => {
  return mainConnect.put(`clients/clients/${clientId}/questionnaire`, body).catch(generateError);
};

export const getClientPassport = clientId => {
  return mainConnect.get(`clients/clients/${clientId}/passport`).catch(generateError);
};

export const getTodayApplicationsFromMerchants = params => {
  return mainConnect.get(`/applications/counters/today-applications-from-merchants`, { params }).catch(generateError);
};

export const clientRunPreScoring = clientId => {
  return mainConnect.get(`/clients/clients/${clientId}/pre-scoring`).catch(generateError);
};

export const searchClients = params => {
  return mainConnect.get('/clients/clients/search', { params }).catch(generateError);
};

export const nasiyaSearchClients = params => {
  return mainConnect.get('/clients/clients/search-by-phone', { params }).catch(generateError);
};

export const clientRequestAddNew = params => {
  // throw {
  //   message: 'Some error',
  //   alert: 'passport_with_subclient_exists',
  //   client: {
  //     gender: 'F',
  //     name: 'Daler',
  //     surname: 'Saidov',
  //     patronymic: 'test',
  //     phone: '998914007439',
  //   },
  // };

  return mainConnect.post('clients/clients/create/request', params).catch(generateError);
};

export const clientAddNewVerify = params => {
  return mainConnect.post(`clients/clients/create/verify`, params).catch(generateError);
};

export const checkClient = body => {
  return mainConnect.post(`clients/clients/check-client`, body).catch(generateError);
};

export const clientConfirmAgreement = body => {
  return mainConnect.post(`clients/clients/confirm-agreement`, body).catch(generateError);
};

export const requestCreateClient = formData => {
  return mainConnect.post(`clients/clients/request-create-client`, formData).catch(generateError);
};

export const createClient = body => {
  return mainConnect.post(`clients/clients/create-client-confirm`, body).catch(generateError);
};

export const getClientsCreatedToday = params => {
  return mainConnect.get(`clients/clients/today`, { params }).catch(generateError);
};

export const getUpdatedApplicantUuid = params => {
  return mainConnect.get(`clients/applicant/uuid`, { params }).catch(generateError);
};

export const getUpdatedClientUuid = clientId => {
  return mainConnect.get(`clients/clients/${clientId}/qr-uuid`).catch(generateError);
};

export const getApplicantFiles = params => {
  return mainConnect.get(`clients/applicant/files`, { params }).catch(generateError);
};
