import { useQuery } from '@tanstack/vue-query';
import { getTodayApplicationsFromMerchants } from '../api';

const useGetTodayApplicationsFromMerchants = clientId => {
  return useQuery({
    queryKey: ['clients', 'detail', clientId, 'today-applications-from-merchants'],
    async queryFn({ queryKey }) {
      const clientId = queryKey[2];

      const response = await getTodayApplicationsFromMerchants({
        client_id: clientId,
      });

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetTodayApplicationsFromMerchants;
