<template>
  <template v-if="queries.orderStatuses.isLoading">
    <fullscreen-loader />
  </template>

  <template v-else-if="queries.orderStatuses.isError">
    <api-error :error="queries.orderStatuses.error" />
  </template>

  <template v-else-if="queries.orderStatuses.isSuccess">
    <router-view v-slot="{ Component }">
      <at-sidebar
        :items="items"
        :user="me"
        :logo="{
          name: 'Merchant Logo',
          path: '/Logo.svg',
          darkPath: '/LogoDark.svg',
        }"
        :user-dropdown-items="dropdownItems"
        :module="module"
        :modules="moduleOptions"
        :is-dark-mode="theme === 'dark'"
        :lang="lang"
        @update:lang="changeLang($event)"
        @update:is-dark-mode="setTheme($event ? 'dark' : 'light')"
        @update:module="changeModule"
        @on-logout="logout"
      >
        <div class="pb-12">
          <component :is="Component"></component>
        </div>
      </at-sidebar>
    </router-view>
  </template>

  <reload-page-on-deploy-modal />
</template>

<script setup>
  // todo refactor layout (store and constants)

  import { computed, reactive } from 'vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n/index';
  import { useUserStore } from '@/to-fsd/shared/store/user';
  import { useMainStore } from '@/to-fsd/shared/store/main';
  import { storeToRefs } from 'pinia';
  import { ROUTE_NAMES as ALIFSHOP_ROUTES } from '@/modules/alifshop/common/constants/routeNames';
  import { useModuleOptions } from '@/to-fsd/shared/hooks';
  import { resetStores } from '@/stores';
  import { useJivoFieldsAutoComplete } from '@/to-fsd/features/jivo';
  import { useTheme } from '@/hooks';
  import { useLang } from '@/to-fsd/shared/hooks';
  import { ReloadPageOnDeployModal } from '@/to-fsd/features/reload-page-on-deploy';
  import { FullscreenLoader } from '@/to-fsd/shared/ui';
  import { useGetOrderStatusesQuery } from '@/to-fsd/shared/queries';
  import { ApiError } from '@/to-fsd/shared/lib';

  import AtSidebar from '@/plugins/aliftech-ui/components/AtSidebar/AtSidebar';
  import { useLogoutMutation } from '@/to-fsd/features/logout';
  import { modulesList, modulesName, ROUTE_NAMES as MAIN_ROUTES } from '@/to-fsd/shared/constants';

  const mutations = reactive({
    logout: useLogoutMutation(),
  });

  const queries = reactive({
    orderStatuses: useGetOrderStatusesQuery(),
  });

  useJivoFieldsAutoComplete();

  const { t } = useI18n();
  const router = useRouter();

  const user = useUserStore();
  const store = useMainStore();
  const { theme, setTheme } = useTheme();
  const { lang, changeLang } = useLang();

  const { module } = storeToRefs(store);
  const moduleOptions = useModuleOptions();

  store.$patch({ module: modulesName.ALIFSHOP });

  const me = computed(() => {
    return {
      name: user?.me?.name,
      phone: user?.me?.phone,
      avatar_link: user?.me?.avatar_link,
    };
  });

  const links = computed(() => {
    return [
      {
        title: t('sidebar.routes.orders'),
        visible: true,
        icon: 'ShoppingCart',
        to: { name: ALIFSHOP_ROUTES.ordersList },
      },
    ];
  });

  const items = computed(() => {
    return links.value?.filter?.(link => {
      return link.visible || false;
    });
  });

  const dropdownItems = computed(() => {
    return [
      {
        title: t('sidebar.logout'),
        icon: { name: 'logout', type: 'outline' },
        onClick: () => logout(),
      },
    ];
  });

  const logout = async () => {
    await mutations.logout.mutateAsync();
    router.push({ name: MAIN_ROUTES.login }).then(() => {
      resetStores();
    });
  };

  const changeModule = value => {
    store.$patch({ module: value });
    router.push({ name: modulesList[value].homeRoute });
  };
</script>
