import { useQuery } from '@tanstack/vue-query';
import { getApplicantFiles } from '../api';

const useGetClientFilesByUuidQuery = uuid => {
  return useQuery({
    queryKey: ['applicant', uuid, 'files'],
    async queryFn({ queryKey }) {
      const uuid = queryKey[1];

      const response = await getApplicantFiles({ uuid });

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

export default useGetClientFilesByUuidQuery;
