import { CASHBACK_TYPE } from '../constants';

export default {
  entities: {
    cashback: {
      typeText: {
        [CASHBACK_TYPE.CHARGE]: 'Tasdiqlandi',
        [CASHBACK_TYPE.TO_PAY]: 'Hisoblash uchun tayyor',
        [CASHBACK_TYPE.NOT_APPROVED]: 'Tasdiqlanmadi',
        [CASHBACK_TYPE.REJECT]: 'Qaytarish uchun hisob',
        [CASHBACK_TYPE.TOTAL]: "Umumiy o'tkazilgan bonus miqdori",
      },
      table: {
        headers: [
          '№',
          'RASMIYLASHTIRILGAN ARIZA SUMMASI',
          'BONUS',
          'RASMIYLASHTIRILGAN SANA',
          "BONUS O'TKAZILGAN SANA",
          'HOLAT',
        ],
      },
      typeSelect: {
        all: 'Barcha holatlar',
      },
    },
  },
};
