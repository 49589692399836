import { useQuery } from '@tanstack/vue-query';
import { getClientsCreatedToday } from '../api';

const useGetClientsCreatedToday = ({ page }) => {
  return useQuery({
    queryKey: ['clients', 'list', { page, createdToday: true }],
    async queryFn({ queryKey }) {
      const { page } = queryKey[2];

      const response = await getClientsCreatedToday({ page, per_page: 10 });

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetClientsCreatedToday;
