import { useMutation } from '@tanstack/vue-query';
import { createClient } from '../api';

const useCreateClientMutation = () => {
  return useMutation({
    async mutationFn({ phone, otp, uuid }) {
      const response = await createClient({
        phone,
        otp,
        uuid,
      });

      return response.data;
    },
  });
};

export default useCreateClientMutation;
