<template>
  <at-modal :model-value="showModal" size="lg">
    <div class="text-center mb-6">
      <div class="mb-4 flex flex-col">
        <p class="rounded-full mx-auto justify-center items-center bg-yellow-100 dark:bg-yellow-200 p-3">
          <exclamation-icon class="text-yellow-500 dark:text-yellow-600 h-6 w-6" />
        </p>
      </div>
      <h4 class="font-bold text-lg">
        {{ $t('system.reload.siteHasBeenUpdated') }}
      </h4>
      <p class="text-base">
        {{ $t('system.reload.pleaseUpdate') }}
      </p>
    </div>
    <div class="mt-2 justify-evenly">
      <at-button class="w-full" color="primary" type="button" @click="reloadPage()">
        {{ $t('system.reload.updateNow') }}
      </at-button>
    </div>
  </at-modal>
</template>

<script setup>
  import { onUnmounted, onMounted, ref } from 'vue';
  import * as Sentry from '@sentry/vue';
  import { ExclamationIcon } from '@heroicons/vue/solid';

  import { AtModal, AtButton } from '@packages/aliftech-ui';

  import { useCentrifuge } from '@/to-fsd/shared/hooks';
  import { addVueAppErrorHandler, removeVueAppErrorHandler } from '@/plugins/global';
  import { addRouteErrorHandler, removeRouteErrorHandler } from '@/router';

  const centrifuge = useCentrifuge();

  const channel = process.env.VUE_APP_CENTRIFUGE_DEPLOY_CHANNEL;

  const showModal = ref(false);

  const reloadPage = () => {
    if (typeof window !== 'undefined' && 'location' in window && typeof window.location.reload === 'function') {
      localStorage.removeItem('reload');
      window.location.reload();
    }
  };

  let subscription;

  const handleDeploy = () => {
    showModal.value = true;
  };

  const subscribeToDeploy = () => {
    subscription = centrifuge.getSubscription(channel) || centrifuge.newSubscription(channel);

    subscription.subscribe();

    subscription.on('publication', handleDeploy);
  };

  const unsubscribeFromDeploy = () => {
    if (subscription) {
      subscription.off('publication', handleDeploy);
    }
  };

  const vueAppErrorHandler = message => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = pattern.test(message.toString());

    if (isChunkLoadFailed) {
      Sentry.captureException(message);
      showModal.value = true;
    }
  };

  const subscribeToVueAppError = () => {
    addVueAppErrorHandler(vueAppErrorHandler);
  };

  const unsubscribeToVueAppError = () => {
    removeVueAppErrorHandler(vueAppErrorHandler);
  };

  const routerErrorHandler = error => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);

    if (isChunkLoadFailed) {
      Sentry.captureException(error);
      showModal.value = true;
    }
  };

  const subscribeToRouterError = () => {
    addRouteErrorHandler(routerErrorHandler);
  };

  const unsubscribeFromRouterError = () => {
    removeRouteErrorHandler(routerErrorHandler);
  };

  onMounted(() => {
    subscribeToDeploy();
    subscribeToVueAppError();
    subscribeToRouterError();
  });

  onUnmounted(() => {
    unsubscribeFromDeploy();
    unsubscribeToVueAppError();
    unsubscribeFromRouterError();
  });
</script>
