import { unref, watchEffect } from 'vue';
import { useCentrifuge } from '@/to-fsd/shared/hooks';

const useSubscribeToClientFileUploadedByUuid = (uuid, fileType, callback) => {
  const centrifuge = useCentrifuge();

  let subscription = null;

  const handler = async ctx => {
    if (ctx.data.event !== 'file_uploaded' || ctx.data.file_type !== unref(fileType)) return;

    callback();
  };

  const subscribe = () => {
    subscription =
      centrifuge.getSubscription(`merchant-applicant-${unref(uuid)}`) ||
      centrifuge.newSubscription(`merchant-applicant-${unref(uuid)}`);

    subscription.subscribe();

    subscription.on('publication', handler);
  };

  const unsubscribe = () => {
    subscription?.off('publication', handler);
  };

  watchEffect(onCleanup => {
    subscribe();

    onCleanup(() => {
      unsubscribe();
    });
  });
};

export default useSubscribeToClientFileUploadedByUuid;
