import { merchantsService } from './merchants.service';
import { creditsService } from './credits.service';

export const actions = {
  async fetchApp() {
    if (!this.app) {
      const response = await merchantsService.getApp();
      this.app = response?.data;
    }
    return Promise.resolve(this.app);
  },
  async fetchMerchantRelations(params) {
    if (!this.merchantRelations) {
      const response = await merchantsService.getMerchantRelations(params);
      this.merchantRelations = response?.data;
    }
    return Promise.resolve(this.merchantRelations);
  },
  async fetchCreditsApp() {
    if (!this.creditsApp) {
      const response = await creditsService.getApp();
      this.creditsApp = response?.data;
    }
    return Promise.resolve(this.creditsApp);
  },
  async fetchEmployees(params) {
    if (!this.employees) {
      const response = await merchantsService.getMerchantEmployees(params);
      let employees = response?.data;
      if (employees?.meta?.last_page !== employees?.meta?.current_page) {
        const totalEmployees = await merchantsService.getMerchantEmployees({ per_page: employees?.meta?.total });
        employees = totalEmployees?.data;
      }
      this.employees = employees?.data;
    }
    return Promise.resolve(this.employees);
  },
};
