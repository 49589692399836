export default {
  features: {
    acquiringDownloadExcel: {
      downloadCsv: `Excel ga o'tkazish`,
      errors: {
        dateTooEarly: "Xato: Hisobot sanasi 2025 yil 1 yanvardan oldin bo'lmasligi kerak",
      },
      excel: {
        id: 'ID',
        paymentType: `To'lov turi`,
        type: `Operatsiya turi`,
        amount: 'Narx',
        amountNew: "So'm",
        date: 'Sana va vaqt',
        status: 'Holat',
        spic: 'Mahsulotlning MXIK',
        label: 'Mahsulot nomi',
        store: `Do'kon`,
        phone: 'Xaridorning raqami',
        created_at: 'Sana va vaqt',
        updated_at: 'Yangilanish sanasi',
        fee: 'Qabul qiluvchi komissiyasi',
        feeNew: 'Komissiyasi',
        created_from: 'Dan',
        created_to: 'Gacha',
        created: 'Hisobot yaratilgan sana',
        excelLength: 'Tranzaktsiyalar soni',
        period: 'Davr',
        filename: 'Hisobotlar',
      },
      statuses: {
        REFUND: 'Qaytarilgan',
        PARTIAL_REFUND: 'Qisman qaytarilgan',
        PAYMENT: "To'lov",
      },
      paymentType: {
        INSTALLMENT: 'Nasiya',
        CARD: 'Karta',
      },
    },
  },
};
