export default {
  sidebar: {
    routes: {
      main: 'Главное',
      clients: 'Клиенты',
      applications: 'Заявки',
      rejects: 'Возвраты',
      tickets: 'Проблемные кейсы',
      complianceChat: 'Чаты с compliance',
      requests: 'Запросы',
      deliveries: 'Доставки',
      alifshop: 'Алифшоп',
      items: 'Товары',
      orders: 'Заказы',
      offers: 'Акции',
      management: 'Менеджмент',
      invoice: 'Счёт-фактура',
      analytics: 'Аналитика',
      notifications: 'Уведомления',
      calculator: 'Калькулятор',
      bonuses: 'Бонусы',
      faq: 'F.A.Q.',
      warehouse: 'Склад',
      sales: 'Продажи',
      excels: 'Отчёты',
      employees: 'Сотрудники',
      pos: 'Кассы',
      allApplications: 'Заявки всех магазинов',
      payments: 'Платежи',
      balances: 'Список оплат',
      scoring: 'Оценка карт',
      searchClients: 'Поиск клиента',
      knowledgeBase: 'База знаний',
      installmentPayment: 'Оплата рассрочки',
    },
    dropdown: {
      title: 'Настройки',
      logout: 'Выйти из аккаунта',
      lang: 'Язык',
      ru: 'Русский',
      uz: 'O’zbekcha',
      toggleLabel: 'Тёмная тема',
    },
    logout: 'Выйти',
    modules: {
      module_acquiring: 'Эквайринг & BML',
      module_alifshop: 'Alif Shop',
      module_azo: 'Alif Nasiya',
      module_admin: 'Админ панель',
    },
  },
};
