import { generateError, mainConnect } from '@/services';

export const getClientPassport = clientId => {
  return mainConnect.get(`clients/clients/${clientId}/passport`).catch(generateError);
};

export const storeClientPassport = (clientId, body) => {
  return mainConnect.post(`clients/clients/${clientId}/passport`, body).catch(generateError);
};

export const updateClientPassport = (clientId, body) => {
  return mainConnect.put(`clients/clients/${clientId}/passport`, body).catch(generateError);
};

export const updateClientPassportV2 = body => {
  return mainConnect.post('clients/clients/update-passport-by-phone', body).catch(generateError);
};

export const getPassportDataFromOcr = formData => {
  return mainConnect.post('clients/clients/ocr-recognition', formData).catch(generateError);
};
