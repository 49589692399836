export default {
  features: {
    alifshopOrderSetMarking: {
      button: "Belgini to'ldirish",
      modal: {
        title: 'Quyidagi mahsulotlar uchun belgini kiriting',
      },
    },
  },
};
