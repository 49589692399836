<template>
  <div class="flex flex-col flex-wrap justify-start items-top min-h-screen">
    <div class="w-full block py-6">
      <div class="container mx-auto px-4">
        <div class="-m-1 sm:-m-2 flex justify-end w-full">
          <div class="p-1 sm:p-2">
            <theme-toggler />
          </div>
          <div class="p-1 sm:p-2">
            <lang-toggler />
          </div>
        </div>
      </div>
    </div>

    <div class="block w-full my-auto py-4">
      <div class="container mx-auto px-4">
        <div class="flex items-center justify-between -m-2">
          <div class="block p-2 xl:w-7/12 w-full">
            <div class="block w-full">
              <router-view v-slot="{ Component }">
                <component :is="Component"></component>
              </router-view>
            </div>

            <div class="mt-4">
              <div class="mb-2">
                <h2 class="text-gray-400 text-lg font-bold">{{ $t('layouts.registration.support') }}</h2>
              </div>
              <div class="flex flex-wrap items-start sm:-m-3 -m-1">
                <div v-for="(phone, index) in getPhones" :key="'auth-page-phones-' + index" class="block sm:p-3 p-1">
                  <a :href="'tel:' + phone.value" class="sm:text-lg text-gray-900 dark:text-white font-bold">
                    {{ phone.title }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 xl:w-5/12 xl:block hidden">
            <svg-auth-illustration></svg-auth-illustration>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { ThemeToggler } from '@/to-fsd/features/toggle-theme';
  import { LangToggler } from '@/to-fsd/features/toggle-lang';
  import SvgAuthIllustration from './SvgAuthIllustration.vue';

  const phones = ['+998 90 052 53 17', '+998 90 014 04 42'];

  const getPhones = computed(() => {
    return phones.map(phone => ({ value: '+' + phone.replace(/\D/g, ''), title: phone }));
  });
</script>
