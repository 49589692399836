export default {
  features: {
    employeeTogglePermissions: {
      panel: {
        title: 'Kirish ruxsatlarini sozlash',
      },
      merchant_acquiring: 'Ekvayring bilan boshqarish',
      merchant_admin: 'Admin panel',
      merchant_alifnasiya: 'Alifnasiya bilan boshqarish',
      merchant_alifshop: 'Alifshop bilan boshqarish',
      merchant_applications: 'Arizalar bilan boshqarish',
      merchant_deliveries: 'Yetkazib berish bilan boshqarish',
      merchant_manager: 'Xodimlar bilan boshqarish',
      merchant_moderation_goods: 'Tovarlarning moderatsiyasini boshqarish',
      merchant_scoring_card: 'Kartalarni skoring qilishni boshqarish',
      merchant_upload_goods: 'Tovarlarni yuklash bilan boshqarish',
      merchant_moderation_alifshop: 'Alifshop takliflarini boshqarish',
    },
  },
};
