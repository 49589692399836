import { AcquiringLayout } from '@/to-fsd/layouts/acquiring';
import { ROUTE_NAMES } from '@/modules/acquiring/common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';

const routes = {
  path: '/acquiring/',
  component: AcquiringLayout,
  redirect: '/acquiring/purchases',
  children: [
    {
      path: '/acquiring/purchases',
      name: ROUTE_NAMES.balancesList,
      component: async () => {
        const { AcquiringBalancesPage } = await import('@/to-fsd/pages/acquiring-balances');

        return AcquiringBalancesPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Список оплат'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_acquiring'),
          requirePermissions(['merchant_acquiring']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: '/acquiring/balances/create-invoice',
      name: ROUTE_NAMES.createInvoice,
      component: async () => {
        const { AcquiringCreateInvoicePage } = await import('@/to-fsd/pages/acquiring-create-invoice');

        return AcquiringCreateInvoicePage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Cоздание инвойса'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_acquiring'),
          requirePermissions(['merchant_acquiring']),
        ],
      },
    },
    {
      path: '/acquiring/card-scoring',
      name: ROUTE_NAMES.cardScoring,
      component: async () => {
        const { CardScoringPage } = await import('@/to-fsd/pages/card-scoring');

        return CardScoringPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Оценка карт'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_acquiring'),
          requirePermissions(['merchant_acquiring', 'merchant_scoring_card']),
        ],
      },
    },
    {
      path: '/acquiring/clients/search',
      name: ROUTE_NAMES.searchClients,
      component: async () => {
        const { BmlSearchClientsPage } = await import('@/to-fsd/pages/bml-search-clients');

        return BmlSearchClientsPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Поиск клиентов'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_acquiring'),
          requirePermissions(['merchant_acquiring']),
        ],
      },
    },
  ],
};

export default router => {
  router.addRoute(routes);
};
