export default {
  features: {
    warehouseItemUpdate: {
      updateWithExcelButton: 'Изменить товары',
      updateQuantityAndPriceForm: {
        title: 'Количество и цена',
        submit: 'Изменить',
        fields: {
          quantity: {
            label: 'Количество на складе',
            times: 'шт.',
          },
          originalPrice: {
            label: 'Цена за 1 шт.',
          },
          price: {
            label: 'Цена с НДС',
            placeholder: 'Цена с НДС',
          },
        },
      },
    },
  },
};
