<template>
  <at-panel v-if="isLoading">
    <template #header>
      <div class="w-1/2">
        <at-skeleton type="row" />
      </div>
    </template>
    <div class="-m-2">
      <div class="p-2">
        <at-skeleton type="row" />
      </div>
      <div class="p-2">
        <at-skeleton type="row" />
      </div>
    </div>
  </at-panel>

  <at-panel v-else :title="$t('entities.rejectCase.photos.title')">
    <div class="flex flex-wrap justify-between items-center">
      <div v-if="photos.length" class="flex flex-wrap">
        <template v-for="(photo, index) in photos" :key="index">
          <img :src="photo" alt="reject items" class="p-2 w-full rounded-xl" />
        </template>
      </div>
      <at-alert v-else class="w-full" type="info">
        {{ $t('entities.rejectCase.photos.alert') }}
      </at-alert>
    </div>
  </at-panel>
</template>

<script setup>
  import { computed } from 'vue';

  import { AtPanel, AtSkeleton, AtAlert } from '@packages/aliftech-ui';

  const props = defineProps({
    rejectCase: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  });

  const photos = computed(() => {
    return props.rejectCase.items.reduce((acc, item) => {
      if (item.files?.length) {
        item.files.forEach(file => acc.push(file.url));
      }

      return acc;
    }, []);
  });
</script>
