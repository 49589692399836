export default {
  pages: {
    errorPage: {
      messages: {
        pageNotFound: 'Страница не найдена',
        objectNotFound: 'Объект не найден',
        notAllowed: 'Недостаточно прав',
        moduleAccessDenied: 'Доступ к модулю запрещен',
        serverError: 'Сервер не отвечает',
      },
      goToMainPage: 'Вернуться на главную',
    },
  },
};
