const formatPhone = phone => {
  if (!phone) {
    throw new Error('phone is empty');
  }

  let formattedPhone = phone.startsWith('+') ? phone.substring(1) : phone;

  if (formattedPhone.length < 11) {
    throw new Error('phone number is too short');
  }

  const countryCode = phone.startsWith('+')
    ? `+${formattedPhone.substring(0, 3)}`
    : `+${formattedPhone.substring(0, 3)}`;
  const companyCode = `(${formattedPhone.substring(3, 5)})`;
  const number = `${formattedPhone.substring(5, 8)}-${formattedPhone.substring(8, 10)}-${formattedPhone.substring(10)}`;

  return `${countryCode} ${companyCode} ${number}`;
};

export default formatPhone;
