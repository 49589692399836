import { CLIENT_STATUS } from '../constants';
import { formatPhone } from '@/to-fsd/shared/lib';
import { formatTime } from '@/to-fsd/shared/lib';
import { CLIENT_PASSPORT_TYPE } from '@/to-fsd/entities/client-passport';
import { parseISO } from 'date-fns';

export const getClientFullName = client => {
  let result = '';

  if (client.surname) {
    result += client.surname;
  }

  if (client.name) {
    result += ' ' + client.name;
  }

  if (client.patronymic) {
    result += ' ' + client.patronymic;
  }

  return result;
};

export const getClientShortName = fullName => {
  if (!fullName) {
    throw new Error('fullName is empty');
  }

  const [firstName, lastName] = fullName.split(' ');

  let result = firstName[0];

  if (lastName) {
    result += lastName[0];
  }

  return result;
};

export const getClientStatusColor = clientStatus => {
  const map = {
    [CLIENT_STATUS.CREATED]: 'blue',
    [CLIENT_STATUS.FIXED]: 'yellow',
    [CLIENT_STATUS.REVIEWED]: 'yellow',
    [CLIENT_STATUS.IDENTIFIED]: 'green',
  };

  return map[clientStatus];
};

export const clientGetStatusBody = client => {
  return client.client_status.body;
};

export const clientGetPhone = client => {
  return client.phone;
};

export const clientHasPhone = client => {
  return Boolean(clientGetPhone(client));
};

export const clientGetUniqueId = client => {
  return client.unique_id;
};

export const clientGetClientStatusKey = client => {
  return client.client_status_key;
};

export const clientGetFixReasons = client => {
  return client.fix_reasons;
};

export const clientGetFormattedPhone = client => {
  return formatPhone(clientGetPhone(client));
};

export const clientGetStatusKey = client => {
  return client.client_status_key;
};

export const clientGetCreatedByStr = client => {
  return client.created_by_str;
};

export const clientGetUpdatedByStr = client => {
  return client.updated_by_str;
};

export const clientGetFormattedCreatedAtTime = (client, { withDayPeriod = true } = {}) => {
  return formatTime(parseISO(client.created_at), { withDayPeriod });
};

export const clientIsPassportTypePassport = client => {
  return client.passport_type === CLIENT_PASSPORT_TYPE.PASSPORT;
};

export const clientIsPassportTypeId = client => {
  return client.passport_type === CLIENT_PASSPORT_TYPE.ID;
};

export const clientRequirementsGetByKey = (clientRequirements, key) => {
  return clientRequirements.find(requirement => requirement.key === key);
};

export const isClientStatusFixed = client => {
  return client.client_status.key === CLIENT_STATUS.FIXED;
};

export const isClientStatusIdentified = client => {
  return client.client_status.key === CLIENT_STATUS.IDENTIFIED;
};

export const isClientStatusCreated = client => {
  return client.client_status.key === CLIENT_STATUS.CREATED;
};

export const isClientStatusReviewed = client => {
  return client.client_status.key === CLIENT_STATUS.REVIEWED;
};
