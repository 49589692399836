import { useQuery } from '@tanstack/vue-query';
import { getClients, getIdentifiedClients } from '../api';

const useGetClientsListQuery = params => {
  return useQuery({
    queryKey: ['clients', 'list', params],
    async queryFn({ queryKey }) {
      // TODO: refactor
      const AZO_CLIENT_STATUS_ID = 2;

      const params = queryKey[2];

      const statusId = params.status_id;

      const isAzoStatusSelected = statusId === AZO_CLIENT_STATUS_ID;

      let response;

      if (isAzoStatusSelected) {
        response = await getIdentifiedClients(params);
      } else {
        response = await getClients(params);
      }

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetClientsListQuery;
