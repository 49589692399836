import { useMutation } from '@tanstack/vue-query';
import { requestCreateRejectCase } from '../api';

const useRequestCreateRejectCaseMutation = () => {
  return useMutation({
    async mutationFn({ applicationId }) {
      const response = await requestCreateRejectCase({ application_id: applicationId });

      return response.data;
    },
  });
};

export default useRequestCreateRejectCaseMutation;
