import { useQuery } from '@tanstack/vue-query';
import { getOrderStatuses } from '@/to-fsd/shared/api';

const useGetOrderStatusesQuery = () => {
  return useQuery({
    queryKey: ['global', 'order-statuses'],
    async queryFn() {
      const response = await getOrderStatuses();

      return response.data;
    },
    gcTime: Infinity,
    staleTime: Infinity,
  });
};

export default useGetOrderStatusesQuery;
