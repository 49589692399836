export default {
  entities: {
    alifshopOrderItem: {
      table: {
        title: "Mahsulotlar ro'yxati",
        headers: ['Nomi', 'Turi', 'Soni', 'Narxi'],
      },
    },
  },
};
