export default {
  shared: {
    ui: {
      cropModal: {
        save: 'Saqlash',
      },
      select: {
        options: {
          all: 'Barchasi',
        },
      },
      otpInfoBlock: {
        title: 'Nima uchun SMS kelmayapti?',
        steps: [
          'Telefon balansini tekshiring.',
          'Telefon xotirasini tekshiring (agar xotira toʻlgan boʻlsa, tozalang).',
          'Telefonni oʻchirib qayta yoqing.',
          'Alif Nasiya spamga qoʻshilmaganligini tekshiring.',
          'Agar yuqoridagi barcha choralardan hech biri yordam bermasa, {technicalSupport} murojaat qiling.',
        ],
        technicalSupport: 'texnik yordam.',
      },
      slideOver: {
        close: 'Yopish',
      },
    },
  },
};
