export default {
  features: {
    offerDemandCreate: {
      steps: {
        list: ['Toifa', 'Brend va model', 'Rasm va Video', 'Xususiyatlar', 'Tavsif'],
        tabs: ['Rus tilida', "O'zbek tilida"],
        sendToReview: "Ko'rib chiqishga yuborish",
      },
      stepCategory: {
        tabs: ['Tanlov', 'Qidiruv'],
        fields: {
          firstCategory: {
            label: 'Toifani tanlang',
            placeholder: 'Toifa',
          },
          secondCategory: {
            placeholder: '2-kategoriya',
          },
          thirdCategory: {
            placeholder: '3-kategoriya',
          },
          searchCategory: {
            placeholder: 'Toifani qidirish...',
          },
        },
        continue: 'Davom etirish',
      },
      stepSpecifications: {
        title: 'Tovarning brendi, modeli va rangini tanlang',
        fields: {
          brand: {
            label: 'Brend',
            placeholder: 'Brend',
          },
          model: {
            label: 'Model',
            placeholder: 'Model',
          },
          series: {
            label: 'Seriya',
            placeholder: 'Seriya',
          },
          color: {
            label: 'Rang',
          },
        },
        continue: 'Davom etirish',
        required: "Ushbu maydon to'ldirilishi shart",
        anotherBrand: 'Boshqa',
      },
      stepImagesAndVideo: {
        title: 'Tovarning fotosuratlari',
        descriptionTitle: 'Asosiy qilish uchun yuklangan rasmni bosing',
        rules: ['Kamida 2 ta rasm', 'Minimal ruxsat 512x512 piksel', 'Maksimal 1  mb'],
        selectFile: 'Faylni tanlang',
        productConverse: "Tovarning konversiyasini ko'taring",
        addYoutubeVideo: 'Tovar haqida YouTubega video-rolik joylang',
        continue: 'Davom etirish',
        required: "Ushbu maydon to'ldirilishi shart",
      },
      openVideoButton: 'Video joylash',
      createVideoModal: {
        title: 'Video joylashtirish',
        ruLabel: 'Rus tilidagi videoning URL havolasini kiriting',
        uzLabel: "O'zbek tilidagi videoning URL havolasini kiriting",
        add: 'Joylash',
        cancel: 'Bekor qilish',
      },
      stepDescriptions: {
        infoText: 'Mahsulotning batafsil tavsifi sotuvni sezilarli darajada oshiradi',
        validation: 'Tavsif qo‘shganda, uni ham rus, ham o‘zbek tilida ko‘rsatish zarur bo‘ladi',
        title: 'Mahsulot tavsifi',
        save: 'Saqlash',
        enterDescription: 'Tavsifni kiriting',
      },
      stepCharacteristics: {
        valueInRu: "Ruscha ma'nosi",
        valueInUz: "O'zbekcha ma'nosi",
        addMoreValue: "Yana qo'shish",
        addMoreAttribute: "Qo's.xususiyatlari qo'shish",
        continue: 'Davom etirish',
        attributePlaceholderRu: 'Емкость аккумулятора ...',
        attributeNameRu: 'Atribut nomi (ru)',
        attributePlaceholderUz: 'Batareya quvvati...',
        attributeNameUz: 'Atribut nomi (uz)',
        cancel: 'Bekor qilish',
        add: 'Video joylash',
        required: "Ushbu maydon to'ldirilishi shart",
        anotherBrand: 'Boshqa',
        attributeAdding: "Atribut qo'shish",
      },
    },
  },
};
