<template>
  <router-view v-slot="{ Component }">
    <at-sidebar
      :items="items"
      :user="me"
      :logo="{
        name: 'Merchant Logo',
        path: '/Logo.svg',
        darkPath: '/LogoDark.svg',
      }"
      :user-dropdown-items="dropdownItems"
      :module="module"
      :modules="moduleOptions"
      :is-dark-mode="theme === 'dark'"
      :lang="lang"
      @update:lang="changeLang($event)"
      @update:is-dark-mode="setTheme($event ? 'dark' : 'light')"
      @update:module="changeModule"
      @on-logout="logout"
    >
      <div class="pb-12">
        <component :is="Component"></component>
      </div>
    </at-sidebar>
  </router-view>

  <reload-page-on-deploy-modal />
</template>

<script setup>
  // todo refactor layout (store and constants)

  import { computed, onBeforeUnmount, reactive } from 'vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n/index';
  import { ROUTE_NAMES as ACQUIRING_ROUTES } from '@/modules/acquiring/common/constants/routeNames';
  import { useUserStore } from '@/to-fsd/shared/store/user';
  import { useMainStore } from '@/to-fsd/shared/store/main';
  import { resetAcquiringStores } from '@/modules/acquiring/common/store';
  import { storeToRefs } from 'pinia';
  import { useModuleOptions } from '@/to-fsd/shared/hooks';
  import { resetStores } from '@/stores';
  import { useJivoFieldsAutoComplete } from '@/to-fsd/features/jivo';
  import { useTheme } from '@/hooks';
  import { useLang } from '@/to-fsd/shared/hooks';
  import { ReloadPageOnDeployModal } from '@/to-fsd/features/reload-page-on-deploy';
  import AtSidebar from '@/plugins/aliftech-ui/components/AtSidebar/AtSidebar';
  import { useLogoutMutation } from '@/to-fsd/features/logout';
  import { modulesList, modulesName, ROUTE_NAMES as MAIN_ROUTES } from '@/to-fsd/shared/constants';

  const mutations = reactive({
    logout: useLogoutMutation(),
  });

  useJivoFieldsAutoComplete();

  const { t } = useI18n();
  const router = useRouter();

  const { theme, setTheme } = useTheme();
  const { lang, changeLang } = useLang();
  const user = useUserStore();
  const store = useMainStore();
  const { module } = storeToRefs(store);
  const moduleOptions = useModuleOptions();
  store.$patch({ module: modulesName.ACQUIRING });

  const me = computed(() => {
    return {
      name: user?.me?.name,
      phone: user?.me?.phone,
      avatar_link: user?.me?.avatar_link,
      permissions: user?.me?.permissions,
    };
  });

  const links = computed(() => {
    return [
      // {
      //   title: t('sidebar.routes.payments'),
      //   visible: true,
      //   icon: 'CreditCard',
      //   to: { name: ACQUIRING_ROUTES.paymentsList },
      // },
      {
        title: t('sidebar.routes.balances'),
        visible: true,
        icon: 'CurrencyDollar',
        to: { name: ACQUIRING_ROUTES.balancesList },
      },
      {
        title: t('sidebar.routes.scoring'),
        visible: true,
        permission: 'merchant_scoring_card',
        icon: 'CreditCard',
        to: { name: ACQUIRING_ROUTES.cardScoring },
      },
      {
        title: t('sidebar.routes.searchClients'),
        visible: true,
        icon: 'Search',
        to: { name: ACQUIRING_ROUTES.searchClients },
      },
    ];
  });

  const items = computed(() => {
    return links.value?.filter?.(link => {
      return (
        (link.visible && me.value?.permissions?.find?.(el => el.name === link.permission || !link.permission)) || false
      );
    });
  });

  const dropdownItems = computed(() => {
    return [
      {
        title: t('sidebar.logout'),
        icon: { name: 'logout', type: 'outline' },
        onClick: () => logout(),
      },
    ];
  });

  const logout = async () => {
    await mutations.logout.mutateAsync();
    router.push({ name: MAIN_ROUTES.login }).then(() => {
      resetStores();
    });
  };

  const changeModule = value => {
    store.$patch({ module: value });
    router.push({ name: modulesList[value].homeRoute });
  };

  onBeforeUnmount(() => {
    resetAcquiringStores();
  });
</script>
