import { ALIFSHOP_ORDER_DELIVERY_METHOD } from '../constants';

export default {
  entities: {
    alifshopOrder: {
      receiptMethod: {
        title: 'Qabul qilish usuli',
        pickupDescription: "Mijoz o'zi tovar uchun kelishga tayyor",
        address: 'Manzil',
      },
      deliveryMethod: {
        [ALIFSHOP_ORDER_DELIVERY_METHOD.delivery]: 'Yetkazma',
        [ALIFSHOP_ORDER_DELIVERY_METHOD.pickup]: 'Olib ketish',
      },
    },
  },
};
