export default {
  features: {
    alifshopOrderCreateDelivery: {
      link: {
        title: 'Доставки',
        button: 'Доставка Алифа',
      },
      form: {
        ENTER_ENTER: 'С двери до двери',
        ENTER_POINT: 'С двери до пункта выдачи',
        deliveryType: 'Тип доставки',
        save: 'Сохранить',
        cancel: 'Отменить',
        sender: {
          title: 'Отправитель',
          info: 'Отсутствует адреса складов',
          name: {
            label: 'ФИО',
          },
          phone: {
            label: 'Номер телефона',
          },
        },
        receiver: {
          title: 'Получатель',
          region: {
            label: 'Область',
            placeholder: 'Выберите значение',
          },
          district: {
            label: 'Район',
            placeholder: 'Выберите значение',
          },
          city: {
            label: 'Населенный пункт',
            placeholder: 'Выберите значение',
          },
          street: {
            label: 'Улица',
          },
          name: {
            label: 'ФИО',
          },
          phone: {
            label: 'Номер телефона',
          },
        },
        parcel: {
          title: 'Посылка',
          datePickup: {
            label: 'Дата забора посылки',
          },
          singleWeight: {
            label: 'Вес одной посылки (кг)',
          },
          singleVolume: {
            label: 'Объем одной посылки (метр куб)',
          },
        },
      },
    },
  },
};
